import {isEmpty} from 'lodash';
import getLanguage from '../../constants/language';
// Import Actions
import {
    TOGGLE_ADD_POST,
    ADD_VENUE_ROLES,
    REQUEST_LOGIN,
    REQUEST_OTP_LOGIN,
    REQUEST_OTP_FAILURE,
    REQUEST_OTP_SUCCESS,
    LOGIN_OTP_SUCCESS,
    LOGIN_OTP_FAILURE,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    REQUEST_LOGOUT,
    LOGOUT_SUCCESS,
    REQUEST_CHECK_TOKEN,
    TOKEN_VALID,
    TOKEN_INVALID,
    TOGGLE_SIDEBAR,
    ACCESSLIST_ADDED,
    MEHNDILIST_ADDED,
    EDIT_LOGIN_NUMBER,
    LOAD_ACTIVE_MEHNDI, LOAD_ACTIVE_VENUE, VERIFYING_OTP, LOAD_HOME_PAGE, TOGGLE_PASSWORD_OTP_LOGIN,
    TOGGLE_SHOW_HEADER, LOAD_BASE_FILTERS,
    TOGGLE_SHOW_SCREEN_LOADER,
    LOAD_CITY_LIST,
    UPDATE_LEAD_MANAGE_PATH, ACCESS_LIST_FETCHING_STATUS,
    LOAD_USER_PERMISSIONS,
    LOAD_DASHBOARD_DATA,
    LOAD_LEAD_STATUS_LIST,
    LOAD_CLOSED_LOST_REASON_LIST,
    LOAD_CALL_STATUS_LIST,
    LOAD_PARTNER_APPROVALS,
    PARTNER_APPROVAL_FETCHING_STATUS,
    LOAD_UPDATE_APPROVAL_STATUS,
    LOAD_DASHBOARD_LEADS_DATA,
    LOAD_AGENT_LIST, SET_PARTNER_ACCOUNT_DATA, ACCESS_LIST_APPEND_TO_PARTNERACCOUNT,
    LOAD_VENUE_SPACE_DATA,
    FETCH_TASK_CONTENT,
    LOAD_VENUE_KEY,
    FETCH_OPERATIONAL_CITIES,
    PIVOT_STATUS_LEADS,
    AGENT_WISE_PIVOT_LEADS,
    IS_CARD_SELECTED
} from './AppActions';

// Initial State
const initialState = {
    isAuthenticated: false,
    isOTPSent: false,
    isRequestingOtp: false,
    isFetching: false,
    loaded: true,
    isVerifyingOTP: false,
    message: '',
    isHeaderShow: true,
    isShowScreenLoader: false,
    isSideBarShow: true,
    isFooterShow: true,
    isUserSectionAuthenticated: false,
    user: {},
    isLoading: false,
    venueList: [],
    mehndiList:[],
    activeVenue: {},
    activeMehndi:{},
    venueDetail:[],
    current_month_leads_dashboard_graph_data: [],
    language: 'en',
    siteStaticContent: getLanguage(),
    homepage: {},
    isVerifyingPassword: false,
    isShowHeader: true,
    leadBaseFilters : {},
    sortFilters : [],
    operationalCity: [],
    cities: [
        {
            "name": "Gurgaon",
            "sname": "gurgaon"
        },
        {
            "name": "Delhi",
            "sname": "delhi"
        },
        {
            "name": "Noida",
            "sname": "noida"
        },
        {
            "name": "Faridabad",
            "sname": "faridabad"
        },
        {
            "name": "Ghaziabad",
            "sname": "ghaziabad"
        },
        {
            "name": "Mumbai",
            "sname": "mumbai"
        },
        {
            "name": "Bangalore",
            "sname": "bangalore"
        },
        {
            "name": "Chandigarh",
            "sname": "chandigarh"
        },
        {
            "name": "Pune",
            "sname": "pune"
        },
        {
            "name": "Kolkata",
            "sname": "kolkata"
        },
        {
            "name": "Ahmedabad",
            "sname": "ahmedabad"
        },
        {
            "name": "Jaipur",
            "sname": "jaipur"
        }
    ],
    isLeadManageFromLeadList: true,
    userPermissions: [],
    isFetchingAccessList: false,
    isCheckingForToken: false,
    converted_leads_gmv: 0,
    converted_leads_count: 0,
    last_month_leads: 0,
    current_month_leads: 0,
    active_leads_count: 0,
    leadStatusList: [],
    closedLostReasons: [],
    callStatusList: [],
    partnerApprovalCount: 0,
    partnerApprovalPageNum: 1,
    partnerApprovalPageSize: 20,
    partnerApprovalList: [],
    isFetchingPartnerApprovals: false,
    isUpdatingApproval: false,
    agentList: [],
    partneraccount: {},
    memberRole: {},
    venueRoles: [],
    accessList :[],
    venuespacedata:[],
    callingStatusList:[],
    venuekey:'',
    pivotstatusleads: [],
    agentwisepivotleads: [],
    selectedtype: ""
};

const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCESS_LIST_APPEND_TO_PARTNERACCOUNT:
            let partnerAccount = {...state.partneraccount}
            action.accessList.forEach((accessObject, index) => {
                if(accessObject.ref_name === 'venue') {
                    if(accessObject?.ref_id === state?.activeVenue?._id) {
                        partnerAccount = {
                            ...partnerAccount,
                            role: accessObject.role ? accessObject.role : '',
                            role_id: accessObject.role_id ? accessObject.role_id : '',
                        }
                    }
                }
            })
            let roles = [...state.venueRoles]
            roles.forEach(role => {
                if(role._id === partnerAccount.role_id) {
                    partnerAccount.permissions = role.permissions;
                }
            })

            return {
                ...state,
                accessList: action.accessList,
                partneraccount: partnerAccount
            }
        case LOAD_VENUE_KEY:
            return {
                ...state,
                venuekey: action.venuekey
            }    
            case PIVOT_STATUS_LEADS:
                return {
                    ...state,
                    pivotstatusleads: action.pivotstatusleads
                } 
                case AGENT_WISE_PIVOT_LEADS:
                    return {
                        ...state,
                        agentwisepivotleads: action.agentwisepivotleads
                    }             
            case FETCH_OPERATIONAL_CITIES:
                return {
                    ...state,
                    operationalCity: action.operationalCity
                }       
        case ADD_VENUE_ROLES:
            return {
                ...state,
                venueRoles: action.venueRoles
            }
            case IS_CARD_SELECTED:
                return {
                    ...state,
                    selectedtype: action.selectedtype
                }       
        case LOAD_VENUE_SPACE_DATA:
            return {
                ...state,
                venuespacedata: action.venuespacedata
            }    
        case SET_PARTNER_ACCOUNT_DATA:
            return {
                ...state,
                partneraccount: action.partneraccount,
                memberRole: action.role
            }
        case LOAD_AGENT_LIST:
            return {
                ...state,
                agentList: action.agentList
            }
        case LOAD_DASHBOARD_LEADS_DATA:
            return {
                ...state,
                current_month_leads_dashboard_graph_data: action.current_month_leads_dashboard_graph_data
            }
        case LOAD_UPDATE_APPROVAL_STATUS:
            return {
                ...state,
                isUpdatingApproval: action.isUpdatingApproval,
            };

        case PARTNER_APPROVAL_FETCHING_STATUS:
            return {
                ...state,
                isFetchingPartnerApprovals: action.isFetchingPartnerApprovals
            };

        case LOAD_PARTNER_APPROVALS:
            return {
                ...state,
                partnerApprovalCount: action.partnerApprovalCount,
                partnerApprovalList: action.partnerApprovalList,
                isFetchingPartnerApprovals: action.isFetchingPartnerApprovals,
                partnerApprovalPageNum: action.partnerApprovalPageNum
            };

        case LOAD_CALL_STATUS_LIST:
            return {
                ...state,
                callStatusList: action.callStatusList
            };

        case LOAD_CLOSED_LOST_REASON_LIST:
            return {
                ...state,
                closedLostReasons: action.closedLostReasons,
            };

        case LOAD_LEAD_STATUS_LIST:
            return {
                ...state,
                leadStatusList: action.leadStatusList,
            };

        case LOAD_DASHBOARD_DATA:
            return {
                ...state,
                current_month_leads: action.current_month_leads,
                current_month_active_leads: action.current_month_active_leads,
                last_month_leads: action.last_month_leads,
                last_month_active_leads: action.last_month_active_leads,
                current_month_bookings: action.current_month_bookings,
                last_month_active_bookings: action.last_month_active_bookings,
                current_month_revenue: action.current_month_revenue,
                last_month_revenue: action.last_month_revenue,
                current_month_accepted_leads: action.current_month_accepted_leads,
                current_month_rejected_leads: action.current_month_rejected_leads, 
                last_month_accepted_leads: action.last_month_accepted_leads,
                last_month_rejected_leads: action.last_month_rejected_leads,
                current_month_closed_lost_leads: action.current_month_closed_lost_leads,
                last_month_closed_lost_leads: action.last_month_closed_lost_leads
            };

        case LOAD_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.userPermissions
            };

        case ACCESS_LIST_FETCHING_STATUS:
            return {
                ...state,
                isFetchingAccessList: action.isFetchingAccessList,
            };

        case UPDATE_LEAD_MANAGE_PATH:
            return {
                ...state,
                isLeadManageFromLeadList: action.isLeadManageFromLeadList,
            };

        case LOAD_CITY_LIST:
            return {
                ...state,
                cities: action.cities,
            };

        case TOGGLE_SHOW_SCREEN_LOADER:
            return {
                ...state,
                isShowScreenLoader: action.isShowScreenLoader,
            };

        case LOAD_BASE_FILTERS:
            return {
                ...state,
                leadBaseFilters: action.leadBaseFilters,
                sortFilters: action.sortFilters,
            };

        case TOGGLE_SHOW_HEADER:
            return {
                ...state,
                isShowHeader: action.isShowHeader,
            };

        case TOGGLE_PASSWORD_OTP_LOGIN:
            return {
                ...state,
                isFetching: action.isFetching,
                isAuthenticated: action.isAuthenticated,
                isRequestingOtp: action.isRequestingOtp,
                isVerifyingOTP: action.isVerifyingOTP,
                isOTPSent: action.isOTPSent,
                isVerifyingPassword: action.isVerifyingPassword,
            };

        case VERIFYING_OTP:
            return {
                ...state,
                isVerifyingOTP: action.isVerifyingOTP
            };

        case LOAD_HOME_PAGE:
            return {
                ...state,
                homepage: action.homepage,
            };

        case TOGGLE_ADD_POST:
            return {
                ...state,
                showAddPost: !state.showAddPost,
            };

        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isSideBarShow: !state.isSideBarShow,
            };

        case EDIT_LOGIN_NUMBER:
            return {
                ...state,
                isOTPSent: action.isOTPSent,
            };

        case REQUEST_CHECK_TOKEN:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                isFetching: action.isFetching,
                isCheckingForToken: action.isCheckingForToken,
            };

        case TOKEN_VALID:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                isFetching: action.isFetching,
                user: action.data,
                isCheckingForToken: action.isCheckingForToken
            };

        case TOKEN_INVALID:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                isFetching: action.isFetching,
                isCheckingForToken: action.isCheckingForToken
            };

        case REQUEST_LOGIN:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                isFetching: action.isFetching,
                user: action.creds,
                message: '',
                isVerifyingPassword: action.isVerifyingPassword
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                isFetching: action.isFetching,
                isSideBarShow: true,
                message: '',
                user: action.data,
                redirectUrl: action.redirectUrl,
                isVerifyingPassword: action.isVerifyingPassword
            };

        case LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                isFetching: false,
                message: action.message,
                isVerifyingPassword: action.isVerifyingPassword,
            };

        case REQUEST_OTP_LOGIN:
            return {
                ...state,
                isRequestingOtp: action.isRequestingOtp,
                isOTPSent: action.isOTPSent
            };

        case REQUEST_OTP_FAILURE:
            return {
                ...state,
                isRequestingOtp: action.isRequestingOtp,
                isOTPSent: action.isOTPSent,
                message: action.message,
            };

        case REQUEST_OTP_SUCCESS:
            return {
                ...state,
                isRequestingOtp: action.isRequestingOtp,
                isOTPSent: action.isOTPSent,
                message: action.message,
            };

        case LOGIN_OTP_SUCCESS:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                isFetching: action.isFetching,
                isSideBarShow: true,
                message: '',
                user: action.data,
                isRequestingOtp: action.isRequestingOtp,
                isOTPSent: action.isOTPSent,
                redirectUrl: action.redirectUrl
            };

        case LOGIN_OTP_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                isFetching: false,
                isRequestingOtp: action.isRequestingOtp,
                isVerifyingOTP: action.isVerifyingOTP,
                // isOTPSent: action.isOTPSent,
                message: action.message,
            };


        case REQUEST_LOGOUT:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                isFetching: action.isFetching,
            };

        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                isFetching: false,
                message: action.message,
            };

        case ACCESSLIST_ADDED:
            return {
                ...state,
                venueList: action.venueList,
                venueDetail: action.venueDetail,
                isFetchingAccessList: action.isFetchingAccessList
            };

        case MEHNDILIST_ADDED:
            return {
                ...state,
                mehndiList: action.mehndiList,
                isFetchingAccessList: action.isFetchingAccessList
            };

        case LOAD_ACTIVE_VENUE:
            return {
                ...state,
                activeVenue: action.activeVenue
            };
        case LOAD_ACTIVE_MEHNDI:
            return{
                ...state,
                activeMehndi:action.activeMehndi
            }
            case FETCH_TASK_CONTENT:
                return {
                    ...state,
                    callingStatusList: action.taskContent.callingStatusList,
                };    

        default:
            return state;
    }
};

/* Selectors */
// Get showAddPost
export const getDashboardGraphLeadsStats = state => state.app.current_month_leads_dashboard_graph_data;
export const getShowHeaderStatus = state => state.app.isShowHeader;
export const getCheckingTokenStatus = state => state.app.isCheckingForToken;
export const getAccessListFetchingStatus = state => state.app.isFetchingAccessList;
export const getScreenLoaderStatus = state => state.app.isShowScreenLoader;

export const getShowAddPost = state => state.app.showAddPost;

export const getAuthenticatedState = state => state.app.isAuthenticated;

export const getRedirectUrl = state => state.app.redirectUrl;

export const getSessionUserId = state => !isEmpty(state.app.user) ? state.app.user._id : null;
export const getUserPermissions = state => state.app.userPermissions ? state.app.userPermissions : [];

export const getActiveVenueId = state =>  !isEmpty(state.app.activeVenue) ? state.app.activeVenue._id : '';

export const getActiveVenue = state =>  !isEmpty(state.app.activeVenue) ? state.app.activeVenue : {};

export const getActiveMehndiId = state =>  !isEmpty(state.app.activeMehndi) ? state.app.activeMehndi._id : '';

export const getActiveMehndi = state =>  !isEmpty(state.app.activeMehndi) ? state.app.activeMehndi : {};

export const getVenueList = state =>  !isEmpty(state.app.venueList) ? state.app.venueList : [];
export const getVenueDetail = state =>  !isEmpty(state.app.venueDetail) ? state.app.venueDetail : [];
export const getMehndiList = state =>  !isEmpty(state.app.mehndiList) ? state.app.mehndiList : [];
export const getHomePage = state => state.app.homepage;
export const getOTPVerifyingState = state => state.app.isVerifyingOTP;
export const getPasswordProcessingState = state => state.app.isVerifyingPassword;

export const getAccesslist = state => state.app.accesslist;

export const getOTPRequestState = state => state.app.isRequestingOtp;

export const getOtpSentState = state => state.app.isOTPSent;

export const getUserName = state => state.app.user ? state.app.user.username : "";
export const getUser = state => state.app.user ? state.app.user : {};
export const getUserAgent = state => state.app.user ? { name : state.app.user.contact_name, phone: state.app.user.contact_phone, _id: state.app.user._id} : {};

export const getCityList = state => state.app.cities;
export const getSortFilters = state => state.app.sortFilters;
export const getFilters = state => state.app.leadBaseFilters;
export const getLeadManageParentPath = state => state.app.isLeadManageFromLeadList;

export const getVenueActiveLeadCount = state => state.app.active_leads_count;
export const getVenueCurrentMonthLeads = state => state.app.current_month_leads;
export const getVenueLastMonthLeads = state => state.app.last_month_leads;
export const getVenueConvertedLeadCount = state => state.app.converted_leads_count;
export const getVenueConvertedLeadsGMV = state => state.app.converted_leads_gmv;
export const getLeadClosedLostReasons = state => state.app.closedLostReasons;
export const getLeadStatusList = state => state.app.leadStatusList;

export const getCallStatusList = state => state.app.callStatusList;
export const getPartnerApprovals = state => state.app.partnerApprovalList;
export const getPartnerApprovalCount = state => state.app.partnerApprovalCount;
export const getPartnerApprovalPageNum = state => state.app.partnerApprovalPageNum;
export const getPartnerApprovalPageSize = state => state.app.partnerApprovalPageSize;
export const getPartnerApprovalFetchingStatus = state => state.app.isFetchingPartnerApprovals;
export const getPartnerApprovalUpdatingStatus = state => state.app.isUpdatingApproval;

/* Static Content selectors */

export const getAppStaticContent = state => state.app.siteStaticContent.APP;

export const getDashboardStaticContent = state => state.app.siteStaticContent.DASHBOARD

export const getBookingStaticContent = state => state.app.siteStaticContent.BOOKING;

export const getLeadManageStaticContent = state => state.app.siteStaticContent.LEAD_MANAGE;

export const getLeadsStaticContent = state => state.app.siteStaticContent.LEADS;

export const getMemberStaticContent = state => state.app.siteStaticContent.MEMBER;

export const getPaymentStaticContent = state => state.app.siteStaticContent.PAYMENT;

export const getVenueStaticContent = state => state.app.siteStaticContent.VENUE;
export const getCurrentMonthLeads = state => (state.app.current_month_leads)
export const getCurrentMonthActiveLeads = state => (state.app.current_month_active_leads)
export const getLastMonthLeads = state => (state.app.last_month_leads)
export const getLastMonthActiveLeads = state => (state.app.last_month_active_leads)
export const getCurrentMonthBookings = state => (state.app.current_month_bookings)
export const getLastMonthActiveBookings = state => (state.app.last_month_active_bookings)
export const getCurrentMonthRevenue = state => (state.app.current_month_revenue)
export const getLastMonthRevenue = state => (state.app.last_month_revenue)
export const getCurrentRejectedMonthLeads = state => (state.app.current_month_rejected_leads)
export const getCurrentAcceptedMonthLeads = state => (state.app.current_month_accepted_leads)
export const getLastRejectedMonthLeads = state => (state.app.last_month_rejected_leads)
export const getLastAcceptedMonthLeads = state => (state.app.last_month_accepted_leads)
export const getLastClosedLostMonthLeads = state => (state.app.last_month_closed_lost_leads)
export const getCurrentClosedWonMonthLeads = state => (state.app.current_month_closed_lost_leads)



export const getAgentList = state => (state.app.agentList)

export const getVenueRoles = state => (state.app.venueRoles)

export const getPartnerAccount = state => (state.app.partneraccount)
export const getVenueSpaceList = state => (state.app.venuespacedata)
export const getCallingStatusList = (state) => state.app.callingStatusList;
export const getVenuekey = state => (state.app.venuekey)
export const getOperationalcities = state => (state.app.operationalCity)
export const getPivotStatusLeads = state => (state.app.pivotstatusleads)
export const getAgentWisePivotLeads = state => (state.app.agentwisepivotleads)
export const getSelectedCardType = state => (state.app.selectedtype)


















export default AppReducer;

const createStyles = (customStyles, styles) => {
    let newStyles = {...styles};

    for (let style in customStyles) {
        if (!!!styles[style]) {
            newStyles[style] = customStyles[style];
        } else if (styles[style] !== customStyles[style]) {
            newStyles[style] = customStyles[style];
        }
    }

    return newStyles;
};

const CustomCard = ({customStyles, children}) => {
    let cardStyles = createStyles(customStyles, styles.cardStyles);
    return <div style={{...cardStyles}}>{children}</div>;
};

export const CardHeader = ({customStyles, children}) => {
    let cardHeaderStyles = createStyles(customStyles, styles.cardHeaderStyles);
    return <div style={cardHeaderStyles}>{children}</div>;
};

export const CardBody = ({customStyles, data, children}) => {
    let cardBodyStyles = createStyles(customStyles, styles.cardBodyStyles);
    return (
        <div style={cardBodyStyles}>
            {data
                ? data.map((child, index) => (
                    <div
                        key={index}
                        style={styles.cardChild}
                    >
                        {child.label && (
                            <div style={styles.childLabel}>{child.label}</div>
                        )}
                        {child.value &&
                            <div style={styles.childValue}>{child.value}</div>}
                    </div>
                ))
                : children}
        </div>
    );
};

export const CardFooter = ({customStyles, children}) => {
    let cardFooterStyles = createStyles(customStyles, styles.cardFooterStyles);
    return <div style={cardFooterStyles}>{children}</div>;
};

export default CustomCard;

const styles = {
    cardStyles: {
        border: "1px solid #cddeff",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minWidth: "60%",
        width: "100%",
        minHeight: "40px",
        flexWrap: 'wrap',
    },
    cardHeaderStyles: {
        borderBottom: "1px solid black",
        minHeight: "20px",
        position: "relative",
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px',
    },
    cardBodyStyles: {
        minHeight: "40px",
        display: "flex",
        width: "100%",
        padding: "20px",
        flexWrap: "wrap",
    },
    cardFooterStyles: {
        borderTop: "1px solid black",
        minHeight: "20px",
        position: "relative",
        bottom: "0px",
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
    },
    cardChild: {
        width: "50%",
        padding: "10px 0px 10px 10px",
        textAlign: "left"
    },
    childLabel: {
        marginBottom: "8px",
        fontSize: '10px',
        color: '#595858'
    },
    childValue: {
        fontSize: '14px',
        color: '#1A1A1A',
        fontWeight: '500'
    }
};

import React, { Component } from 'react';
import {Button} from "react-bootstrap";
import BasicLoader from '../../../../../components/Loader/BasicLoader';
import isNumeric from 'validator/lib/isNumeric';
import SpinnerLoader from '../../../../../components/Loader/Spinner';
import _ from 'lodash';
import {Checkbox} from "antd";

class EditPackage extends Component{
  constructor(props){
    super(props);
    this.state = {
      package: {},
      menu: []
    };
  }

  componentDidMount() {
    let menu = this.props.menu ? this.props.menu : [];
    this.setState({
      package: this.props.package,
      menu: menu
    }, () => {
      this.handlePrapareMenu(menu);
    })
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(this.props, prevProps)) {
      let menu = this.props.menu ? this.props.menu : [];
      this.setState({
        package: this.props.package,
        menu: menu
      }, () => {
        this.handlePrapareMenu(menu);
      })
    }
  }

  handlePrapareMenu = (menu) => {
    const newMenu = menu.map(item => {
      const newItem = { ...item }; // Create a new object
      if (newItem.count && newItem.count > 0) {
        newItem.isSelected = true;
        newItem.selectedCount = newItem.count;
      } else {
        newItem.isSelected = false;
        newItem.selectedCount = 0;
      }
      return newItem;
    });
    this.setState({
      menu: newMenu
    });
  };

  handleMethodOfContactChange = () => {

  };

  checkPackageValidation = (pack) => {
    let isValid = true;
    if(!!!pack.name || pack.name === '') {
      isValid = false;
      this.props.showMessage('Please Provide Package Name.', 'warning');
    } else if(!!!pack.package_price || pack.package_price === '') {
      isValid = false;
      this.props.showMessage('Please Provide Package price', 'warning');
    } else if(!isNumeric(pack.package_price)) {
      isValid = false;
      this.props.showMessage('Please Enter Numeric Package price', 'warning');
    } else if(!!!pack.menuItems || pack.menuItems.length === 0) {
      isValid = false;
      this.props.showMessage('Please Select Some Menu Items', 'warning');
    }
    return isValid;
  };

  savePackage = () => {
    let newPackage = {...this.props.package};
    newPackage.name = this.packageName.value;
    newPackage.package_price = this.packagePrice.value;
    newPackage.menuItems = [...this.state.menu].filter(item => item.isSelected === true);
    newPackage.menuItems = newPackage.menuItems.map(item => {
      return {
        count: item.selectedCount,
        menuId: item._id,
        name: item.name,
      };
    });
    if(this.checkPackageValidation(newPackage)) {
      this.props.updatePackage(newPackage);
    }
  };

  handleIncrementMenuItem = (id) => {
    const menu = this.state.menu.map(item => {
      if (item._id === id) {
        const newItem = { ...item }; // Create a new menu item object
        if (newItem.selectedCount === 0) {
          newItem.isSelected = true;
        }
        if (newItem.count) {
          if (newItem.selectedCount < newItem.count) {
            newItem.selectedCount = newItem.selectedCount + 1;
          } else {
            this.props.showMessage('Max count reached', 'warning');
          }
        } else {
          newItem.selectedCount = newItem.selectedCount + 1;
        }
        return newItem;
      }
      return item;
    });

    this.setState({
      menu: menu
    });
  };

  handleDecrementMenuItem = (id) => {
    const menu = this.state.menu.map(item => {
      if (item._id === id) {
        const newItem = { ...item }; // Create a new menu item object
        if (newItem.selectedCount > 0) {
          newItem.selectedCount = newItem.selectedCount - 1;
        }
        if (newItem.selectedCount === 0) {
          newItem.isSelected = false;
        }
        return newItem;
      }
      return item;
    });

    this.setState({
      menu: menu
    });
  };

  handleSelectMenuItem = (id, value) => {
    const menu = this.state.menu.map(item => {
      if (item._id === id) {
        const newItem = { ...item }; // Create a new menu item object
        newItem.isSelected = !value;
        if (newItem.isSelected) {
          newItem.selectedCount = 1;
        } else {
          newItem.selectedCount = 0;
        }
        return newItem;
      }
      return item;
    });

    this.setState({
      menu: menu
    });
  };


  render() {

    let staticContent = this?.props?.staticContent ? this.props.staticContent : {};
    let responsiveCondition = this.props.windowWidth > this.props.breakpoint
    return (
      <div className="col-sm-12" style={{padding: 0}}>
        <div className="row">
          <div className={responsiveCondition ? "col-6" : "col-12"}>
            <label style={{fontSize: '14px'}} >{staticContent.PACKAGE_PAGE_PACKAGE_NAME_LABEL ? staticContent.PACKAGE_PAGE_PACKAGE_NAME_LABEL : 'Package Name'}</label>
            <div className="input-group">
              <input
                type="text"
                defaultValue={this?.state?.package?.name ? _.startCase(this.state.package.name) : ''}
                className="form-control"
                aria-label="Phone"
                aria-describedby="userSms-addon"
                ref = {(ref) => this.packageName = ref}
              />
            </div>
          </div>
          <div className={responsiveCondition ? "col-6" : "col-12"}>
            <label >{staticContent.PACKAGE_PAGE_PACKAGE_PRICE_LABEL ? staticContent.PACKAGE_PAGE_PACKAGE_PRICE_LABEL : 'Price'}</label>
            <div className="input-group">
              <input
                type="text"
                defaultValue={this?.state?.package?.package_price ? this.state.package.package_price : ''}
                className="form-control"
                aria-label="Phone"
                aria-describedby="userSms-addon"
                ref = {(ref) => this.packagePrice = ref}
              />
            </div>
          </div>
        </div>

        <div style={{position: 'relative', display: 'block', marginTop: '25px'}}>
          <div>{staticContent.PACKAGE_PAGE_PACKAGE_MENU_TITLE ? staticContent.PACKAGE_PAGE_PACKAGE_MENU_TITLE : 'Menu'}</div>
          <div style={{position: 'relative', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
          {
              this?.state?.menu && this?.state?.menu?.length > 0 && this?.state?.menu.map(item => (
                <div key={item._id} style={{position: 'relative', display: 'flex', justifyContent: 'space-between', marginTop: '5px', width: '48%'}}>
                  <span style={{ cursor: 'pointer'}} onClick={() => this.handleSelectMenuItem(item._id, item.isSelected)}>
                    <span>
                      <Checkbox checked={item.isSelected && item.isSelected}/>
                      {/*<i className={`fa ${item.isSelected ? 'fa-check-square-o' : 'fa-square-o'}`}></i>*/}
                    </span>
                    <span style={{marginLeft: '15px'}}>{item.name}</span>
                  </span>
                  {
                    item?.isSelected && item?.selectedCount > 0 ?
                      <span  style={{border: '1px solid #D6D6D6', borderRadius: '3px', fontSize: '12px', display: 'flex', lineHeight: '1.7'}}>
                      <span style={{padding: '0px 7px', color: '#0057FF', cursor: 'pointer'}} onClick={() => this.handleDecrementMenuItem(item._id)}>-</span>
                      <span style={{padding: '0px 7px', backgroundColor: '#f8f8f8'}}>{item.selectedCount && item.selectedCount}</span>
                      <span style={{padding: '0px 7px', color: '#0057FF', cursor: 'pointer'}} onClick={() => this.handleIncrementMenuItem(item._id)} >+</span>
                    </span> : null
                  }
                  {
                    item.selectedCount === 0 ?
                      <span onClick={() => this.handleIncrementMenuItem(item._id)} style={{border: '1px solid #D6D6D6', cursor: 'pointer', borderRadius: '3px', color: '#737373', fontSize: '12px', display: 'flex', lineHeight: '1.7'}}>
                              <span style={{padding: '0px 7px', marginRight: '3px'}}>{'Add'}</span>
                              <span style={{padding: '0px 7px', color: '#0057FF'}}>+</span>
                           </span>: null
                  }
                  {/*{*/}
                  {/*  !item.isSelected ?*/}
                  {/*    <span style={{border: '1px solid #D6D6D6', borderRadius: '3px', fontSize: '12px', display: 'flex', lineHeight: '1.7'}}>*/}
                  {/*          <span style={{padding: '0px 7px', marginRight: '3px', color: '#737373'}}>{'Add'}</span>*/}
                  {/*          <span style={{padding: '0px 7px', color: '#0057FF', opacity: 0.6}}>+</span>*/}
                  {/*    </span> : null*/}
                  {/*}*/}
                </div>
              ))
            }
          </div>
        </div>


        <div style={{position: 'relative',  display: 'block', marginTop: '35px'}}>
          <Button disabled={this?.props?.isUpdatingSubPackage && this.props.isUpdatingSubPackage} className="btn btn-primary pull-right"  style={{width: '200px'}} onClick={() => this.savePackage()} >{this.props.isUpdatingSubPackage ? <SpinnerLoader/> : staticContent.PACKAGE_PAGE_PACKAGE_SAVE_BUTTON_TEXT ? staticContent.PACKAGE_PAGE_PACKAGE_SAVE_BUTTON_TEXT : 'SAVE'}</Button>
        </div>
      </div>
    );
  }
}

export default EditPackage;

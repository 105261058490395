import React, { Component } from 'react';
import _ from 'lodash'
import { Button } from 'react-bootstrap';
import { callingStatusList } from 'constants/constants';
import { Select } from 'antd';
import { updateLastCallStatus } from 'modules/Lead/LeadActions';
import { connect } from 'react-redux';
import { withRoute } from 'util/withRoute';

class CallStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastCallStatus:'',
            lead: {},
            showAllCallStatus: false,
        };

    }

    handleClickMoreStatus = () => {
        this.setState((prevState) => ({
            showAllCallStatus: !prevState.showAllCallStatus
        }));
    }
    


    handleChange = (keyName, keyValue) => {
        let newLead = { ...this.state.lead };
        newLead[keyName] = keyValue;
        this.setState(
            {
                lead: newLead,
            },
            () => this.handleUpdateKey(keyName, keyValue)
        );
    };

    handleUpdateKey = (keyName, keyValue, type) => {
        let lead = { ...this.state.lead };
        lead[keyName] = keyValue;
        this.setState({
            lead: lead,
        });
    };

    handleChangeCallingStatus = (status) => {
      
        this.setState({
            lastCallStatus: status?.value
        }, () => {
            this.handleChange("active_call_status", status?.value);
        });
    };


    render() {

        return (
    <div>
             <div style={{width: this.props.width >768 ? '100%' : '30%'}}>
                    
                            <form className="form-inline editableform">
              <div className="control-group form-group">
                <div
                  className="quality-edit-popover"
                  style={{ display: "flex" }}
                >
                  <div
                    className="editable-input"
                    style={{ display: "inline-block" }}
                  >
                <Select
          style={{ width: this.props.width > 768 ? '170px' : '75px'}}
          placeholder="Select CallStatus"
          labelInValue={true}
          onChange={this.handleChangeCallingStatus}
          value={this.state.lastCallStatus ? this.state.lastCallStatus : this.props.lead.active_call_status}
          options={callingStatusList}
          className='selectStyles'
        />
                  </div>
                  <div
                    className="editable-buttons"
                    style={{ display: "inline-block", marginLeft: "5px" }}
                  >
                    <Button
                    style={{
                        textAlign: 'center',
                        color: 'white',
                        backgroundColor: '#004ad9',
                        border: 'none',
                        borderRadius:'4px',
                        padding:'4px 8px'
                    }}
                      onClick={() => this.props.saveCallStatus()}
                      bsStyle="success"
                      bsSize="xsmall"
                    >
                      <i className="fa fa-check"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
                    
                    </div>
    </div>
               
                    
        );
    }
}

export default connect()(withRoute(CallStatus));


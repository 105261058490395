import {
  UPDATE_MEMBER_LIST,
  FETCH_MEMBER_LIST_STATUS,
  ADD_MEMBER_IN_TO_LIST,
  ADD_MEMBER_STATUS,
  UPDATE_MEMBER_IN_TO_LIST,
  UPDATE_MEMBER_STATUS,
  DELETE_MEMBER_STATUS,
  DELETE_MEMBER_FROM_LIST, FETCH_MEMBER,
  LOAD_EDIT_PERMISSIONS,
  LOAD_PERMISSION_UPDATING_STATUS, CREATE_PARTNER_ACCOUNT_OBJECT_MAP,
} from './MemberActions';

  const initialState = {
    list: [],
    isFetching: false,
    count: 0,
    pageNum: 1,
    pageSize: 25,
    isUpdatingMember: false,
    isAddingMember: false,
    isDeletingMember: false,
    member: {},
    editPermissions: [],
    permissionModuleList: [],
    isUpdatingPermission: false,
    partnerAccountObjectMap: {}
  };

  const MemberReducer = (state = initialState, action) => {
    let memberList = [...state.list];
    switch (action.type) {

      case CREATE_PARTNER_ACCOUNT_OBJECT_MAP:
        let objectMap = {}
          action.list && action.list.length > 0 && action.list.map(member => {
            objectMap[member.partneraccountId] = {...member}
          })
        return {
          ...state,
          partnerAccountObjectMap: objectMap
        };

      case LOAD_PERMISSION_UPDATING_STATUS:
        return {
          ...state,
          isUpdatingPermission: action.isUpdatingPermission,
        };

      case LOAD_EDIT_PERMISSIONS:
        return {
          ...state,
          permissionModuleList: action.permissionModuleList ? action.permissionModuleList : [],
          editPermissions: action.editPermissions ? action.editPermissions : [],
          editUser: action.editUser
        };

      case FETCH_MEMBER:
        return {
            ...state,
            member: action.member
        };

      case UPDATE_MEMBER_LIST:
        return {
          ...state,
          list: action.list,
          count: action.count,
          pageNum: action.pageNum,
        };

      case FETCH_MEMBER_LIST_STATUS:
        return {
          ...state,
          isFetching: action.isFetching,
        };

      case ADD_MEMBER_IN_TO_LIST:
        memberList.unshift(action.member);
        return {
          ...state,
          list: memberList,
          isAddingMember: action.isAddingMember,
        };

      case ADD_MEMBER_STATUS:
        return {
          ...state,
          isAddingMember: action.isAddingMember,
        };

      case UPDATE_MEMBER_IN_TO_LIST:
        memberList = memberList.map(member => {
          if(member._id === action.member._id){
            member = action.member;
          }
          return member;
        });
        return {
          ...state,
          list: memberList,
          isUpdatingMember: action.isUpdatingMember
        };

      case UPDATE_MEMBER_STATUS:
        return {
          ...state,
          isUpdatingMember: action.isUpdatingMember
        };

      case DELETE_MEMBER_STATUS:
        return {
          ...state,
          isDeletingMember: action.isDeletingMember,
        };

      case DELETE_MEMBER_FROM_LIST:
        memberList = memberList.filter(member => (member._id !== action.memberId));
        return {
          ...state,
          list: memberList,
        };

      default :
        return {
          ...state,
        };
    }
  };


  //Selectors
  export const getMemberList = state => state.member.list ? state.member.list : [];
  export const getMemberCount = state => state.member.count ? state.member.count : 0;
  export const getMemberPageNum = state => state.member.pageNum ;
  export const getMemberPageSize = state => state.member.pageSize;
  export const getMemberListStatus = state => state.member.isFetching;
  export const getMemberUpdateStatus = state => state.member.isUpdatingMember;
  export const getMemberAddStatus = state => state.member.isAddingMember;
  export const getMemberDeleteStatus = state => state.member.isDeletingMember;
  export const getMember = state => state.member.member;
  export const getEditPermissions = state => state.member.editPermissions;
  export const getPermissionModuleList = state => state.member.permissionModuleList;
  export const getEditPermissionUser = state => state.member.editUser;
  export const getUpdatingPermissionStatus = state => state.member.isUpdatingPermission;
  export const getPartnerAccountObjectMap = state => state.member.partnerAccountObjectMap;


  export default MemberReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ToastBox extends React.Component {
  render() {
    const { notifications } = this.props;

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {notifications?.map((notification) => {
          switch (notification.level) {
            case 'success':
              toast.success(notification.message);
              break;
            case 'info':
              toast.info(notification.message);
              break;
            case 'warning':
              toast.warning(notification.message);
              break;
            case 'error':
              toast.error(notification.message);
              break;
            default:
              toast(notification.message);
              break;
          }
          return null;
        })}
      </div>
    );
  }
}

ToastBox.propTypes = {
  notifications: PropTypes.array,
};

export default connect((state) => ({ notifications: state.toasts }))(ToastBox);

import _ from 'lodash';
import {
  GET_LEAD_DETAILS,
  UPDATE_FETCH_LEAD_STATUS,
  UPDATE_FETCH_SUBPACKAGE_STATUS,
  GET_SUBPACKAGE_LIST,
  UPDATE_FETCH_SMS_STATUS,
  GET_SMS_LIST,
  GET_EMAIL_LIST,
  UPDATE_FETCH_EMAIL_STATUS,
  GET_VISIT_LIST,
  UPDATE_FETCH_VISIT_STATUS,
  UPDATE_VISIT_LIST,
  ADD_NEW_SUB_PACKAGE,
  UPDATE_SUB_PACKAGE_LIST,
  DELETE_SUB_PACKAGE,
  GET_SMS_TEMPLATE_LIST,
  GET_EMAIL_TEMPLATE_LIST,
  GET_LEAD_LIST,
  LEAD_LIST_STATUS,
  ADDING_NEW_SUB_PACKAGE_STATUS,
  SENDING_EMAIL_STATUS,
  SENDING_SMS_STATUS,
  UPDATING_SUB_PACKAGE_STATUS,
  LEAD_UPDATING_STATUS,
  LOAD_LEAD_LOGS,
  ADD_VENUEBUNDLE_NOTE,
  UPDATE_VENUEBUNDLE_NOTE,
  REMOVE_VENUEBUNDLE_NOTE,
  LOAD_VENUEBUNDLE_NOTES, NOTE_UPDATING_STATUS, GET_PRIMARY_ASSETS_LIST,
  LEAD_MANAGE_MODAL_VISIBLE,
  SET_LEAD_ID,SHOW_FULL_VIEW_TAB_VISIBLE
} from './LeadManageActions';

const initialState = {
  lead: {},
  isFetchingLeadDetail: false,
  subPackages: [],
  subPackageCount: 0,
  subPackagePageSize: 15,
  subPackagePageNum: 1,
  isFetchingSubPackage: false,
  smsList: [],
  smsCount: 0,
  smsPageSize: 15,
  smsPageNum: 1,
  isFetchingSms: false,
  emailList: [],
  emailCount: 0,
  emailPageSize: 15,
  emailPageNum: 1,
  isFetchingEmails: false,
  visits: [],
  visitCount: 0,
  visitPageNum: 1,
  visitPageSize: 15,
  isFetchingVisits: false,
  isAddingVisit: false,
  isUpdatingVisit: false,
  isRemovingVisit: false,
  smsTemplates: [],
  smsTemplateCount: 0,
  emailTemplates: [],
  emailTemplateCount: 0,
  leads: [],
  leadCount: 0,
  isFetchingLeads: false,
  isAddingSubPackage: false,
  isSendingSms: false,
  isUpdatingSubPackage: false,
  isUpdatingLead: false,
  leadLogs: [],
  venuebundlenotes: [],
  isAddingVenueBundleNote: false,
  isUpdatingVenueBundleNote: false,
  isFetchingVenueBundleNotes: false,
  venuebundlenotesCount: 0,
  menus: [],
  contact: {},
  isFetchingAssets: false,
  isLeadManageModalVisible: false,
  isFullViewTabvisible: false,
  // selectedLeadId:null,
  selectedTab: 1,
};

const LeadManageReducer = (state = initialState, action) => {
  let tempSubPackageList = [...state.subPackages];
  let tempVenueBundleNotes = [...state.venuebundlenotes];
  switch (action.type) {
    case LEAD_MANAGE_MODAL_VISIBLE:
      return {
        ...state,
        isLeadManageModalVisible: action.isLeadManageModalVisible,
        selectedTab: action.selectedTab,
      };

      case SHOW_FULL_VIEW_TAB_VISIBLE:
        return {
          ...state,
          isFullViewTabvisible: action.isFullViewTabvisible,
        };

      case SET_LEAD_ID:
        return {
            ...state,
            selectedLeadId: action.selectedLeadId,
        };  
    case GET_PRIMARY_ASSETS_LIST:
      return {
        ...state,
        menus: action.menus,
        contact: action.contact,
        packages: action.packages,
        isFetchingAssets: action.isFetchingAssets,
      }

    case GET_SUBPACKAGE_LIST:
      // console.log(action, 'get')
      return {
        ...state,
        subPackages: action.subpackages,
        isFetchingSubPackage: action.isFetchingSubPackage,
      };
    case NOTE_UPDATING_STATUS:
      return {
        ...state,
        isUpdatingVenueBundleNote: action.isUpdatingVenueBundleNote,
      };
    case LOAD_VENUEBUNDLE_NOTES:
      return {
        ...state,
        venuebundlenotes: action.venuebundlenotes ? action.venuebundlenotes : [],
        venuebundlenotesCount: action.venuebundlenotesCount ? action.venuebundlenotesCount : 0
      };

    case ADD_VENUEBUNDLE_NOTE:
      tempVenueBundleNotes.push(action.venuebundlenote);
      return {
        ...state,
        venuebundlenotes: tempVenueBundleNotes,
        venuebundlenotesCount: state.venuebundlenotesCount + 1,
        isAddingVenueBundleNote: false
      };
    case REMOVE_VENUEBUNDLE_NOTE:
      tempVenueBundleNotes = tempVenueBundleNotes.filter(venuebundlenote => (venuebundlenote._id !== action.venuebundlenote._id));
      return {
        ...state,
        venuebundlenotes: tempVenueBundleNotes,
        venuebundlenotesCount: state.venuebundlenotes + 1
      };

    case UPDATE_VENUEBUNDLE_NOTE:
      tempVenueBundleNotes = tempVenueBundleNotes.map(venuebundlenote => {
        if(venuebundlenote._id === action.venuebundlenote._id){
          venuebundlenote = action.venuebundlenote;
        }
        return venuebundlenote;
      });
      return {
        ...state,
        venuebundlenotes: tempVenueBundleNotes,
        isUpdatingVenueBundleNote: false
      };

    case LOAD_LEAD_LOGS:
      return {
        ...state,
        leadLogs: action.leadLogs
      };

    case LEAD_UPDATING_STATUS:
      return {
        ...state,
        isUpdatingLead: action.isUpdatingLead,
      };

    case UPDATING_SUB_PACKAGE_STATUS:
      return {
        ...state,
        isUpdatingSubPackage: action.isUpdatingSubPackage,
      };

    case SENDING_SMS_STATUS:
      return {
        ...state,
        isSendingSms: action.isSendingSms,
      };

    case SENDING_EMAIL_STATUS:
      return {
        ...state,
        isSendingEmail: action.isSendingEmail,
      };

    case ADDING_NEW_SUB_PACKAGE_STATUS:
      return {
        ...state,
        isAddingSubPackage: action.isAddingSubPackage,
      };

    case LEAD_LIST_STATUS:
      return {
        ...state,
        isFetchingLeads: action.isFetchingLeads
      };

    case GET_LEAD_LIST:
      return {
        ...state,
        leads: action.leads,
        leadCount: action.leadCount,
        isFetchingLeads: action.isFetchingLeads
      };

    case GET_EMAIL_TEMPLATE_LIST:
      return {
        ...state,
        emailTemplates: action.emailTemplates,
        emailTemplateCount: action.emailTemplateCount
      };

    case GET_SMS_TEMPLATE_LIST:
      return {
        ...state,
        smsTemplates: action.smsTemplates,
        smsTemplateCount: action.smsTemplateCount
      };

    case DELETE_SUB_PACKAGE:
      tempSubPackageList = tempSubPackageList.filter(subpackage => (subpackage._id !== action.subpackage._id));
      return {
        ...state,
        subPackages: tempSubPackageList,
        subPackageCount: state.subPackageCount + 1
      };

    case UPDATE_SUB_PACKAGE_LIST:
      // console.log(action, 'update')
      tempSubPackageList = tempSubPackageList.map(subpackage => {
        if(subpackage._id === action.subpackage._id){
          subpackage = action.subpackage;
        }
        return subpackage;
      });
      return {
        ...state,
        subPackages: tempSubPackageList,
        isUpdatingSubPackage: action.isUpdatingSubPackage
      };

    case ADD_NEW_SUB_PACKAGE:
      // console.log(action, 'add')
      tempSubPackageList.push(action.subpackage);
      return {
        ...state,
        subPackages: tempSubPackageList,
        // subPackageCount: state.subPackageCount + 1,
        isAddingSubPackage: action.isAddingSubPackage
      };

    case UPDATE_VISIT_LIST:
      let visits = state.visits;
      if(visits && visits.length > 0) {
        visits = visits.map(visit => {
          if(visit._id === action.visit._id){
            visit = action.visit;
          }
          return visit;
        });
      }
      return {
        ...state,
        visits: visits
      };

    case UPDATE_FETCH_VISIT_STATUS:
      return {
        ...state,
        isFetchingVisits: action.isFetchingVisits
      };

    case GET_VISIT_LIST:
      return {
        ...state,
        visits: action.visits,
        visitCount: action.visitCount,
        isFetchingVisits: action.isFetchingVisits
      };

    case UPDATE_FETCH_EMAIL_STATUS:
      return {
        ...state,
        isFetchingEmails: action.isFetchingEmails
      };

    case GET_EMAIL_LIST:
      return {
        ...state,
        emailList: action.emailList,
        emailCount: action.emailCount,
        isFetchingEmails: action.isFetchingEmails
      };

    case UPDATE_FETCH_SMS_STATUS:
      return {
        ...state,
        isFetchingSms: action.isFetchingSms
      };

    case GET_SMS_LIST:
      return {
        ...state,
        smsList: action.smsList,
        smsCount: action.smsCount,
        isFetchingSms: action.isFetchingSms
      };

    case UPDATE_FETCH_SUBPACKAGE_STATUS:
      return {
        ...state,
        isFetchingSubPackage: action.isFetchingSubPackage
      };

    case UPDATE_FETCH_LEAD_STATUS:
      return {
        ...state,
        isFetchingLeadDetail: action.isFetchingLeadDetail
      };

    case GET_LEAD_DETAILS :
      return {
        ...state,
        lead: action.lead,
        isFetchingLeadDetail: action.isFetchingLeadDetail
      };

    default:
      return {
        ...state
      };
  }
};

// BasicDetailsPage
export const getLeadDetails = (state) => state.leadmanage.lead;
export const getLeadLogId = (state) => !_.isEmpty(state.leadmanage.lead) ? state.leadmanage.lead.logId : '';
export const getLeadUpdatingStatus = (state) => state.leadmanage.isUpdatingLead;
export const getVenueBundleNotes = (state) => state.leadmanage.venuebundlenotes ? state.leadmanage.venuebundlenotes : []
export const getAddingVenueBundleNote = (state) => state.leadmanage.isAddingVenueBundleNote;
export const getEditingVenueBundleNote = (state) => state.leadmanage.isUpdatingVenueBundleNote;
export const getBasicDetailPageModalVisible = (state) => state.leadmanage.isLeadManageModalVisible;
export const getFullViewTabVisiblestate = (state) => state.leadmanage.isFullViewTabvisible;
export const getSelectedLeadId = (state) => state.leadmanage.selectedLeadId;
export const getLeadManageSelectedTab = (state) => state.leadmanage.selectedTab;




//EmailListPage
export const getEmailList = (state) => state.leadmanage.emailList;
export const getEmailPageNum = (state) => state.leadmanage.emailPageNum;
export const getEmailPageSize = (state) => state.leadmanage.emailPageSize;
export const getEmailCount = (state) => state.leadmanage.emailCount;
export const getEmailTemplates = (state) => state.leadmanage.emailTemplates;
export const getSendingMailStatus = (state) => state.leadmanage.isSendingEmail;
export const getFetchingMailStatus = (state) => state.leadmanage.isFetchingEmails;

//PackageListPage
export const getSubPackages = (state) => state.leadmanage.subPackages;
export const getSubPackageCount = (state) => state.leadmanage.subPackageCount;
export const getSubPackagePageNum = (state) => state.leadmanage.subPackagePageNum;
export const getSubPackagePageSize = (state) => state.leadmanage.subPackagePageSize;
export const getAddingSubPackageStatus = (state) => state.leadmanage.isAddingSubPackage;
export const getEditingSubPackageStatus = (state) => state.leadmanage.isUpdatingSubPackage;
export const getFetchingSubpackageListStatus = (state) => state.leadmanage.isFetchingSubPackage;
export const getMenuList = (state) => state.leadmanage.menus;
export  const getPackages = (state) => state.leadmanage.packages

//SmsListPage
export const getSmsList = (state) => state.leadmanage.smsList;
export const getSmsPageNum = (state) => state.leadmanage.smsPageNum;
export const getSmsPageSize = (state) => state.leadmanage.smsPageSize;
export const getSmsCount = (state) => state.leadmanage.smsCount;
export const getSmsTemplates = (state) => state.leadmanage.smsTemplates;
export const getSendingSmsStatus = (state) => state.leadmanage.isSendingSms;
export const getFetchingSmsStatus = (state) => state.leadmanage.isFetchingSms;

//VisitPage
export const getVisits = (state) => state.leadmanage.visits;
export const getVisitCount = (state) => state.leadmanage.visitCount;
export const getVisitPageNum = (state) => state.leadmanage.visitPageNum;
export const getVisitPageSize = (state) => state.leadmanage.visitPageSize;

export const getLeadLogs = (state) => state.leadmanage.leadLogs;

export default LeadManageReducer;

import fetch from 'isomorphic-fetch';
import {CURRENT_STAGE, ENV_PRODUCTION} from "../constants/enum";

export const API_URL = process.env.REACT_APP_ID_API_URL || `https://id.venuemonk.com`;

// mobile, desktop --> api
export default function callApi(endpoint, method = 'get', body) {
  let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
  let headers = {};
  headers['content-type'] = 'application/json';
  if (token && token !=='') {
    headers.Authorization = `Bearer ${token}`;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers,
    method,
    body: JSON.stringify(body),
  }).then(response => response.json().then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json);
    }
    return json;
  })
  .then(
    response => response,
    error => error
  );
}

import callApi from '../../util/apiCaller';
import callEventApi from "../../util/eventApiCaller";
import idCallApi from '../../util/idApiCaller'
import {userNotify} from '../../components/Toasts/ToastAdd';
import _ from 'lodash';
import callVenueApi from "../../util/venueApiCaller";

export const FETCH_MEMBER_LIST_STATUS = 'FETCH_MEMBER_LIST_STATUS';
export const UPDATE_MEMBER_LIST = 'UPDATE_MEMBER_LIST';
export const ADD_MEMBER_IN_TO_LIST = 'ADD_MEMBER_IN_TO_LIST';
export const ADD_MEMBER_STATUS = 'ADD_MEMBER_STATUS';
export const UPDATE_MEMBER_IN_TO_LIST = 'UPDATE_MEMBER_IN_TO_LIST';
export const UPDATE_MEMBER_STATUS = 'UPDATE_MEMBER_STATUS';
export const DELETE_MEMBER_STATUS = 'DELETE_MEMBER_STATUS';
export const DELETE_MEMBER_FROM_LIST = 'DELETE_MEMBER_FROM_LIST';
export const FETCH_MEMBER = 'FETCH_MEMBER';
export const LOAD_EDIT_PERMISSIONS = 'LOAD_EDIT_PERMISSIONS';
export const LOAD_PERMISSION_UPDATING_STATUS = 'LOAD_PERMISSION_UPDATING_STATUS';
export const CREATE_PARTNER_ACCOUNT_OBJECT_MAP = "CREATE_PARTNER_ACCOUNT_OBJECT_MAP";

export function fetchMemberListStatus(status) {
    return {
        type: FETCH_MEMBER_LIST_STATUS,
        isFetching: status,
    };
}

export function updateMemberList(data, pageNum) {
    return {
        type: UPDATE_MEMBER_LIST,
        list: data.venueMemberList,
        count: data.venueMemberCount ? data.venueMemberCount : data.venueMemberList.length,
        pageNum: pageNum,
    };
}

export function createPartnerAccountObjectMap(data) {
    return {
        type: CREATE_PARTNER_ACCOUNT_OBJECT_MAP,
        list: data.venueMemberList
    }
}

export function createVenuememberDataWithRole(updatedVenuememberList, filters) {
    return {
        type: UPDATE_MEMBER_LIST,
        list: updatedVenuememberList,
        count: updatedVenuememberList ? updatedVenuememberList.length : 0,
        pageNum: filters.pageNum ? filters.pageNum : 1,
    }
}

export function fetchPartnerAccountRolesFromPartnerAccountId(venueId, data, filters) {
    return (dispatch) => {
        let venueMemberList = [...data.venueMemberList].map((venueMember, index) => {
            return venueMember.partneraccountId
        })
        return idCallApi(`api/v1/partneraccounts/venue/${venueId}/role-list`, 'post', {
            partner_account_ids: venueMemberList
        }).then(res => {
            if (res.status === 'Success') {
                if (res.data && res.data.list) {
                    let updatedVenuememberList = [...data.venueMemberList].map((venuemember, index) => {
                        let updatedVenuemember = {...venuemember}
                        res.data.list.forEach(role => {
                            if (venuemember.partneraccountId === role.partner_account_id) {
                                updatedVenuemember.role = role.role;
                                updatedVenuemember.role_id = role.role_id
                            }
                        })
                        return updatedVenuemember
                    })
                    dispatch(createVenuememberDataWithRole(updatedVenuememberList, filters))
                } else {
                    console.error(res)
                }
            } else {
                console.error(res)
            }
        })
    }
}

export function fetchMemberList(venueId, filters) {
    return (dispatch) => {
        dispatch(fetchMemberListStatus(true));
        return callVenueApi(`venuemember/list`, 'post', {...filters, filters: {venue_id: venueId}}).then(res => {
            if (res.status === 'Success') {
                dispatch(updateMemberList(res.data, filters.pageNum));
                dispatch(fetchPartnerAccountRolesFromPartnerAccountId(venueId, res.data, filters))
                dispatch(createPartnerAccountObjectMap(res.data));
                dispatch(fetchMemberListStatus(false));
            }
        });
    };
}

export function updateMember(data) {
    return {
        type: FETCH_MEMBER,
        member: data.member,
    };
}

export function fetchMemberById(memberId) {
    return (dispatch) => {
        return callVenueApi(`venuemember/${memberId}`, 'get').then(res => {
            if (res.status === 'Success') {
                dispatch(updateMember(res.data));
            }
        });
    };
}

export function updateMemberStatus(status) {
    return {
        type: UPDATE_MEMBER_STATUS,
        isUpdatingMember: status,
    };
}

export function updateMemberInToList(data) {
    return {
        type: UPDATE_MEMBER_IN_TO_LIST,
        member: data.venueMember,
        isUpdatingMember: false,
    };
}

export function updateMemberById(memberId, member) {
    return (dispatch) => {
        dispatch(updateMemberStatus(true));
        return idCallApi(`api/v1/partneraccounts/${member.partneraccountId}/update`, 'post', {partneraccount: member}).then(res => {
            if (res.status === 'Success') {
                return callVenueApi(`venuemember/${memberId}/update`, 'post', {venueMember: member}).then(res => {
                    if (res.status === 'Success') {
                        dispatch(updateMemberInToList(res.data));
                        userNotify("Member Updated Successfully", "success")
                    } else {
                        dispatch(updateMemberStatus(false));
                        userNotify("Not able to update venue member account", "error")
                    }
                });
            } else {
                userNotify('Not able to update partner account', 'error')
                dispatch(updateMemberStatus(false));
                console.error(res)
            }
        })
    };
}

export function addMemberStatus(status) {
    return {
        type: ADD_MEMBER_STATUS,
        isAddingMember: status,
    };
}

export function addMemberInToList(data) {
    return {
        type: ADD_MEMBER_IN_TO_LIST,
        member: data.member,
        isAddingMember: false,
    };
}

export function addNewMember(member) {
    try {
        return (dispatch) => {
            dispatch(addMemberStatus(true));
            return idCallApi(`api/v1/create-partner-account-new`, 'post', member).then(createPartnerResponse => {
                if (createPartnerResponse.status === 'Success') {
                    if (createPartnerResponse.data && createPartnerResponse.data.partnerAccount) {
                        let venueMember = {...member}
                        let partnerAccount = {...createPartnerResponse.data.partnerAccount}
                        venueMember.partneraccountId = partnerAccount._id
                        venueMember.isPartnerAccountActive = true
                        venueMember.isOnboarded = true

                        let partner_access_list = {
                            partner_account_id: partnerAccount._id,
                            ref_id: member.venueId,
                            ref_name: 'venue',
                            role: member.role,
                            role_id: member.role_id,
                            is_owner: member.role === 'owner'
                        }

                        let partnerNew = {
                            partneraccount_id: partnerAccount._id,
                            ref_id: member.venueId,
                            ref_name: 'venue',
                            role: member.role,
                            role_id: member.role_id,
                            is_owner: member.role === 'owner'
                        }


                        return callVenueApi(`venuemember/new`, 'post', {venueMember: venueMember}).then(venuememberResponse => {
                            if (venuememberResponse.status === 'Success') {
                                if (venuememberResponse.data) {
                                    let venueMember = {...venuememberResponse.data.venueMember}
                                    venueMember.partneraccount_id = partnerAccount._id

                                    return idCallApi(`partner_access_list/create`, 'post', {partner_access_list: partner_access_list}).then(accessListResponse => {

                                        if (accessListResponse.status === 'Success') {

                                            return idCallApi(`api/v1/partneraccounts/${partnerAccount._id}/venue/${member.venueId}/activate-account`, 'post', {partneraccount: partnerNew}).then(activateAccountResponse => {
                                                if (activateAccountResponse.status === 'Success') {
                                                    dispatch(addMemberStatus(false));
                                                    dispatch(addMemberInToList(venueMember));
                                                    userNotify("Member Added Successfully", "success")
                                                } else {
                                                    dispatch(addMemberStatus(false));
                                                    dispatch(addMemberInToList(venueMember));
                                                    userNotify('Failed to activate partner account', 'error')
                                                    console.error(activateAccountResponse)
                                                    return idCallApi(`partner_access_list/${partner_access_list._id}/delete`)
                                                }
                                            })
                                        } else {
                                            dispatch(addMemberStatus(false));
                                            dispatch(addMemberInToList(venueMember));
                                            userNotify('Failed to create access list', 'error')
                                            console.error(accessListResponse)
                                            deleteMember(member._id, member.partneraccountId, member.venueId)
                                        }
                                    })
                                }
                            } else {
                                dispatch(addMemberStatus(false));
                                userNotify("Failed! to add Member, try again", "error")
                                return idCallApi(`api/v1/partneraccounts/${partnerAccount._id}/permanently-delete-account`, 'get').then(res => {
                                })
                            }
                        });


                    } else if (createPartnerResponse.data) {
                        dispatch(addMemberStatus(false));
                        console.error(createPartnerResponse.data.message ? createPartnerResponse.data.message : 'an error occurred')
                    }
                } else {
                    dispatch(addMemberStatus(false));
                    userNotify('Could not create Partner account', 'error')
                    console.error(createPartnerResponse)
                }
            })
        }
    } catch (e) {
        console.error(e)
    }
}


export function deleteMemberStatus(status) {
    return {
        type: DELETE_MEMBER_STATUS,
        isDeletingMember: status,
    };
}

export function deletMemberFromList(id) {
    return {
        type: DELETE_MEMBER_FROM_LIST,
        memberId: id,
        isDeletingMember: false,
    };
}

export function deleteMember(memberId, partneraccount_id, venueId) {
    return (dispatch) => {
        dispatch(deleteMemberStatus(true));
        return idCallApi(`api/v1/partneraccounts/${partneraccount_id}/venue/${venueId}/deactivate-account`).then(res => {
            if (res.status === 'Success') {
                return callVenueApi(`venuemember/${memberId}/remove`, 'post').then(res => {
                    if (res.status === 'Success') {
                        if (res.data && res.data.venueMember) {
                            dispatch(deletMemberFromList(res.data.venueMember._id));
                        }
                        userNotify("Member Deleted Successfully", "success")
                    } else {
                        dispatch(deleteMemberStatus(false));
                        userNotify("Failed!, try again", "error")
                    }
                });
            } else {
                dispatch(deleteMemberStatus(false));
                userNotify('Not able to deactivate partner account', 'error')
                console.error(res)
            }
        })
    };
}

export function loadEditPermissions(data) {
    return {
        type: LOAD_EDIT_PERMISSIONS,
        editPermissions: data.userPermission && !_.isEmpty(data.userPermission) ? data.userPermission.userPermissions : [],
        permissionModuleList: data.moduleList,
        editUser: data.user
    };
}

export function fetchPermissionsByUserId(userId) {
    return (dispatch) => {
        return callApi(`profile/user/${userId}/permissions`, 'get').then(res => {
            if (res.status === 'Success') {
                dispatch(loadEditPermissions(res.data));
            } else {

            }
        });
    };
}

export function loadPermissionUpdatingStatus(status) {
    return {
        type: LOAD_PERMISSION_UPDATING_STATUS,
        isUpdatingPermission: !!status
    };
}

export function updatePermissionsByUserId(userId, permisssions) {
    return (dispatch) => {
        dispatch(loadPermissionUpdatingStatus(true));
        return callApi(`profile/user/${userId}/update-permission`, 'post', {userPermission: permisssions}).then(res => {
            if (res.status === 'Success') {
                dispatch(loadPermissionUpdatingStatus(false));
                userNotify('Permissions Updated Successfully', 'success');
            } else {
                dispatch(loadPermissionUpdatingStatus(false));
                userNotify('Failed to update, try again', 'error');
            }
        });
    };
}

export function updateUserRole(partneraccount_id, venue_id, partneraccount) {
    return (dispatch) => {
        return idCallApi(`api/v1/partneraccounts/${partneraccount_id}/venue/${venue_id}/update-role`, 'post', {partneraccount: partneraccount}).then(res => {
            if (res.status === 'Success') {
                userNotify('User Role updated successfully', 'success');
                fetchMemberList(venue_id, {pageNum: 1, pageSize: 25})
                if (res.data) {

                }
            } else {

            }
        });
    };
}

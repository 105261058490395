import FooterStyle from './Footer.module.css';
import React, { useEffect, useState } from 'react';
import footerContent from '../Footer/newfooterlinkstructure.json';
import { Link } from 'react-router-dom';
import {CURRENT_STAGE} from "../../../../constants/enum";
import meta from '../../../../constants/meta.json'

const NewFooter = ({ children, footerLinks }) => {
    const [width, setWidth] = useState(0);
    const currentYear = new Date().getFullYear();

const copyRight = {
    copy_right: `Copyright© ${currentYear} VenueMonk Technologies Private Limited`
};

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
    };

    const getYear = () => {
        return new Date().getFullYear();
    };

    return (
        
        <div className={FooterStyle['footer']}>
            {/* <FooterLinks
                    links={footerLinks ? footerLinks : newfootLinks}
                /> */}
            <div className={FooterStyle['container']}
            >
                <div className={FooterStyle['section1']}>
                <div className={FooterStyle['information']}>
                <span className={FooterStyle['link-heading']}>{footerContent.information.title}</span>
                <ul className={FooterStyle['ul-style']}>
                        {footerContent.information.url.map((link, index) => (
                            <li className={FooterStyle['li-style']} key={index}>
                                <Link className={FooterStyle['list-a']} to={link.link}>{link.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={FooterStyle['company_details']}>
                    <span className={FooterStyle['link-heading']}>{footerContent.company_details.title}</span>
                    <ul className={FooterStyle['ul-style']}>
                        {footerContent.company_details.links.map((link, index) => (
                            <li className={FooterStyle['li-style']} key={index}>
                                <Link className={FooterStyle['list-a']} to={link.link}>{link.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                </div>
                <div>
                <div className={FooterStyle['partner_with_us']}>
                <span className={FooterStyle['link-heading']}>{footerContent.partner_with_us.title}</span>
                   <span className={FooterStyle['list-a']}> {footerContent.partner_with_us.content} </span>
                    <div className={FooterStyle.appLinks}>
                        <img
                            src={'https://vmnk.gumlet.io/websiteicon.svg'}
                            style={{ fontSize: '10px' }}
                            alt="WEBSITE"
                        />
                        <a href="https://play.google.com/store">
                            <img
                                src='https://vmnk.gumlet.io/google_play_image.svg'
                                style={{ fontSize: '19px' }}
                                alt="GOOGLE PLAY STORE"
                            />
                        </a>
                    </div>
                </div>
                </div>
                <div>
                <div className={FooterStyle.follow_us}>
                <span className={FooterStyle['link-heading']}>{footerContent.follow_us.title}</span> 
                    <div className={FooterStyle.socialLinks}>
                        {footerContent.follow_us.socialMedia.map((social, index) => (
                            <a
                                key={index}
                                href={social.url}
                                className={FooterStyle[social.className]}
                            >
                                <img src={social.icon} style={{ fontSize: '19px' }} />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            </div>
            <div>
                <hr style={{ paddingTop: '10px' }}></hr>
            </div>
            <div className={FooterStyle.copy_right}>
            {/* <div style={{color: '#222', fontSize: '14px', fontWeight: '600'}}>{`VenueMonk ${CURRENT_STAGE.slice(0, 1).toUpperCase()}  ${meta.version}`}</div>
             */}
            <div style={{color: '#222', fontSize: '14px', fontWeight: '600'}}>{`VenueMonk ${meta.version}`}</div>

                <p>{copyRight.copy_right}</p>
            </div>
        </div>
    );
};

export default NewFooter;

import React, { StrictMode } from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { routes } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./buildcss/vm-partner-desk.2.0.7.min.css";
import "./buildcss/vm-partner-mob.2.0.7.min.css";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import { ENV_PRODUCTION } from "./constants/enum";

// Initialize store
const initialStore = store(window.__INITIAL_STATE__);
const mountApp = document.getElementById("root");
delete window.__INITIAL_STATE__;

// Initialize Sentry
Sentry.init({
  dsn: "https://2dfa30975cc04bf4a90c58d74c9166de@o91517.ingest.sentry.io/4505974286778368",
  integrations: [new BrowserTracing()],
  environment: ENV_PRODUCTION,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const renderApp = (renderMethod) => {
  renderMethod(
    <StrictMode>
      <Provider store={initialStore}>
        <BrowserRouter>
          <Helmet>
            <title>Venuemonk Partner</title>
            <meta name="description" content="App Description" />
            <meta name="theme-color" content="#008f68" />
          </Helmet>
          <Routes>{routes}</Routes>
        </BrowserRouter>
      </Provider>
    </StrictMode>,
    mountApp
  );
};
if (mountApp.hasChildNodes()) {
  renderApp(hydrate);
} else {
  renderApp(render);
}

import {
    LOAD_MENU_LIST,
    FETCH_MENU_STATUS,
    FETCH_PACKAGE_STATUS,
    LOAD_PACKAGE_LIST,
    FETCH_VENUE_INFO_STATUS,
    LOAD_VENUE_INFO,
    FETCH_CLONE_VENUE_INFO_STATUS,
    LOAD_CLONE_VENUE_INFO,
    UPDATE_CLONE_VENUE_INFO_STATUS,
    UPDATE_CLONE_VENUE_INFO,
    FETCH_CLONE_PACKAGE_STATUS,
    LOAD_CLONE_PACKAGE_LIST,
    FETCH_CLONE_MENU_STATUS,
    LOAD_CLONE_MENU_LIST,
    UPDATE_CLONE_MENU_STATUS,
    UPDATE_CLONE_MENU_TO_LIST,
    UPDATE_CLONE_PACKAGE_STATUS,
    UPDATE_CLONE_PACKAGE_TO_LIST,
    DELETE_CLONE_PACKAGE_STATUS,
    DELETE_CLONE_PACKAGE,
    DELETE_CLONE_MENU_STATUS,
    DELETE_CLONE_MENU_FROM_LIST,
    ADD_CLONE_MENU_STATUS,
    ADD_CLONE_MENU_TO_LIST,
    ADD_CLONE_PACKAGE_STATUS,
    ADD_CLONE_PACKAGE_TO_LIST,
    UPDATE_CLONE_PACKAGE_ACTIVE_STATUS,
    UPDATE_CLONE_MENU_ACTIVE_STATUS
  } from './VenueActions';
  
  // state : { venue : { } }
  const initialState = {
    isFetchingMenu: false,
    menuList: [],
    menuCount: 0,
    isFetchingPackage: false,
    packageList: [],
    packageCount: 0,
    isFetchingVenueInfo: false,
    venueInfo: {},
    isFetchingCloneVenueInfo: false,
    isUpdatingCloneVenueInfo: false,
    cloneVenueInfo: {},
    clonePackageList: [],
    clonePackageCount: 0,
    clonePackagePageNum: 1,
    clonePackagePageSize: 15,
    isFetchingClonePackageList: false,
    isFetchingCloneMenuList: false,
    cloneMenuList:[],
    cloneMenuCount: 0,
    isUpdatingCloneMenu: false,
    isUpdatingClonePackage: false,
    isDeletingClonePackage: false,
    isDeletingCloneMenu: false,
    isAddingCloneMenu: false,
    isAddingClonePackage: false,
  };
  
  const VenueReducer = (state = initialState, action) => {
    let cloneMenuList = state.cloneMenuList;
    let clonePackageList = state.clonePackageList;
  
    switch (action.type) {
  
      case UPDATE_CLONE_MENU_ACTIVE_STATUS:
        cloneMenuList = cloneMenuList.map(menu => {
          if(menu._id === action.menuId) {
            menu.isUpdatingActiveStatus = action.isUpdatingActiveStatus;
          }
          return menu;
        });
        return {
          ...state,
          cloneMenuList: cloneMenuList,
        };
  
      case UPDATE_CLONE_PACKAGE_ACTIVE_STATUS:
        clonePackageList = clonePackageList.map(pack => {
          if(pack._id === action.packId) {
            pack.isUpdatingActiveStatus = action.isUpdatingActiveStatus;
          }
          return pack;
        });
        return {
          ...state,
          clonePackageList: clonePackageList,
        };
  
      case FETCH_MENU_STATUS:
        return {
          ...state,
          isFetchingMenu: action.isFetchingMenu,
        };
  
      case LOAD_MENU_LIST:
        return {
          ...state,
          menuList: action.menuList,
          menuCount: action.menuCount,
          isFetchingMenu: action.isFetchingMenu
        };
  
      case FETCH_PACKAGE_STATUS:
        return {
          ...state,
          isFetchingPackage: action.isFetchingPackage
        };
  
      case LOAD_PACKAGE_LIST:
        return {
          ...state,
          packageList: action.packageList,
          packageCount: action.packageCount,
          isFetchingPackage: action.isFetchingPackage
        };
  
      case FETCH_VENUE_INFO_STATUS:
        return {
          ...state,
          isFetchingVenueInfo: action.isFetchingVenueInfo
        };
  
      case LOAD_VENUE_INFO:
        return {
          ...state,
          venueInfo: action.venueInfo,
          isFetchingVenueInfo: action.isFetchingVenueInfo
        };
  
      case FETCH_CLONE_VENUE_INFO_STATUS:
        return {
          ...state,
          isFetchingCloneVenueInfo: action.isFetchingCloneVenueInfo
        };
  
      case LOAD_CLONE_VENUE_INFO:
        return {
          ...state,
          cloneVenueInfo: action.cloneVenueInfo,
          isFetchingCloneVenueInfo: action.isFetchingCloneVenueInfo
        };
  
      case UPDATE_CLONE_VENUE_INFO_STATUS:
        return {
          ...state,
          isUpdatingCloneVenueInfo: action.isUpdatingCloneVenueInfo
        };
  
      case UPDATE_CLONE_VENUE_INFO:
        return {
          ...state,
          cloneVenueInfo: action.cloneVenueInfo,
          isUpdatingCloneVenueInfo: action.isUpdatingCloneVenueInfo
        };
  
      case FETCH_CLONE_PACKAGE_STATUS:
        return {
          ...state,
          isFetchingClonePackageList: action.isFetchingClonePackageList
        };
  
      case LOAD_CLONE_PACKAGE_LIST:
        return {
          ...state,
          clonePackageList: action.clonePackageList,
          clonePackageCount: action.clonePackageCount,
          isFetchingClonePackageList: action.isFetchingClonePackageList
        };
  
      case FETCH_CLONE_MENU_STATUS:
        return {
          ...state,
          isFetchingCloneMenuList: action.isFetchingCloneMenuList
        };
  
      case LOAD_CLONE_MENU_LIST:
        return {
          ...state,
          cloneMenuList: action.cloneMenuList,
          cloneMenuCount: action.cloneMenuCount,
          isFetchingCloneMenuList: action.isFetchingCloneMenuList
        };
  
      case UPDATE_CLONE_MENU_STATUS:
        return {
          ...state,
          isUpdatingCloneMenu: action.isUpdatingCloneMenu
        };
  
      case UPDATE_CLONE_MENU_TO_LIST:
        cloneMenuList = cloneMenuList.map(menu => {
          if(menu._id === action.menu._id){
            menu = action.menu;
          }
          return menu;
        });
        return {
          ...state,
          cloneMenuList: cloneMenuList,
          isUpdatingCloneMenu: action.isUpdatingCloneMenu
        };
  
      case UPDATE_CLONE_PACKAGE_STATUS:
        return {
          ...state,
          isUpdatingClonePackage: action.isUpdatingClonePackage
        };
  
      case UPDATE_CLONE_PACKAGE_TO_LIST:
        clonePackageList = clonePackageList.map(item => {
          if(item._id === action.package._id){
            item = action.package;
            item.isUpdatingActiveStatus = false;
          }
          return item;
        });
        return {
          ...state,
          clonePackageList: clonePackageList,
          isUpdatingClonePackage: action.isUpdatingClonePackage
        };
  
      case DELETE_CLONE_PACKAGE_STATUS:
        return {
          ...state,
          isDeletingClonePackage: action.isDeletingClonePackage
        };
  
      case DELETE_CLONE_PACKAGE:
        clonePackageList = clonePackageList.filter(item => item._id !== action.package._id);
        return {
          ...state,
          clonePackageList: clonePackageList,
          isDeletingClonePackage: action.isDeletingClonePackage
        };
  
      case DELETE_CLONE_MENU_STATUS:
        return {
          ...state,
          isDeletingCloneMenu: action.isDeletingCloneMenu
        };
  
      case DELETE_CLONE_MENU_FROM_LIST:
        cloneMenuList = cloneMenuList.filter(menu => menu._id !== action.menu._id);
        return {
          ...state,
          cloneMenuList: cloneMenuList,
          isDeletingCloneMenu: action.isDeletingCloneMenu
        };
  
      case ADD_CLONE_MENU_STATUS:
        return {
          ...state,
          isAddingCloneMenu: action.isAddingCloneMenu
        };
  
      case ADD_CLONE_MENU_TO_LIST:
        cloneMenuList.unshift(action.menu);
        return {
          ...state,
          cloneMenuList: cloneMenuList,
          cloneMenuCount: state.cloneMenuCount + 1,
          isAddingCloneMenu: action.isAddingCloneMenu
        };
  
      case ADD_CLONE_PACKAGE_STATUS:
        return {
          ...state,
          isAddingClonePackage: action.isAddingClonePackage
        };
  
      case ADD_CLONE_PACKAGE_TO_LIST:
        clonePackageList.unshift(action.package);
        return {
          ...state,
          clonePackageList: clonePackageList,
          clonePackageCount: state.clonePackageCount + 1,
          isAddingClonePackage: action.isAddingClonePackage
        };
  
      default:
        return {
          ...state
        };
    }
  };
  
  //menu
  export const getMenuList = state => state.venue.menuList;
  export const fetchMenuStatus = state => state.venue.isFetchingMenu;
  export const getMenuCount = state => state.venue.menuCount;
  
  // clonemenu
  export const getCloneMenuListStatus = state => state.venue.isFetchingCloneMenuList;
  export const getCloneMenuList = state => state.venue.cloneMenuList;
  export const getCloneMenuCount = state => state.venue.cloneMenuCount;
  export const getCloneMenuPageNum = state => state.venue.cloneMenuPageNum;
  export const getCloneMenuPageSize = state => state.venue.cloneMenuPageSize;
  export const getUpdatingCloneMenuStatus = state => state.venue.isUpdatingCloneMenu;
  export const getDeletingCloneMenuStatus = state => state.venue.isDeletingCloneMenu;
  export const getAddingCloneMenuStatus = state => state.venue.isAddingCloneMenu;
  
  //packages
  export const getFetchingPackageState = state => state.venue.isFetchingPackage;
  export const getPackageList = state => state.venue.packageList;
  export const getPackageCount = state => state.venue.packageCount;
  export const getPackagePageNum = state => state.venue.subPackagePageNum;
  export const getPackagePageSize = state => state.venue.subPackagePageSize;
  export const getClonePackageList = state => state.venue.clonePackageList;
  export const getClonePackageCount = state => state.venue.clonePackageCount;
  export const getClonePackagePageNum = state => state.venue.clonePackagePageNum;
  export const getClonePackagePageSize = state => state.venue.clonePackagePageSize;
  export const getFetchingClonePackageStatus = state => state.venue.isFetchingClonePackageList;
  export const getUpdatingClonePackageStatus = state => state.venue.isUpdatingClonePackage;
  export const getDeletingClonePackageStatus = state => state.venue.isDeletingClonePackage;
  export const getAddingClonePackageStatus = state => state.venue.isAddingClonePackage;
  
  //venues
  export const getFetchingVenueInfoState = state => state.venue.isFetchingVenueInfo;
  export const getVenueInfo = state => state.venue.venueInfo;
  export const getFetchingCloneVenueInfoState = state => state.venue.isFetchingCloneVenueInfo;
  export const getUpdatingCloneVenueInfoState = state => state.venue.isUpdatingCloneVenueInfo;
  export const getCloneVenueInfo = state => state.venue.cloneVenueInfo;
  
  export const getVenueAgentId = state => state.venue.cloneVenueInfo ? state.venue.cloneVenueInfo.venueAgentId : '';
  
  
  export default VenueReducer;
  
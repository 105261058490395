import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Banner from './components/Banner';
import Sponsers from './components/Sponsers';
import GetStart from "./components/GetStart";
import MobileBanner from './components/MobileBanner';
import { withRoute } from 'util/withRoute';

// Import Actions
import {
  fetchHomePageData,
} from '../../AppActions';

import {
  getAuthenticatedState, getHomePage,
} from '../../AppReducer';
import FAQ from './components/FAQ';
import { Navigate } from 'react-router';

// const sponsers = ['1mg-vm-corporate-logo.png','car-dehko-vm-corporate-logo.png','make-my-trip-vm-corporate-logo.png','shuttle-vm-corporate-logo.png','snapdeal-vm-corporate-logo.png','zs-vm-corporate-logo.png']
const sponsers = ['1mg-corporate-logo.png', 'car-dehko-corporate-logo.png', 'make-my-trip-corporate-logo.png', 'shuttl-corporate-logo.png', 'snapdeal-corporate-logo.png', 'zs-associates-corporate-logo.png']

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchHomePageData());
    window.addEventListener('resize', this.handleResize)
    if (this.props.isAuthenticated) {
      this.props.navigate('/dashboard')
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated) {
      this.props.navigate('/dashboard')
    }
  }




  render() {
    const breakpoint = 800;

    return (
      <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
        <div style={{ backgroundColor: '#fff', width: '100%' }}>
          {(this.state.windowWidth > breakpoint) ? <Banner
            isAuthenticated={this.props.isAuthenticated}
          /> : <MobileBanner
            isAuthenticated={this.props.isAuthenticated}
          />}
          <div className="container">
            <div className="row">
              <Sponsers
                sponsers={sponsers}
              />
              {/*<Feedback*/}
              {/*  list={testimonials}*/}
              {/*/>*/}
            </div>
          </div>
          {/*<div style={{ backgroundColor: '#FAFAFA', display: 'flex', padding: '10px' }}>*/}
          {/*  <div className="container">*/}
          {/*    <div className="row">*/}
          {/*      <FAQ />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div style={{ backgroundColor: '#2A52BB', display: 'flex', padding: '10px', color: '#fff' }}>
            <div className="container">
              <div className="row">
                <GetStart />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    homepage: getHomePage(state),
    isAuthenticated: getAuthenticatedState(state),
  };
}

HomePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object,
};



export default connect(mapStateToProps)(withRoute(HomePage));

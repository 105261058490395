import React, { Component, Proptypes } from 'react';
import {withRoute} from "../../util/withRoute";
import { Link } from 'react-router-dom';

const activeStyle = {
  color: '#0057FF'
};
const style = {
  width: '100%',
  padding: '12px',
  position: 'relative',
  display: 'block',
  height: '50px',
  backgroundColor: '#2a52bb',
  color: '#fff',
  overflow: 'hidden',
};
export class NormalLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false
    }
  };

  getActiveStatus = (url, activeUrl, isArray) => {
    if(isArray) {
      let isActive = false;
      url.forEach(item => {
          if(item === activeUrl) {
            isActive = true;
          }
      });
      return isActive;
    } else {
      return url === activeUrl;
    }
  };

  handleEnter = () => {
    this.setState(prev => {
      return {
        isHover: true,
      }
    })
  }
  handleExit = () => {
    this.setState(prev => {
      return {
        isHover: false,
      }
    })
  }

  getSideBarActiveStatus = (link, activeLink) => {
    let isActive = false;
    if(link === activeLink) {
      isActive = true;
    }

    return isActive
  }


  render() {
    let isActive = this.getSideBarActiveStatus(this.props.url, this.props.pathName);
    let linkClass = isActive ? 'active' : '';
      return (
          <li onMouseEnter={this.handleEnter} onMouseLeave={this.handleExit} style={{marginTop: '20px'}} className={linkClass}>
            <Link style={{display: 'flex', gap: '18px', padding: `${this.props.isShowSideBar ? '12px': '12px 6px'}`, cursor: 'pointer'}} to={this.props.isUrlArray ? this.props.url[0] : this.props.url}>
              <div style={{width: '40px'}}>
                <img width={this.props.width} height={this.props.height} src={this.state.isHover || isActive ? this.props.icon_secondary : this.props.icon_primary} alt={this.props.name}/>
              </div>
              {/*<i style={{transform: 'scale(1.5)', marginRight: '30px', marginBottom: '5px'}} className={this.props.icon} />*/}
              {
                this.props.isShowSideBar ?
                  <span style={{fontSize: '18px'}}>{this.props.name}</span> : null
              }
            </Link>
          </li>
      );
  }
}

export default withRoute(NormalLink);


import moment from 'moment';
import _ from 'lodash';
import reactCookie from 'react-cookies';



export function loadVmLeadTrackContent() {
  return reactCookie.load('vmleadtrack');
}

export function setVmLeadTrackContent(input) {
  const date = moment().add(1, 'hours');
  const stringInput = JSON.stringify(input);
  reactCookie.save('vmleadtrack', stringInput, {
    path: '/',
    maxAge: date.diff(moment(), 'seconds'),
    expires: date.toDate(),
  });
}

import React from 'react';
import ScreenLoader from '../Loader/ScreenLoader';

export default function PageAccessControl(props) {
    let isShowLoader = true;
    const hasPermission = () => {
        if (props.shouldHavePermissions && props.shouldHavePermissions.length > 0) {
            if (props.userPermissions) {
                let userPermissions = props.userPermissions;
                let shouldHavePermissions = props.shouldHavePermissions;
                let leftPermissions = shouldHavePermissions.filter(item => userPermissions.indexOf(item) !== -1);
                return leftPermissions && leftPermissions.length > 0;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    if(hasPermission()) {
      return props.children;
    } else {
      return(
        <div className={'text-center'} style={{marginTop: '40vh'}}>
          <p style={{fontSize: '32px'}}>You Don't Have Permission To Access This Page</p>
        </div>
      );
    }
}


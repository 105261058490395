import callApi from '../../util/apiCaller';
import callEventApi from "../../util/eventApiCaller";
export const LOAD_BOOKING = 'LOAD_BOOKING';
export const FETCH_BOOKING_STATUS = 'FETCH_BOOKING_STATUS';
export const FETCH_BOOKING_LIST_STATUS = 'FETCH_BOOKING_LIST_STATUS';
export const LOAD_BOOKING_LIST = 'LOAD_BOOKING_LIST';

export function fetchBookingsListStatus(status) {
  return {
    type: FETCH_BOOKING_LIST_STATUS,
    isFetchingBookings: !!status,
  };
}

export function loadBookingList(data, pageNum) {
  return {
    type: LOAD_BOOKING_LIST,
    bookingList: data.leads,
    isFetchingBookings: false,
    bookingPageNum: pageNum,
    bookingCount: data.totalCount,
  };
}

export function fetchBookingList(venueid, filters) {
  return (dispatch) => {
    dispatch(fetchBookingsListStatus(true));
    callEventApi(`api/v3/partner-dashboard/bookings/venue/${venueid}/bookings`, 'POST', {filters: filters}).then(
      (res) => {
        if(res.status === 'Success') {
          dispatch(loadBookingList(res.data, filters.pageNum));
        } else {
          dispatch(fetchBookingsListStatus(false));
        }
      }).catch((err) => {

    });
  };
}

export function fetchBookingStatus(status) {
  return {
    type: FETCH_BOOKING_STATUS,
    isFetchingBooking: !!status,
  };
}

export function loadBooking(data) {
  return {
    type: LOAD_BOOKING,
    bookingList: data.lead,
    isFetchingBooking: false,
  };
}

export function fetchBooking(leadid) {
  return (dispatch) => {
    dispatch(fetchBookingStatus(true));
    callEventApi(`api/v3/partner-dashboard/bookings/lead/${leadid}/info`, 'GET').then(
      (res) => {
        if(res.status === 'Success') {
          dispatch(loadBooking(res.data));
        } else {
          dispatch(fetchBookingStatus(false));
        }
      }).catch((err) => {

    });
  }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {loadActiveVenue, logoutUser, toggleShowHeader, toggleShowScreenLoader} from './App/AppActions';
import {
  getAccessListFetchingStatus,
  getActiveVenue,
  getActiveVenueId,
  getAuthenticatedState,
  getCheckingTokenStatus,
  getScreenLoaderStatus,
  getSessionUserId,
  getUser,
  getUserPermissions,
  getVenueDetail,
  getVenueList
} from './App/AppReducer';
import Header from '../components/Header/Header';
import {Outlet} from 'react-router';
import ScreenLoader from '../components/Loader/ScreenLoader';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MenuRounded} from '@mui/icons-material';
import Connector from '../Connector';
import {withRoute} from 'util/withRoute';
import Sidebar from '../components/SideBar/SideBar';


class AuthWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowSideBar:  window.innerWidth > 800 ? true : false,
      sidebarWidth: '255px',
      windowWidth: window.innerWidth
    };
  }

  componentDidMount() {
    this.props.dispatch(toggleShowHeader(false));
    if (this.props.isCheckingForToken || !this.props.isAuthenticated) {
      this.props.dispatch(toggleShowScreenLoader(true));
    }
    // if (!_.isEmpty(this.props.user) && !_.isEmpty(this.props.user._id)) {
    //   this.props.dispatch(fetchUserPermissions(this.props.user._id));
    // }
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    this.props.dispatch(toggleShowHeader(true));
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isCheckingForToken && !this.props.isAuthenticated && this.props.location && this.props.location.pathname) {
      return this.props.navigate(`/login?ref=${this.props.location.pathname}`);
    }
    if (prevProps.isCheckingForToken && !this.props.isCheckingForToken) {
      this.props.dispatch(toggleShowScreenLoader(false));
    }
    // if (!prevProps.isAuthenticated && this.props.isAuthenticated && !_.isEmpty(this.props.user)) {
    //   this.props.dispatch(getUserPermissions(this.props.user._id));
    // }
  }

  handleResize = () => {
    this.setState({windowWidth: window.innerWidth})
  }

  toggleSideBar = () => {
    this.setState({ isShowSideBar: !!!this.state.isShowSideBar, sidebarWidth: `${!this.state.isShowSideBar ? '230px' : '55px'}` });
  };

  handleChangeActiveVenue = (venue) => {
    this.props.dispatch(toggleShowScreenLoader(true));
    this.props.dispatch(loadActiveVenue(venue));
    this.props.navigate('/dashboard');
  };

  handleLogOut = () => {
    this.props.dispatch(logoutUser());
    this.props.navigate('/')
  };

  showMessage = (msg, msgtype) => {
    switch (msgtype) {
      case 'warning':
        toast.warning(msg, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
        break;

      case 'error':
        toast.error(msg, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
        break;

      case 'info':
        toast.info(msg, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
        break;

      case 'success':
        toast.success(msg, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
        break;

      default:
        toast(msg, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
    }
  };

  render() {
    let breakpoint = 800
    let mobileStyles = {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
    }

    if (this.props.isAuthenticated) {

      return (
        <div style={{ position: 'relative' }}>
                    <div style={{ width: `${this.props.isAuthenticated && (this.state.windowWidth > breakpoint) ? `calc(100% - ${this.state.sidebarWidth})` : '100%'}`, position: 'absolute', top: '60px', marginLeft: `${this.props.isAuthenticated && (this.state.windowWidth > breakpoint) ? this.state.sidebarWidth : 0}` }}>
            <div style={{ height: '60px', position: 'fixed', zIndex: '1000', top: 0, backgroundColor: '#F9FAFC', width: `${this.props.isAuthenticated && (this.state.windowWidth > breakpoint) ? `calc(100% - ${this.state.sidebarWidth})` : '100%'}`, boxShadow: '0px 4px 20px 4px rgb(0, 0, 0, 0.1)' }}>
              <Header
                toggleSideBar={this.toggleSideBar}
                breakpoint={breakpoint}
                windowWidth={this.state.windowWidth}
                isShowSidebar={this.state.isShowSideBar}
                venueList={this.props.venueList}
                venueDetails={this.props.venueDetails}
                activeVenue={this.props.activeVenue}
                handleChangeActiveVenue={this.handleChangeActiveVenue}
                isAuthenticated={this.props.isAuthenticated}
                profileHeader={true}
              />
            </div>
            <div className="container-fluid right-part-y-scroll" style={{ height: 'calc(100vh - 60px)', overflowY: 'scroll', width: '100%', padding: '0', overflowX: 'hidden' }}>
              <Outlet context={this.props} />
              {/*<PageAccessControl*/}
              {/*  userPermissions={this.props.userPermissions}*/}
              {/*  shouldHavePermissions={[]}*/}
              {/*>*/}
              {/*  */}
              {/*</PageAccessControl>*/}

              {/*{*/}
              {/*  this.props.isShowScreenLoader ?*/}
              {/*    <ScreenLoader style={{ position: 'absolute' }} /> : null*/}

              {/*}*/}
            </div>
          </div>

          {(this.state.windowWidth > breakpoint) ?
          <div style={{ width: (this.state.windowWidth > breakpoint) ? `${this.state.sidebarWidth}` : '100vw',position: 'absolute', minHeight: '100vh', backgroundColor: '#2a52bb' }}>
            <div style={{ width: `100%`, position: 'relative', zIndex: 3300, top: 0, minHeight: '100vh' }}>
              <Connector user={this.props.user} showMessage={this.showMessage} userPermissions={this.props.userPermissions} />
              <Sidebar
                windowWidth={this.state.windowWidth}
                toggleSideBar={this.toggleSideBar}
                isShowSideBar={this.state.isShowSideBar}
                venueList={this.props.venueList}
                activeVenueId={this.props.activeVenueId}
                activeVenue={this.props.activeVenue}
                activeUrl={this.props.currentURL}
                user={this.props.user}
                handleLogOut={this.handleLogOut}
                userPermissions={this.props.userPermissions}
              />
            </div>
          </div> :
          <div onClick={this.toggleSideBar} style={{padding: '10px 0 0 10px', position: 'absolute', zIndex: '1000', color: '#0057ff', cursor: 'pointer'}}>
            <MenuRounded style={{cursor: 'pointer'}} onClick={this.props.toggleSideBar}  fontSize='large'/>
            <div style={{ width: `80vw`, position: 'fixed', zIndex: 3300, top: 0, minHeight: '100vh', left: this.state.isShowSideBar ? '0' : '-110vw', transition: 'left 0.2s ease-in-out' }}>
            <Sidebar
              mobileStyles={mobileStyles}
              windowWidth={this.state.windowWidth}
              toggleSideBar={this.toggleSideBar}
              isShowSideBar={this.state.isShowSideBar}
              venueList={this.props.venueList}
              activeVenueId={this.props.activeVenueId}
              activeVenue={this.props.activeVenue}
              activeUrl={this.props.currentURL}
              user={this.props.user}
              handleLogOut={this.handleLogOut}
              userPermissions={this.props.userPermissions}
            />
            </div>
          </div>
          }

        </div>
      );
    } else {
      return <ScreenLoader style={{ position: 'absolute' }} />;
    }
  }
}

AuthWrapper.propTypes = {
  children: PropTypes.element,
};

function mapStateToProps(state, ownProps) {
  return {
    userId: getSessionUserId(state),
    isAuthenticated: getAuthenticatedState(state),
    venueList: getVenueList(state),
    venueDetails: getVenueDetail(state),
    activeVenue: getActiveVenue(state),
    activeVenueId: getActiveVenueId(state),
    currentURL: ownProps?.location?.pathname,
    user: getUser(state),
    isShowScreenLoader: getScreenLoaderStatus(state),
    userPermissions: getUserPermissions(state),
    isFetchingAccessList: getAccessListFetchingStatus(state),
    isCheckingForToken: getCheckingTokenStatus(state),
    userPermission: getUserPermissions(state),
    ...ownProps,
  };
}

export default connect(mapStateToProps)(withRoute(AuthWrapper));

export const AVAILABLE_COLOR =  '#9AC37E'
export const BOOKED_COLOR ='#0057ff'
export const UNAVAILABLE_COLOR='#C8C8C8'

export const AVAILABLE='available'
export const UNAVAILABLE='unavailable'
export const BOOKED='booked'
export const SLOT_OPTIONS =[
    {
        "label":"Lunch",
        "value":"lunch"
    },
    {
        "label":"Dinner",
        "value":"dinner"
    }
]

export const STATUS_OPTIONS=[
    {
        "label" : "Booked",
        "value" :  BOOKED,
        "index":2
    },
    {
        "label":  "Available",
        "value":  AVAILABLE,
        "index":1
    },
    {
        "label": "Unavailable",
        "value": UNAVAILABLE,
        "index":3   
    },
]



export const VENUE_SPACE_COLOR_OPTIONS = [
    "#FF5733",
    "#42A5F5",
    "#FFD700",
    "#4CAF50",
    "#FF4081",
    "#9C27B0",
    "#00BCD4",
    "#FF9800",
    "#795548",
    "#607D8B"
  ];
  
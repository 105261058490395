import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function SpinnerLoader(props) {
  return (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  );
}

import reactCookie from 'react-cookies';
import _ from 'lodash';
import callApi, { callApiCallingStatus } from '../../util/apiCaller';
import callEventApi, { callCoreApi, callEventApiWithAPIKey } from "../../util/eventApiCaller";
import idCallApi from '../../util/idApiCaller';
import { userNotify } from '../../components/Toasts/ToastAdd';
import { PARTNER } from '../../constants/enum';
import callVenueApi from "../../util/venueApiCaller";
import { fetchMemberList } from "../Member/MemberActions";
import callSalesApi from "../../util/salesApiCaller";
import async from "async";
// Export Constants
export const TOGGLE_ADD_POST = 'TOGGLE_ADD_POST';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REQUEST_OTP_LOGIN = 'REQUEST_OTP_LOGIN';
export const REQUEST_OTP_FAILURE = 'REQUEST_OTP_FAILURE';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const LOGIN_OTP_SUCCESS = 'LOGIN_OTP_SUCCESS';
export const LOGIN_OTP_FAILURE = 'LOGIN_OTP_FAILURE';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REQUEST_CHECK_TOKEN = 'REQUEST_CHECK_TOKEN';
export const TOKEN_VALID = 'TOKEN_VALID';
export const TOKEN_INVALID = 'TOKEN_INVALID';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const ACCESSLIST_ADDED = 'ACCESSLIST_ADDED';
export const MEHNDILIST_ADDED = 'MEHNDILIST_ADDED';
export const EDIT_LOGIN_NUMBER = 'EDIT_LOGIN_NUMBER';
export const LOAD_ACTIVE_VENUE = 'LOAD_ACTIVE_VENUE';
export const LOAD_ACTIVE_MEHNDI = 'LOAD_ACTIVE_MEHNDI';
export const LOAD_HOME_PAGE = 'LOAD_HOME_PAGE';
export const VERIFYING_OTP = 'VERIFYING_OTP';
export const TOGGLE_PASSWORD_OTP_LOGIN = 'TOGGLE_PASSWORD_OTP_LOGIN';
export const TOGGLE_SHOW_HEADER = 'TOGGLE_SHOW_HEADER';
export const LOAD_BASE_FILTERS = 'LOAD_BASE_FILTERS';
export const TOGGLE_SHOW_SCREEN_LOADER = 'TOGGLE_SHOW_SCREEN_LOADER';
export const LOAD_CITY_LIST = 'LOAD_CITY_LIST';
export const UPDATE_LEAD_MANAGE_PATH = 'UPDATE_LEAD_MANAGE_PATH';
export const ACCESS_LIST_FETCHING_STATUS = 'ACCESS_LIST_FETCHING_STATUS';
export const LOAD_USER_PERMISSIONS = 'LOAD_USER_PERMISSIONS';
export const LOAD_DASHBOARD_DATA = 'LOAD_DASHBOARD_DATA';
export const LOAD_CLOSED_LOST_REASON_LIST = 'LOAD_CLOSED_LOST_REASON_LIST';
export const LOAD_LEAD_STATUS_LIST = 'LOAD_LEAD_STATUS_LIST';
export const LOAD_CALL_STATUS_LIST = 'LOAD_CALL_STATUS_LIST';
export const LOAD_PARTNER_APPROVALS = 'LOAD_PARTNER_APPROVALS';
export const PARTNER_APPROVAL_FETCHING_STATUS = 'PARTNER_APPROVAL_FETCHING_STATUS';
export const LOAD_UPDATE_APPROVAL_STATUS = 'LOAD_UPDATE_APPROVAL_STATUS';
export const LOAD_DASHBOARD_LEADS_DATA = 'LOAD_DASHBOARD_LEADS_DATA'
export const LOAD_AGENT_LIST = 'LOAD_AGENT_LIST'
export const SET_PARTNER_ACCOUNT_DATA = 'SET_PARTNER_ACCOUNT_DATA'
export const ADD_VENUE_ROLES = 'ADD_VENUE_ROLES'
export const ACCESS_LIST_APPEND_TO_PARTNERACCOUNT = 'ACCESS_LIST_APPEND_TO_PARTNERACCOUNT'
export const LOAD_VENUE_SPACE_DATA = 'LOAD_VENUE_SPACE_DATA'
export const FETCH_TASK_CONTENT = 'FETCH_TASK_CONTENT'
export const LOAD_VENUE_KEY = 'LOAD_VENUE_KEY'
export const FETCH_OPERATIONAL_CITIES = 'FETCH_OPERATIONAL_CITIES'
export const PIVOT_STATUS_LEADS = 'PIVOT_STATUS_LEADS'
export const AGENT_WISE_PIVOT_LEADS = 'AGENT_WISE_PIVOT_LEADS'
export const IS_CARD_SELECTED = 'IS_CARD_SELECTED'
// Export Actions
export function updateLeadListToLeadManagePath(isLeadManageFromLeadList) {
  return {
    type: UPDATE_LEAD_MANAGE_PATH,
    isLeadManageFromLeadList: isLeadManageFromLeadList,
  };
}

export function showMessage(msg, msgtype) {

  return userNotify(msg, msgtype)

}

export function loadHomePageData(data) {
  return {
    type: LOAD_HOME_PAGE,
    homepage: data,
  };
}


export function selectedCardType(type) {
  return {
    type: IS_CARD_SELECTED,
    selectedtype: type,
  };
}
export function toggleShowScreenLoader(status) {
  return {
    type: TOGGLE_SHOW_SCREEN_LOADER,
    isShowScreenLoader: !!status,
  };
}

export function toggleShowHeader(status) {
  return {
    type: TOGGLE_SHOW_HEADER,
    isShowHeader: !!status,
  };
}

export function loadBaseFilters(data) {
  return {
    type: LOAD_BASE_FILTERS,
    leadBaseFilters: data?.filters?.baseFilters || [],
    sortFilters: data?.filters?.sortFilters || [],
  };
}

const loadAgentList = (agentList) => {
  return {
    type: LOAD_AGENT_LIST,
    agentList: agentList,
  }
}

export function fetchAgentList() {
  return (dispatch) => {
    return idCallApi('api/v3/agents/agent-list', 'post', {}).then(res => {
      if (res.status === 'Success') {
        dispatch(loadAgentList(res.data.agents))
      }
    })
  }
}

export function fetchBaseFilters() {
  return (dispatch) => {
    return callEventApi('api/v3/partner-dashboard/leads/base-filters', 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadBaseFilters(res.data));
      }
    });
  };
}

export function fetchHomePageData() {
  return (dispatch) => {
    return callApi('partner/home', 'get')
      .then(res => {
        if (res.status === 'Success') {
          dispatch(loadHomePageData(res.data));
        }
      });
  };
}

export function toggleAddPost() {
  return {
    type: TOGGLE_ADD_POST
  };
}

export function requestLogin(creds) {
  return {
    type: REQUEST_LOGIN,
    isFetching: true,
    isAuthenticated: false,
    isVerifyingPassword: true,
    creds
  };
}

export function togglePasswordOtpLogin() {
  return {
    type: TOGGLE_PASSWORD_OTP_LOGIN,
    isFetching: false,
    isAuthenticated: false,
    isRequestingOtp: false,
    isVerifyingOTP: false,
    isOTPSent: false,
    isVerifyingPassword: false,
  };
}

export function loginSuccess(user, url) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: user.token,
    data: user.partneraccount,
    redirectUrl: url,
    isVerifyingPassword: false
  };
}

export function loginFailure(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    isVerifyingPassword: false,
    message,
  };
}

function requestLogout() {
  return {
    type: REQUEST_LOGOUT,
    isFetching: false,
    isAuthenticated: false
  }
}

function logoutSuccess() {
  window.location = '/';
}

// Logs the user out
export function logoutUser() {
  return dispatch => {
    dispatch(requestLogout());
    localStorage.clear();
    reactCookie.remove('token');
    showMessage('Logged Out!', 'success');
  }
}

export function requestCheckToken() {
  return {
    type: REQUEST_CHECK_TOKEN,
    isFetching: true,
    isAuthenticated: false,
    isCheckingForToken: true,
  };
}

export function tokenValid(user = {}) {
  return {
    type: TOKEN_VALID,
    isFetching: false,
    isAuthenticated: true,
    data: user,
    isCheckingForToken: false,
  };
}

export function tokenInvalid() {
  return {
    type: TOKEN_INVALID,
    isFetching: false,
    isAuthenticated: false,
    isCheckingForToken: false,
  };
}

export function loadAccessList(result, venueData) {
  return {
    type: ACCESSLIST_ADDED,
    venueList: result,
    venueDetail: venueData,
    isFetchingAccessList: false
  };
}

export function loadMehndiList(result) {
  return {
    type: MEHNDILIST_ADDED,//sam
    mehndiList: result,
    isFetchingAccessList: false
  };
}

export function loadActiveVenue(venue) {
  return {
    type: LOAD_ACTIVE_VENUE,
    activeVenue: venue,
  };
}

export function loadActiveMehndi(venue) {
  return {
    type: LOAD_ACTIVE_MEHNDI,//sam
    activeMehndi: venue,
  };
}

export function checkToken(sToken) {
  return (dispatch) => {
    const token = typeof window === 'undefined' ? sToken : localStorage.getItem('token');
    if (!token) {
      return Promise.resolve(dispatch(tokenInvalid()));
    }
    dispatch(requestCheckToken());
    return idCallApi(`api/auth/partner-login/verify?app=${PARTNER}`, 'POST', { token: token }).then((response) => {
      if (response.status === 401) {
        dispatch(tokenInvalid());
        return Promise.reject();
      }
      loadUserPermissions(response.user && response.user.data && response.user.data)
      return response;
    })
      .then((response) => {
        const { user } = response;
        if (!user.ok) {
          dispatch(tokenInvalid());
          return Promise.reject();
        }
        dispatch(tokenValid(user.data));
        let partneraccount_id = localStorage.getItem('partneraccount_id');
        // let venue_access = localStorage.getItem('venue_access');
        dispatch(fetchVenueRoles({ pageNum: 1, pageSize: 25, filters: {} }))
        dispatch(fetchPartnerAccountInfo(partneraccount_id))
        dispatch(fetchAccessList(partneraccount_id));
      })
      .catch((err) => {
        console.log(err)
      })
  }
}


export function loginUser(creds, url) {
  return (dispatch) => {
    creds.password = btoa(creds.password)
    dispatch(requestLogin(creds));
    return idCallApi(`api/auth/partner-login?app=${PARTNER}`, 'post', creds)
      .then((response) => {
        const { partneraccount, token, message } = response.data;
        if (response.status === "Success") {
          localStorage.setItem('token', token);
          reactCookie.save('token', token);
          localStorage.setItem('partneraccount_id', partneraccount._id);
          if (partneraccount._id) {
            dispatch(fetchPartnerAccountInfo(partneraccount._id))
            dispatch(fetchAccessList(partneraccount._id));
          }
          dispatch(loginSuccess(response.data, url));
          dispatch(fetchVenueRoles({ pageNum: 1, pageSize: 25, filters: {} }))
          showMessage('Login Successful', 'success');
        } else if (response.status === "Error") {
          if (!token) {
            showMessage('Invalid Username/Password', 'error')
            dispatch(loginFailure(message));
            return Promise.reject(message);
          }
        }
      })
      .catch((err) => {
      });
  };
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}

export function accessListFetchingStatus(status) {
  return {
    type: ACCESS_LIST_FETCHING_STATUS,
    isFetchingAccessList: !!status
  };
}

export function setPartnerAccountData(partneraccountData) {
  return {
    type: SET_PARTNER_ACCOUNT_DATA,
    partneraccount: partneraccountData,
  }
}

export function fetchPartnerAccountInfo(partneraccount_id) {
  try {
    return (dispatch) => {
      return idCallApi(`api/v1/${partneraccount_id}/partner-account-info`).then(res => {
        if (res.status === 'Success') {
          if (res.data && res.data.partneraccount) {
            dispatch(setPartnerAccountData(res.data.partneraccount))
          }
        } else {
          userNotify('Failed to fetch partner account info', 'error')
          console.log(res)
        }
      })
    }
  } catch (e) {
    console.log(e)
  }
}

const createVenueCallList = (accessList) => {
  let venueList = [];
  let vendorList = [];

  accessList.forEach((accessObject, index) => {
    if (accessObject.ref_name === 'venue') {
      venueList.push(accessObject.ref_id)
    }
    if (accessObject.ref_name === 'vendor') {
      vendorList.push(accessObject.ref_id)
    }

  })
  return { venueList, vendorList }
}

function appendAccessListRolesToPartnerAccount(accessList) {
  return {
    type: ACCESS_LIST_APPEND_TO_PARTNERACCOUNT,
    accessList: accessList
  }
}

export function fetchAccessList(partneraccountId) {
  try {
    return (dispatch) => {
      dispatch(accessListFetchingStatus(true));

      return idCallApi(`partner_access_list/partner/${partneraccountId}/list`).then(accessListResponse => {
        if (accessListResponse.status === 'Success') {
          if (accessListResponse.data) {
            let { venueList, vendorList } = createVenueCallList(accessListResponse.data.partner_access_list ? accessListResponse.data.partner_access_list : [])
            return callVenueApi(`venuemember/${partneraccountId}/venue-list`, 'get').then(res => {
              if (res.status === 'Success') {
                let venueMembers = res.data.venueMembers ? res.data.venueMembers : [];
                let venueList = [];
                venueMembers.forEach(venuemember => {
                  if (venuemember && venuemember.venueId) {
                    venueList.push(venuemember.venueId);
                  }
                });
                let venuesDetails = res.data.venues ? res.data.venues : [];
                let venueListDetail = [];
                venueListDetail = venuesDetails.map((venuesDetail, index) => {
                  if (venuesDetail && venuesDetail._id) {
                    return {
                      _id: venuesDetail?._id,
                      name: venuesDetail?.name,
                      image: venuesDetail?.images[0]?.url?.toLowerCase(),
                      city: venuesDetail?.city,
                      vm_id: venuesDetail?.vm_id
                    }
                  }
                });
                let active_venue = JSON.parse(localStorage.getItem('active_venue'));
                if (active_venue) {
                  dispatch(fetchMemberList(active_venue._id, {
                    pageNum: 1,
                    pageSize: 25,
                  }))
                  dispatch(loadActiveVenue(active_venue));
                  dispatch(loadAccessList(venueList, venueListDetail));
                  dispatch(appendAccessListRolesToPartnerAccount(accessListResponse?.data?.partner_access_list))
                } else if (venueListDetail && venueListDetail.length > 0) {
                  active_venue = venueListDetail[0];
                  dispatch(fetchMemberList(active_venue._id, {
                    pageNum: 1,
                    pageSize: 25,
                  }))
                  localStorage.setItem('active_venue', JSON.stringify(active_venue));
                  dispatch(loadActiveVenue(active_venue));
                  dispatch(loadAccessList(venueList, venueListDetail));
                  dispatch(appendAccessListRolesToPartnerAccount(accessListResponse?.data?.partner_access_list))
                }
              } else {
                dispatch(accessListFetchingStatus(false));
              }
            });
          }
        } else {
          userNotify('Failed to fetch partner access list', 'error')
        }
      })


    };
  } catch (e) {
    console.log(e)
  }
}

export function requestOtpLoginStatus() {
  return {
    type: REQUEST_OTP_LOGIN,
    isRequestingOtp: true,
    isOTPSent: false
  };
}

export function processOTPRequestFail(message) {
  return {
    type: REQUEST_OTP_FAILURE,
    isRequestingOtp: false,
    isOTPSent: false,
    message: message
  };
}

export function processOTPRequestSuccess(message) {
  return {
    type: REQUEST_OTP_SUCCESS,
    isRequestingOtp: false,
    isOTPSent: true,
    message: message
  };
}


export function editLoginNumber() {
  return {
    type: EDIT_LOGIN_NUMBER,
    isOTPSent: false,
  };
}

export function loadvenuespaceList(data) {
  return {
    type: LOAD_VENUE_SPACE_DATA,
    venuespacedata: data
  };
}



export function loginOtpSuccess(user) {
  return {
    type: LOGIN_OTP_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: user.token,
    data: user.data,
    isRequestingOtp: false,
    isOTPSent: false
  };
}

export function loginOtpFailure(message) {
  return {
    type: LOGIN_OTP_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message: message,
    isRequestingOtp: false,
    isVerifyingOTP: false,
    // isOTPSent: false
  };
}

export function requestOTPLogin(creds) {
  return (dispatch) => {
    dispatch(requestOtpLoginStatus());
    return idCallApi(`api/auth/partner-request-otp`, 'POST', creds)
      .then((response) => {
        if (response.status === 'Success') {
          if (response.data) {
            dispatch(processOTPRequestSuccess(response.data.message ? response.data.message : 'OTP sent'));
            showMessage('OTP Sent', 'success');
          }
        } else {
          dispatch(processOTPRequestFail(response.data.message ? response.data.message : 'Failed to send OTP'));
          showMessage('Failed to send OTP, try again', 'error');
        }
      })
      .catch((err) => {
        console.error(err)
      });
  };
}

export function verifyingOTPState(status) {
  return {
    type: VERIFYING_OTP,
    isVerifyingOTP: !!status
  };
}

export function authenticateOTP(creds, url) {
  return (dispatch) => {
    dispatch(verifyingOTPState(true));
    return idCallApi(`api/auth/partner-verify-otp`, 'POST', { creds: creds })
      .then((response) => {
        if (response.status === 'Success') {
          if (response.data) {
            let { token, message, partneraccount } = response.data
            localStorage.setItem('token', token);
            reactCookie.save('token', token);
            localStorage.setItem('partneraccount_id', partneraccount._id);
            if (partneraccount._id) {
              dispatch(fetchPartnerAccountInfo(partneraccount._id))
              dispatch(fetchAccessList(partneraccount._id));
            }
            dispatch(loginSuccess(response.data, url));
            dispatch(fetchVenueRoles({ pageNum: 1, pageSize: 25, filters: {} }))
            showMessage(message ? message : 'Login Successful', 'success');
          }
        } else {
          showMessage('Failed to verify, Try again', 'error');
          dispatch(loginOtpFailure(response.data.message ? response.data.message : 'Failed to verify OTP'));
        }
      })
      .catch((err) => {
        console.error(err)
      });
  };
}

export function otpRequest(phone) {
  return (dispatch) => {
    dispatch(requestOtpLoginStatus());
    return callApi(`/request-otp`, 'post'
    ).then(res => {
    });
  };

}

export function loadCityList(data) {
  return {
    type: LOAD_CITY_LIST,
    cities: data,
    count: data.length,
  };
}

export function pivotStatusLeads(data) {
  return {
    type: PIVOT_STATUS_LEADS,
    pivotstatusleads: data,
  };
}

export function agentWisePivotLeads(data) {
  return {
    type: AGENT_WISE_PIVOT_LEADS,
    agentwisepivotleads: data,
  };
}

export function loadOperatingCityList(data) {
  return {
    type: FETCH_OPERATIONAL_CITIES,
    operationalCity: data.cities,
  };
}


function createCityList(list) {
  let newList = [...list]
  newList = newList.map(city => {
    let temp = { ...city }
    temp.name = _.startCase(temp.name)

    return temp
  })

  return newList
}

export function fetchCityList(filters) {
  return (dispatch) => {
    return callSalesApi('api/v3/citys/city-list', 'get').then((res) => {
      if (res.status === 'Success') {
        let updatedCities = createCityList(res.data.cities);
        dispatch(loadCityList(updatedCities));
      } else {

      }
    });
  };
}

export function fetchVenueSpaceList(lead) {
  return (dispatch) => {
    return callVenueApi(`api/v2/venuespaces/venue/${lead}/list`,
      "get")
      .then(res => {
        if (res.status === 'Success') {
          dispatch(loadvenuespaceList(res.data.venuespace));
        } else {

        }
      });
  };
}


export const getUserPermissions = () => {

}

export function loadUserPermissions(data) {
  return {
    type: LOAD_USER_PERMISSIONS,
    userPermissions: data.userPermission && !_.isEmpty(data.userPermission) ? data.userPermission.userPermissions : [],
  };
}

export function fetchUserPermissions(userId) {
  return (dispatch) => {
    return idCallApi(`profile/user/${userId}/permission-info`, 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadUserPermissions(res.user && res.user.data && res.user.data));
      } else {

      }
    });
  };
}

export function loadDashboardData(data) {
  return {
    type: LOAD_DASHBOARD_DATA,
    current_month_leads: data.current_month_leads ? data.current_month_leads : 0,
    current_month_active_leads: data.current_month_active_leads ? data.current_month_active_leads : 0,
    last_month_leads: data.last_month_leads ? data.last_month_leads : 0,
    last_month_active_leads: data.last_month_active_leads ? data.last_month_active_leads : 0,
    current_month_bookings: data.current_month_bookings ? data.current_month_bookings : 0,
    last_month_active_bookings: data.last_month_active_bookings ? data.last_month_active_bookings : 0,
    current_month_revenue: data.current_month_revenue ? data.current_month_revenue : 0,
    last_month_revenue: data.last_month_revenue ? data.last_month_revenue : 0,
    current_month_accepted_leads: data.current_month_accepted_leads ? data.current_month_accepted_leads : 0,
    current_month_rejected_leads: data.current_month_rejected_leads ? data.current_month_rejected_leads : 0,
    last_month_accepted_leads: data.last_month_accepted_leads ? data.last_month_accepted_leads : 0,
    last_month_rejected_leads: data.last_month_rejected_leads ? data.last_month_rejected_leads : 0,
    current_month_closed_lost_leads: data.current_month_closed_lost_leads ? data.current_month_closed_lost_leads : 0,
    last_month_closed_lost_leads: data.last_month_closed_lost_leads ? data.last_month_closed_lost_leads : 0
  };
}

export function currentMonthGraphStats(data) {
  return {
    type: LOAD_DASHBOARD_LEADS_DATA,
    current_month_leads_dashboard_graph_data: data
  }
}

export function fetchDashboardLeadsStatics(venueId, filters) {
  return (dispatch) => {
    return callEventApi(`api/v3/partner-dashboard/venue/${venueId}/lead-stats`, 'post', { filters: filters }).then(res => {
      if (res.status === 'Success') {
        dispatch(currentMonthGraphStats(res.data))
      } else {
        console.log(res.status)
      }
    });
  };
}



export function fetchDashboardStatics(venueId) {
  return (dispatch) => {
    return callEventApi(`api/v3/partner-dashboard/home-stats?venue_id=${venueId}`, 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadDashboardData(res.data));
      } else {
        // todo add system for something
      }
    });
  };
}



export function fetchOperationalCityList() {
  return dispatch => {
    return callCoreApi('v1/cities/city-list?is_operational=true', 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadOperatingCityList(res.data));
      } else {
      }
    });
  };
}



export function loadClosedLostReasonList(data) {
  return {
    type: LOAD_CLOSED_LOST_REASON_LIST,
    closedLostReasons: data.closed_lost_reasons_list,
  };
}

export function fetchClosedLostReasonList() {
  return (dispatch) => {
    return callEventApi(`api/v3/partner-dashboard/leads/closed-lost-reason-list`, 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadClosedLostReasonList(res.data));
      } else {

      }
    });
  };
}

export function loadLeadStatusList(data) {
  return {
    type: LOAD_LEAD_STATUS_LIST,
    leadStatusList: data.status_list,
  };
}

export function fetchLeadStatusList() {

  return (dispatch) => {
    return callEventApi(`api/v3/partner-dashboard/leads/status-list`, 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadLeadStatusList(res.data));
      } else {

      }
    });
  };
}


export function loadCallStatusList(data) {
  return {
    type: LOAD_CALL_STATUS_LIST,
    callStatusList: data.call_status_list,
  };
}

export function loadvenueWebhookkey(value) {
  return {
    type: LOAD_VENUE_KEY,
    venuekey: value,
  };
}

export function fetchCallStatusList() {
  return (dispatch) => {
    return callEventApi(`api/v3/partner-dashboard/leads/call-status-list`, 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadCallStatusList(res.data));
      } else {

      }
    });
  };
}

export function loadPartnerApprovals(data, pageNum) {
  return {
    type: LOAD_PARTNER_APPROVALS,
    partnerApprovalList: data.partnerapprovals,
    partnerApprovalCount: data.totalCount,
    partnerApprovalPageNum: pageNum ? pageNum : 1,
    isFetchingPartnerApprovals: false,
  };
}

export function partnerApprovalFetchingStatus(status) {
  return {
    type: PARTNER_APPROVAL_FETCHING_STATUS,
    isFetchingPartnerApprovals: !!status
  };
}

export function fetchAllPartnerApprovals(venueId, filters) {
  return (dispatch) => {
    dispatch(partnerApprovalFetchingStatus(true));
    return callApi(`venues/venue/${venueId}/partnerapproval-list`, 'post', { filters: filters }).then(res => {
      if (res.status === 'Success') {
        dispatch(loadPartnerApprovals(res.data, filters && filters.pageNum ? filters.pageNum : 1));
      } else {
        dispatch(partnerApprovalFetchingStatus(false));
      }
    });
  };

}

export function loadUpdateApprovalStatus(status) {
  return {
    type: LOAD_UPDATE_APPROVAL_STATUS,
    isUpdatingApproval: !!status
  };
}

export function updateApproval(approvalId, approval) {
  return (dispatch) => {
    dispatch(loadUpdateApprovalStatus(true));
    return callApi(`venues/partnerapproval/${approvalId}/update-approval`, 'post', { partnerapproval: approval }).then(res => {
      if (res.status === 'Success') {
        dispatch(loadUpdateApprovalStatus(false));
      } else {
        dispatch(loadUpdateApprovalStatus(false));
      }
    });
  };
}

export function loadVenueRoles(venueRoles) {
  return {
    type: ADD_VENUE_ROLES,
    venueRoles: venueRoles
  }
}

export function fetchVenueRoles(filters = {}) {
  try {
    return (dispatch) => {
      return idCallApi(`venuerole/list`, 'post', filters).then(res => {
        if (res.status === 'Success') {
          if (res.data?.venueroleList) {
            dispatch(loadVenueRoles(res.data.venueroleList));
          } else {
            console.error(res.data.message ? res.data.message : 'an internal error occurred')
          }
        } else {
          console.error(res)
        }
      });
    };
  } catch (e) {
    console.error(e)
  }
}

export function createNewLead(lead, apikey) {
  return async (dispatch) => {
    const res = await callEventApiWithAPIKey(`api/v2/events/webhook-event?processInKue==false`, 'post', [lead], apikey);
    if (res.status === 'Success') {
      showMessage("Success!", "Lead Created", 'success')
    } else {
      showMessage("Lead Creation Failed", 'error')
    }
  }
}
function fetchContentByCode(code, next) {
  callApiCallingStatus(`v2/contents/code/${code}`, "GET")
    .then((res) => {
      if (res.status === "Success") {
        return next(null, res.data);
      } else {
        return next(res.data);
      }
    })
    .catch((err) => {
      return next(err);
    });
}

export function fetchTaskStaticContent() {
  return (dispatch) => {
    let model = {};
    async.series(
      [
        (cb) => {
          fetchContentByCode("closed-lost-reasons", (err, data) => {
            if (err) {
              console.log("error", err);
              return cb();
            } else {
              console.log("closed-lost-reasons", data.content.params);
              model.closedLostReasons = data.content.params;
              return cb();
            }
          });
        },
        (cb) => {
          fetchContentByCode("calling-status-list", (err, data) => {
            if (err) {
              return cb();
            } else {
              model.callingStatusList = data.content.params;
              return cb();
            }
          });
        },
      ],
      (err) => {
        if (err) {
          console.log("err" + err);
        } else {
          dispatch({
            type: FETCH_TASK_CONTENT,
            taskContent: model,
          });
        }
      }
    );
  };
}




export function fetchVenuekeys(venueId) {
  return (dispatch) => {
    return callEventApi(`api/v1/venueKeys/partner/venue/${venueId}/api-key`, 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadvenueWebhookkey(res.data));
      } else {

      }
    });
  };
}

export function statusPivotLeads(venueId) {
  return async (dispatch) => {
    try {
      const res = await callEventApi(`api/v3/partner-dashboard/leads/status-pivot-of-leads`, 'POST', {
        venue_id: venueId
      });

      if (res.status === 'Success') {
        dispatch(pivotStatusLeads(res.data));
      }
    } catch (err) {
      console.error(err);
    }
  };
}

export function agentPivotLeads(venueId) {
  return async (dispatch) => {
    try {
      const res = await callEventApi(`api/v3/partner-dashboard/leads/agent-wise-pivot-of-leads`, 'POST', {
        venue_id: venueId
      });

      if (res.status === 'Success') {
        dispatch(agentWisePivotLeads(res.data));
      }
    } catch (err) {
      console.error(err);
    }
  };
}

import React, { Component } from 'react';
import moment from 'moment';
import { Button, Spinner } from 'react-bootstrap';

class RejectLeadForm extends Component{
  constructor(props){
    super(props);
    this.state = {
      isOtherReason: false,
      reasonForRejection: '',
      isNotificationShown: false,
    };
  }

  rejectLead = () => {
    let updatedLead = {};
    if(this.state.isOtherReason) {
      updatedLead.conversion_factor = this.otherReason.value;
    } else {
      updatedLead.conversion_factor = this.state.reasonForRejection;
    }
    updatedLead.isVenueAvailableForEventDate = false;
    updatedLead.venueShow = false;
    updatedLead.isFrozen = true;
    updatedLead.venueReject = true;
    updatedLead.conversion_date = moment().utc().utcOffset(330).toISOString();
    if(updatedLead.conversion_factor && updatedLead.conversion_factor !== '') {
      if(!this.state.isNotificationShown) {
        this.setState({isNotificationShown: true});
      } else if(this.props.leadId) {
        this.props.handleRejectAvailability(this.props.leadId, updatedLead);
      }
    } else {
      this.props.showMessage('Please give a reason for rejection.', 'warning');
    }

  };

  handleChange = (keyValue) => {
    if(keyValue === 'Other reason') {
      this.setState({
        reasonForRejection: keyValue,
        isOtherReason: true,
        isNotificationShown: false

      });
    } else {
      this.setState({
        reasonForRejection: keyValue,
        isOtherReason: false,
        isNotificationShown: false
      });
    }

  };

  render() {
    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-12">
            <label >{'Reason For Rejection'}</label>
            <div className="input-group">
              <select className="form-control" name="" id="" onChange={(e) => this.handleChange(e.target.value)}>
                <option value="">-- Select Reason --</option>
                {
                  this.props.closedLostReasons && this.props.closedLostReasons.length > 0 && this.props.closedLostReasons.map(item => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))
                }
                <option key={'Other reason'} value="Other reason">Other reason</option>
              </select>
            </div>
          </div>
          {
            this.state.isOtherReason ?
              <div className="col-12" style={{marginTop: '15px'}}>
                <label >{'Other Reason.'}</label>
                <div className="input-group">
                  <textarea
                    defaultValue={''}
                    className="form-control"
                    aria-label="Phone"
                    aria-describedby="userSms-addon"
                    ref={ref => this.otherReason = ref}
                  />
                </div>
              </div> : null
          }

        </div>
        {
          this.state.isNotificationShown ?
            <div className="row">
              <div className="col-12" style={{marginTop: '25px', marginBottom: '5px'}}>
                <div className="alert-danger">{`You will no longer see this Lead.`}</div>
              </div>
            </div> : null
        }
        <div style={{position: 'relative',  display: 'block', marginTop: '15px', width: '100%'}}>
          <Button disabled={this.props.isRejectingLead} className="btn btn-danger" style={{width: '100%'}} onClick={() => this.rejectLead()} >{this.props.isRejectingLead ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          /> : this.state.isNotificationShown ? 'Confirm' : 'Reject'}</Button>
        </div>
      </div>
    );
  }
}

export default RejectLeadForm;

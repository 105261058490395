
// Import Actions
import {
    REQUEST_FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    REQUEST_RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE
} from './ResetPageAction';

// Initial State
const initialState = {
    isFetching: false,
    message: '',
    messagetype: ''
};

const ResetPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_FORGOT_PASSWORD:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: action.isFetching,
                message: action.message,
                messagetype: 'success',
            };

        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching,
                message: action.message,
                messagetype: 'danger',
            };

        case REQUEST_RESET_PASSWORD:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: action.isFetching,
                message: action.message,
                messagetype: 'success',
            };

        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching,
                message: action.message,
                messagetype: 'danger',
            };

        default:
            return state;
    }
};

/* Selectors */
// Get showAddPost
export const getForgotFetchingState = state => state.reset.isFetching;
export const getForgotMessage = state => state.reset.message;
export const getForgotMessageType = state => state.reset.messagetype;

// Export Reducer
export default ResetPageReducer;

import React, { useState } from 'react';
import { Select, Radio, Checkbox } from 'antd';
import _ from 'lodash'
import dayjs from 'dayjs'

import { STATUS_OPTIONS } from 'constants/venueCalendarEnums';
import { eventColorBasedOnStatus } from 'util/venueCalendar';



const { Option } = Select;

function AvailabilityCalendarHeader(props) {
  const [selectedVenue, setSelectedVenue] = useState();
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isVenueSelectDropdownVisible,setVenueSelectDropdownVisible]=useState(false)
  


  const handleVenueChange = (value) => {
    setSelectedVenue(value);
    let filters={
      start_date:dayjs(props.currentViewStartDate).startOf('month').subtract(7,"days").toISOString(),
      end_date:dayjs(props.currentViewEndDate).endOf('month').add(7,"days").toISOString(),
    venuespace_list: !_.isEmpty(value) ? value : undefined
    }
    props.addToFilters({venuespace_list:filters.venuespace_list})
    props.refresh(filters,true)
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value)
    let filters={
     start_date:dayjs(props.currentViewStartDate).startOf('month').subtract(7,"days").toISOString(),
      end_date:dayjs(props.currentViewEndDate).endOf('month').add(7,"days").toISOString(),
      time_slot_statuses: !_.isEmpty(value) ? value : undefined
    }
    props.addToFilters({time_slot_statuses:filters.time_slot_statuses})
    props.refresh(filters,true)
  };

  const handleSelectOpen=(value)=>{
   
    setVenueSelectDropdownVisible(true)
   
  }


  return (
    <div className="calendar-header">
        <div>
      <Select
        id="venue-select"
        value={selectedVenue}
        style={selectedVenue && !_.isEmpty(selectedVenue) ? { width: "auto" } :{width:"200px"}}
        onChange={handleVenueChange}
        placeholder='Venue Spaces'
        mode={'multiple'}
      >
        {props.venueSpaceList.map((item)=>(
       <Option value={item._id}><div>{item.name}</div></Option>
        ))}
      </Select>
      </div>
      <div className="calendar-header-item">
      <Checkbox.Group  onChange={handleStatusChange} value={selectedStatus} >
        
        {STATUS_OPTIONS.map((item)=>(
        <Checkbox value={item.value}>
        <div style={{display:"flex",height:"100%"}}><div style={{width:"10px" ,backgroundColor:eventColorBasedOnStatus(item.value) ,marginRight :"5px"}}/>{item.label}</div>  
        </Checkbox>
        ))}
      </Checkbox.Group>
    </div>
    </div>
  );
}

export default AvailabilityCalendarHeader;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './VisitPage.css'
import {
    getActiveVenue,
    getActiveVenueId,
    getAgentList,
    getLeadManageParentPath,
    getLeadManageStaticContent, getPartnerAccount,
    getUserAgent
} from '../../../App/AppReducer';
import { addVisit, fetchLeadDetails, fetchVisitList, removeVisit, showFullViewTabVisibleState, updateVisit } from "../../LeadManageActions";
import {
    getFullViewTabVisiblestate,
    getLeadDetails,
    getLeadLogId,
    getSelectedLeadId,
    getVisitCount,
    getVisitPageNum,
    getVisitPageSize,
    getVisits
} from '../../LeadManageReducer';
import VmSvgImage from '../../../../components/Icons/VmSvgImage';
import NavBar from "../../components/NavBar";
import Breadcrumb from '../../components/Beadcrumb';
import { withRoute } from 'util/withRoute';
import { Button } from "react-bootstrap";
import { DatePicker, Drawer, Modal, Radio, Table } from "antd";
import MobileNavigationBar from "../../components/MobileNavigationBar";
import dayjs from "dayjs";
import { userNotify } from "../../../../components/Toasts/ToastAdd";
import _ from "lodash";
import { fetchAgentList } from "../../../App/AppActions";
import { Helmet } from "react-helmet";
import SwitchControl from "../../../../components/PermissionLocks/SwitchControl";
import moment from 'moment';
import { current } from '@reduxjs/toolkit';

class VisitPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdateFormVisible: false,
            updateVisit: {},
            isAddVisitDrawerOpen: false,
            isEditVisitDrawerOpen: false,
            isDeletingVisit: false,
            visitDate: '',
            isVisitCancelled: false,
            isVisitCompleted: false,
            isConfirmedByUser: false,
            isConfirmedByVenue: false,
            visits: [],
            activeVisit: {}
        };
    }

    visitColumns = [
        {
            title: 'Visit Date',
            dataIndex: 'visit_date',
            key: 'visit_date',
            render: (value) => <div
                className={'dateColumn'}>{value ? dayjs(value).format('DD MMM YYYY HH:MM a') : '--'}</div>,
        },
        {
            title: 'User Confirmed',
            dataIndex: 'isConfirmedByUser',
            key: 'isConfirmedByUser',
            render: (_, record) => <div className={'tableCellCenter'}><SwitchControl
                shouldHavePermissions={['leads:edit']}
                userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                yes={<Button onClick={() => this.setState({
                    isConfirmedByUser: !record.isConfirmedByUser,
                    activeVisit: record
                }, () => this.handleTableDataChange('userConfirmationChange'))}
                    className="btn btn-sm btn-white">{record.isConfirmedByUser ? 'Yes' : 'No'}</Button>}
                no={<Button disabled={true} onClick={() => this.setState({
                    isConfirmedByUser: !record.isConfirmedByUser,
                    activeVisit: record
                }, () => this.handleTableDataChange('userConfirmationChange'))}
                    className="btn btn-sm btn-white">{record.isConfirmedByUser ? 'Yes' : 'No'}</Button>} />
            </div>,
            align: 'center'
        },
        {
            title: 'Venue Confirmed',
            dataIndex: 'isConfirmedByVenue',
            key: 'isConfirmedByVenue',
            render: (_, record) => <div className={'tableCellCenter'}><SwitchControl
                shouldHavePermissions={['leads:edit']}
                userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                yes={<Button onClick={() => this.setState({
                    isConfirmedByVenue: !record.isConfirmedByVenue,
                    activeVisit: record
                }, () => this.handleTableDataChange('venueConfirmationChange'))}
                    className="btn btn-sm btn-white">{record.isConfirmedByVenue ? 'Yes' : 'No'}</Button>}
                no={<Button disabled={true} onClick={() => this.setState({
                    isConfirmedByVenue: !record.isConfirmedByVenue,
                    activeVisit: record
                }, () => this.handleTableDataChange('venueConfirmationChange'))}
                    className="btn btn-sm btn-white">{record.isConfirmedByVenue ? 'Yes' : 'No'}</Button>} />
            </div>,
            align: 'center'
        },
        {
            title: 'Visit Agent',
            dataIndex: 'agent_name',
            key: 'agent_name',
            render: (text) => <div className={'tableCellCenter'}><span>{text}</span></div>
        },
        {
            title: 'Is Visit Cancelled',
            dataIndex: 'isVisitCancelled',
            key: 'isVisitCancelled',
            render: (_, record) => <div className={'tableCellCenter'}><SwitchControl
                shouldHavePermissions={['leads:edit']}
                userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                yes={<Button onClick={() => this.setState({
                    isVisitCancelled: !record.isVisitCancelled,
                    activeVisit: record
                }, () => this.handleTableDataChange('isCancelled'))}
                    className="btn btn-sm btn-white">{record.isVisitCancelled ? 'Yes' : 'No'}</Button>}
                no={<Button disabled={true} onClick={() => this.setState({
                    isVisitCancelled: !record.isVisitCancelled,
                    activeVisit: record
                }, () => this.handleTableDataChange('isCancelled'))}
                    className="btn btn-sm btn-white">{record.isVisitCancelled ? 'Yes' : 'No'}</Button>} /></div>,
            align: 'center'
        },

        {
            title: 'Is Visit Done',
            dataIndex: 'isVisitCompleted',
            key: 'isVisitCompleted',
            render: (_, record) => <div className={'tableCellCenter'}><SwitchControl
                shouldHavePermissions={['leads:edit']}
                userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                yes={<Button onClick={() => this.setState({
                    isVisitCompleted: !record.isVisitCompleted,
                    activeVisit: record
                }, () => this.handleTableDataChange('isVisitDone'))}
                    className="btn btn-sm btn-white">{record.isVisitCompleted ? 'Yes' : 'No'}</Button>}
                no={<Button disabled={true} onClick={() => this.setState({
                    isVisitCompleted: !record.isVisitCompleted,
                    activeVisit: record
                }, () => this.handleTableDataChange('isVisitDone'))}
                    className="btn btn-sm btn-white">{record.isVisitCompleted ? 'Yes' : 'No'}</Button>} /></div>,
            align: 'center'
        },
        {
            title: 'Action',
            dataIndex: 'remove_visit',
            key: 'remove_visit',
            render: (_, record) => <div className={'tableCellCenter'}>
                <SwitchControl
                    shouldHavePermissions={['leads:edit']}
                    userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                    yes={<Button onClick={() => {
                        this.setState({
                            isDeletingVisit: true,
                            activeVisit: record ? record : {},
                        })
                    }} className="btn btn-sm btn-white"><i
                        className="fa fa-trash"></i></Button>}
                    no={<Button disabled={true} onClick={() => {
                        this.setState({
                            isDeletingVisit: true,
                            activeVisit: record ? record : {},
                        })
                    }} className="btn btn-sm btn-white"><i
                        className="fa fa-trash"></i></Button>}
                />
                <SwitchControl
                    shouldHavePermissions={['leads:edit']}
                    userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                    yes={<Button onClick={() => {
                        this.setState({
                            isEditVisitDrawerOpen: true,
                            activeVisit: record ? record : {},
                            visitDate: record.visit_date ? record.visit_date : '',
                            isConfirmedByUser: record.isConfirmedByUser,
                            isConfirmedByVenue: record.isConfirmedByVenue,
                            isVisitCompleted: record.isVisitCompleted
                        })
                    }} className="btn btn-sm btn-white"><i className="fa fa-pencil"></i></Button>}
                    no={<Button disabled={true} onClick={() => {
                        this.setState({
                            isEditVisitDrawerOpen: true,
                            activeVisit: record ? record : {},
                            visitDate: record.visit_date ? record.visit_date : '',
                            isConfirmedByUser: record.isConfirmedByUser,
                            isConfirmedByVenue: record.isConfirmedByVenue,
                            isVisitCompleted: record.isVisitCompleted
                        })
                    }} className="btn btn-sm btn-white"><i className="fa fa-pencil"></i></Button>}
                />
            </div>,
            align: 'center'
        },

    ]

    componentDidMount() {
        let filters = {};
        filters.pageNum = this.props.pageNum;
        filters.pageSize = this.props.pageSize;
        let leadId = this.props.leadid ? this.props.leadid : this.props.params.leadid ? this.props.params.leadid : '';

        this.props.dispatch(fetchAgentList())

        if (leadId) {
            this.props.dispatch(fetchLeadDetails(leadId));
        }

        let eventId = this.props?.lead?.event
        let venueId = this.props?.venueId

        if (eventId && venueId) {
            this.props.dispatch(fetchVisitList(eventId, venueId, filters));
            this.setState({
                visits: this.props.visits,
            })
        }
        if (this.props.visits && this.props.agentList) {
            this.createVisitTableData(this.props.agentList, this.props.visits)
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let leadId = this.props.params.leadid ? this.props.params.leadid : this.props.leadid ? this.props.leadid : '';
        let eventId = this.props?.lead?.event
        let venueId = this.props?.venueId
        let filters = {};
        filters.pageNum = this.props.pageNum;
        filters.pageSize = this.props.pageSize;
        if (prevProps.leadId && leadId && (prevProps.leadId !== leadId)) {
            this.props.dispatch(fetchLeadDetails(leadId));
        }


        if (eventId && venueId && ((prevProps.venueId !== venueId) || (prevProps.lead.event !== eventId))) {
            this.props.dispatch(fetchVisitList(eventId, venueId));
            this.setState({
                visits: this.props.visits
            })
        }

        if (!_.isEqual(this.props.visits, prevProps.visits)) {
            this.createVisitTableData(this.props.agentList, this.props.visits)
        }
    }

    handleTableDataChange = (type) => {
        let visit = { ...this.state.activeVisit }
        switch (type) {
            case 'userConfirmationChange':
                visit.isConfirmedByUser = this.state.isConfirmedByUser
                break
            case 'venueConfirmationChange':
                visit.isConfirmedByVenue = this.state.isConfirmedByVenue
                break
            case 'isCancelled':
                visit.isVisitCancelled = this.state.isVisitCancelled
                break
            case 'isVisitDone':
                visit.isVisitCompleted = this.state.isVisitCompleted
            default:
                break
        }
        this.props.dispatch(updateVisit(visit._id, visit, this.props.agent, this.props.logId))
    }

    handleVisitDateChange = (date) => {
        this.setState({
            visitDate: date
        })
    }

    handleCloseOverlay = () => {
        this.setState({
            isUpdateFormVisible: false,
            updateVisit: {}
        });
    };

    handleOpenOverlay = (visit) => {
        this.setState({
            isUpdateFormVisible: true,
            updateVisit: visit,
        });
    };

    handleAddVisitDrawer = () => {
        this.setState({
            isAddVisitDrawerOpen: true
        })
    }

    closeDrawerForm = () => {
        this.setState({
            isAddVisitDrawerOpen: false,
            isEditVisitDrawerOpen: false,
            visitDate: '',
            isConfirmedByUser: false,
            isConfirmedByVenue: false,
            isVisitCompleted: false
        })
    }

    handleSaveVisit = () => {
        let visit = {}
        if (!_.isEmpty(this.state.visitDate)) {

            visit.visit_date = this.state?.visitDate?.toISOString();
            visit.agentId = this.props.lead.admin_id
            visit.venueId = this.props.activeVenue._id
            visit.eventId = this.props.lead.event
            visit.venue_name = this.props.activeVenue.name
            visit.isConfirmedByUser = true
            visit.isConfirmedByVenue = true
            visit.isCreatedByVenue = true
            visit.lead_source = "partner-crm";
            visit.ref_id = this.props.leadId
            visit.ref_name = "venueBundle"
            visit.isCreatedByAdmin = false
            this.props.dispatch(addVisit(this.props.lead.event, this.props.activeVenue._id, visit))
            this.setState({
                visitDate: '',
                isConfirmedByUser: false,
                isConfirmedByVenue: false,
                isVisitCompleted: false,
                isVisitCancelled: false,
                activeVisit: {},
                isAddVisitDrawerOpen: false,
            })
        } else {
            userNotify('Please select a visit date', 'warning')
        }
    }

    handleAddCustomerVisit = () => {
        this.props.dispatch()
    }

    createVisitTableData = (agentList, visits) => {
        try {
            let agentMap = {}
            agentList.forEach((agent) => {
                agentMap[agent._id] = agent
            })
            if (visits && visits.length > 0) {
                let newVisits = visits.map(visit => {
                    return {
                        ...visit,
                        key: visit._id,
                        agent_name: visit?.agentId && agentMap[visit?.agentId] && agentMap[visit.agentId]?.name ? agentMap[visit.agentId]?.name : '--'
                    }
                })
                this.setState({
                    visits: newVisits
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    handleRemoveVisit = () => {
        if (this.state.activeVisit) {
            let visit = { ...this.state.activeVisit }
            this.props.dispatch(removeVisit(visit.eventId, visit.venueId, visit._id, visit))
        }
        this.setState({
            isDeletingVisit: false,
            activeVisit: {}
        })
    }

    handleUpdateVisit = () => {
        if (this.state.activeVisit) {
            let visit = { ...this.state.activeVisit }
            visit.visit_date = this.state.visitDate ? this.state.visitDate.toISOString() : ''
            visit.isConfirmedByVenue = this.state.isConfirmedByVenue
            visit.isCreatedByVenue = true
            visit.ref_id = this.props.leadId
            visit.ref_name = "venueBundle"
            visit.isVisitCompleted = this.state.isVisitCompleted
            visit.isVisitCancelled = this.state.isVisitCancelled
            this.props.dispatch(updateVisit(visit._id, visit, this.props.agent, this.props.logId))
        }
        this.setState({
            isEditVisitDrawerOpen: false,
            visitDate: '',
            isConfirmedByUser: false,
            isConfirmedByVenue: false,
            isVisitCompleted: false,
            isVisitCancelled: false,
            activeVisit: {}
        })
    }

    handleShowFullView = (val) => {
        this.props.dispatch(showFullViewTabVisibleState(val));
    }


    render() {
        let segment, drawerContent, staticContent = this.props.staticContent;
        let breakpoint = 800;

        drawerContent = this.state.isAddVisitDrawerOpen || this.state.isEditVisitDrawerOpen ? <>
            <div className={'drawerContent'}>
                <label className={'labelMarginRight'}>{staticContent.VISIT_PAGE_ADD_VISIT_SELECT_LABEL}</label>
                <DatePicker className={'datePicker'}
                            disabledDate={(current) => {const today = new Date();
                                today.setHours(0,0,0,0);
                                return current && current.valueOf() < today.valueOf();}}
                            showTime={true}
                            format={'DD MMM YYYY hh:mm a'}
                            value={this.state.visitDate ? dayjs(this.state.visitDate) : ''}
                            onChange={(date) => date && this.handleVisitDateChange(date)}
                />
            </div>
            <div className={'radioGroup'}>
                <label
                    className={'labelMarginRight'}>{staticContent.VISIT_PAGE_ADD_VISIT_CUSTOMER_CONFIRM_LABEL}</label>
                <Radio.Group value={this.state.isConfirmedByUser}
                    onChange={e => this.setState({ isConfirmedByUser: e.target.value })}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </div>
            <div className={'radioGroup'}>
                <label className={'labelMarginRight'}>{staticContent.VISIT_PAGE_ADD_VISIT_VENUE_CONFIRM_LABEL}</label>
                <Radio.Group value={this.state.isConfirmedByVenue}
                    onChange={e => this.setState({ isConfirmedByVenue: e.target.value })}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </div>
        </> : null

        segment = <div className="col-sm-12 segmentContainer">
            <div className={'segment'}>
                <h5>{staticContent.VISIT_PAGE_TITLE}</h5>
                <div>
                    <SwitchControl
                        shouldHavePermissions={['leads:edit']}
                        userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                        yes={<Button style={{ backgroundColor: '#2a52bb' }} onClick={this.handleAddVisitDrawer}
                            className="btn btn-primary pull-right segmentButton">{staticContent.VISIT_PAGE_ADD_VISIT ? staticContent.VISIT_PAGE_ADD_VISIT : 'Add Visit'}</Button>}
                        no={<Button style={{ backgroundColor: '#2a52bb' }} disabled={true} onClick={this.handleAddVisitDrawer}
                            className="btn btn-primary pull-right segmentButton">{staticContent.VISIT_PAGE_ADD_VISIT ? staticContent.VISIT_PAGE_ADD_VISIT : 'Add Visit'}</Button>}
                    />
                </div>
            </div>
            <div className={'tableContainer'}>
                {
                    this.props.visits && this.props.visits.length > 0 ?
                        <Table size={'middle'} tableLayout={'auto'} bordered={true} pagination={false}
                            columns={this.visitColumns} dataSource={this.state.visits ? this.state.visits : []} />
                        :
                        <div className='empty-list-illustrations emptyVisitImage'>
                            <VmSvgImage
                                targetClass=""
                                iconName="vm-location-illustration"
                            />
                            <div className={'emptyVisitText'}>No Past Visit</div>
                        </div>
                }
            </div>
        </div>;
        return (
            <div className="row lead-manage-page"
                style={{ padding: this.props.windowWidth > breakpoint ? '10px 30px' : '10px', marginTop: '0px' }}>
                <Helmet>
                    <title>Visit List</title>
                </Helmet>
                <Modal onCancel={() => this.setState({ isDeletingVisit: false, activeVisit: {} })}
                    onOk={() => this.handleRemoveVisit()} open={this.state.isDeletingVisit}>
                    <p>Are you sure you want to remove the visit</p>
                </Modal>

                <Drawer
                    width={'30vw'}
                    height={this.props.windowWidth > breakpoint ? '100vh' : '60vh'}
                    placement={this.props.windowWidth > breakpoint ? 'right' : 'bottom'}
                    open={this.state.isAddVisitDrawerOpen || this.state.isEditVisitDrawerOpen}
                    onClose={this.closeDrawerForm}
                    title={this.state.isAddVisitDrawerOpen ? staticContent.VISIT_PAGE_ADD_VISIT : this.state.isEditVisitDrawerOpen ? staticContent.VISIT_PAGE_EDIT_VISIT : 'Add Visit'}
                    extra={
                        this.state.isAddVisitDrawerOpen ?
                            <Button
                                onClick={this.handleSaveVisit}
                                className="btn btn-primary pull-right visitFormButton">{staticContent.VISIT_PAGE_TITLE_SAVE ? staticContent.VISIT_PAGE_TITLE_SAVE : 'Save'}
                            </Button> :
                            this.state.isEditVisitDrawerOpen ?
                                <Button
                                    onClick={this.handleUpdateVisit}
                                    className="btn btn-primary pull-right visitFormButton">{staticContent.VISIT_PAGE_TITLE_SAVE ? staticContent.VISIT_PAGE_TITLE_SAVE : 'Save'}
                                </Button> :
                                null
                    }
                >
                    {drawerContent}
                </Drawer>
                {/* <Breadcrumb
                    windowWidth={this.props.windowWidth}
                    breakpoint={breakpoint}
                    isLeadManageFromLeadList={this.props.isLeadManageFromLeadList}
                    contact_name={this.props.lead.contact_name}
                /> */}

                {/* {this.props.isFullViewTabvisible && (
            <div>
                {this.props.windowWidth > breakpoint ? <NavBar
                    url={`/event/${this.props.leadid || this.props.params.leadid || this.props?.leadId}/visit`}
                    leadid={this.props.leadid || this.props.params.leadid}
                    staticContent={staticContent}
                /> : <MobileNavigationBar
                    url={`/event/${this.props.leadid || this.props.params.leadid || this.props?.leadId}/visit`}
                    leadid={this.props.leadid || this.props.params.leadid}
                    staticContent={staticContent}
                />}
                </div> )} */}
                <div
                    style={{ border: this.props.windowWidth < breakpoint ? 'none' : '' }}>
                    {segment}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        agentList: getAgentList(state),
        leadid: props.params?.leadid,
        leadId: getSelectedLeadId(state),
        currentUrl: props.location?.pathname,
        staticContent: getLeadManageStaticContent(state),
        pageSize: getVisitPageSize(state),
        pageNum: getVisitPageNum(state),
        count: getVisitCount(state),
        visits: getVisits(state),
        lead: getLeadDetails(state),
        isLeadManageFromLeadList: getLeadManageParentPath(state),
        activeVenue: getActiveVenue(state),
        logId: getLeadLogId(state),
        agent: getUserAgent(state),
        venueId: getActiveVenueId(state),
        partneraccount: getPartnerAccount(state),
        isFullViewTabvisible: getFullViewTabVisiblestate(state)

    };
}


VisitPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.object,
};


export default connect(mapStateToProps)(withRoute(VisitPage));

import React, {Component} from 'react';
import NormalLink from './NormalLink';
import DropDown from './DropDown';
import {Link} from "react-router-dom";
import {withRoute} from '../../util/withRoute'

const package_json = require('.././../../package.json');


const links = [
  {
    isNormalLink: true,
    url: '/',
    name: 'Home',
    icon: 'fa fa-home'
  },
  {
    isNormalLink: true,
    url: '/dashboard',
    name: 'DashBoard',
    icon: 'fa fa-home'
  },
    {
        isNormalLink: false,
        name: 'Member',
        icon: 'fa fa-bars',
        list: []
    },
    {
          isNormalLink: true,
          url: '/lead/list',
          name: 'Leads',
          icon: 'fa fa-bars'
    },
    {
      isNormalLink: true,
      url: '/lead/list',
      name: 'Lead Funnel',
      icon: 'fa fa-bars'
    }
];

class Sidebar extends Component {
    constructor() {
        super();
        this.state = {};
    }

    handleGenerateNewLinks = () => {
      let generatedLinks = links.map(link => {
        if(link.name === 'Member'){
          if(this.props.venueList.length > 1){
            let list = [];
            this.props.venueList.forEach(venue => {
              let venueLink = {};
              venueLink.isNormalLink = true;
              venueLink.url = `/venue/${venue._id}/member/list`;
              venueLink.name = venue.name;
              venueLink.icon = '';
              list.push(venueLink);
            });
            link.list = list;
          } else if(this.props.venueList.length === 1){
              link.isNormalLink = true;
              link.url = `/member/${this.props.venueList[0]._id}/list`;
              link.icon = 'fa fa-bars';
          }

        }
        if(link.name === 'Leads') {
          link.url = `/venue/${this.props.activeVenueId}/lead/list`;
        }
        return link;
      });
      return generatedLinks;
    };

    handleGenerateLinksForOneVenue = (activeVenueId) => {
      let newLinks = [
        {
          isNormalLink: true,
          isUrlArray: false,
          url: `/dashboard`,
          name: 'Dashboard',
          icon_primary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Dashboard_white.png',
            icon_secondary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Dashboard.png',
          shouldHavePermissions: [],
            width: '24px',
            height: '24px'
        },
        {
          isNormalLink: true,
          isUrlArray: false,
          url: `/lead/list`,
          name: 'Leads',
            icon_primary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Leads_white.png',
            icon_secondary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Group.png',
          icon: 'fa fa-bars',
          shouldHavePermissions: ['leads:read'],
            width: '36px',
            height: 'auto'
        },
        {
          isNormalLink: true,
          isUrlArray: false,
          url: `/booking/list`,
          name: 'Bookings',
            icon_primary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Bookings_white.png',
            icon_secondary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Bookings.png',
            shouldHavePermissions: ['booking:read'],
            width: '20px',
            height: '24px'
        },
        {
          isNormalLink: true,
          isUrlArray: false,
          url: `/member/list`,
          name: 'Members',
            icon_primary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Members_white.png',
            icon_secondary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Members.png',
            shouldHavePermissions: ['member:read'],
            width: '26px',
            height: 'auto'
        },
          // {
          //     isNormalLink: true,
          //     isUrlArray: false,
          //     url: `/${this.props.activeVenue._id}/subscription`,
          //     name: 'Subscription',
          //     icon_primary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Subscriptions_white.png',
          //     icon_secondary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Subscriptions.png',
          //     shouldHavePermissions: ['member:read'],
          //     width: '26px',
          //     height: 'auto'
          // },
          // {
          //     isNormalLink: true,
          //     isUrlArray: false,
          //     url: `/${this.props.activeVenue._id}/analytics`,
          //     name: 'Analytics',
          //     icon_primary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Analytics_white.png',
          //     icon_secondary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Analytics.png',
          //     shouldHavePermissions: ['member:read'],
          //     width: '24px',
          //     height: 'auto'
          // }
        // ,
          {
              isNormalLink: true,
              isUrlArray: false,
              url: `/${this.props.activeVenue._id}/calendar`,
              name: 'Calendar',
              icon_primary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Calander_white.png',
              icon_secondary: 'https://cdn.venuemonk.com/partner-app-icons/Icons-Svgs/Group-1.png',
              shouldHavePermissions: ['member:read'],
              width: '22px',
              height: 'auto'
          },
      ];

      newLinks = newLinks.filter(item => {
        let isShow = true;

        if(this.props.userPermissions && this.props.userPermissions.length > 0 && item.shouldHavePermissions && item.shouldHavePermissions.length > 0) {
          let matchParam =  item.shouldHavePermissions.filter(item2 => this.props.userPermissions.indexOf(item2) !== -1);
          isShow = matchParam && matchParam.length > 0;
        }
        return isShow;
      });

      return newLinks;
    };

    render() {
        let newLinks = this.handleGenerateLinksForOneVenue(this.props.activeVenueId);
        return (
            <div className="static-sidebar-wrapper sidebar-default" style={{position: 'absolute'}}>
                <div className="static-sidebar">
                    <div className="sidebar">
                        <div className="widget stay-on-collapse" id="widget-sidebar">
                            <nav role="navigation" className="widget-body">
                                <ul className="acc-menu" style={{marginBottom: 0}}>
                                    <li style={{display: 'inline'}}>
                                      {
                                        this.props.isShowSideBar ?
                                          <div style={{display: 'flex', contentAlign: 'row', backgroundColor: '#214091', justifyContent: 'space-between'}}>
                                            <div style={{display: 'inline-flex'}}>
                                              <span  style={{ padding: '16px 0px 16px 18px', display: 'block', color: '#fff', fontSize: '18px', textDecoration: 'none'}}>{this.props.user && this.props.user.data && this.props.user.data.username !== '' ? this.props.user.data.username : 'VenueMonk'}</span>
                                              {/*<span onClick={() => this.props.handleLogOut()} style={{marginLeft: '10px', padding: '16px 0px', lineHeight: 1.7, cursor: 'pointer'}}><i className="fa fa fa-power-off"></i></span>*/}
                                            </div>
                                            <span style={{float: 'right', cursor: 'pointer', marginTop: '8px', marginRight: '6px'}} onClick={() => this.props.toggleSideBar()}>
                                                    <i style={{padding: '12px 10px', fontSize: '18px'}} className="fa fa-arrow-left"></i>
                                            </span>
                                          </div>
                                          :
                                          <span style={{ backgroundColor: '#214091', display: 'block', cursor: 'pointer'}} onClick={() => this.props.toggleSideBar()}>
                                                    <i style={{padding: '12px 10px', fontSize: '36px'}} className="fa fa-bars"></i>
                                          </span>
                                      }

                                    </li>
                                    {newLinks.map(item => (
                                        item.isNormalLink ?
                                        <NormalLink
                                            pathName={this.props.location.pathname ? this.props.location.pathname : ''}
                                            width={item.width}
                                            height={item.height}
                                            icon_primary={item.icon_primary}
                                            icon_secondary={item.icon_secondary}
                                            key={item.url}
                                            url={item.url}
                                            name={item.name}
                                            icon={item.icon}
                                            isUrlArray={item.isUrlArray}
                                            activeUrl={this.props.activeUrl}
                                            isShowSideBar={this.props.isShowSideBar}
                                        /> :
                                            <DropDown
                                                key={item.name}
                                                name={item.name}
                                                list={item.list}
                                                activeUrl={this.props.activeUrl}
                                                isShowSideBar={this.props.isShowSideBar}
                                            />
                                    ))}
                                    {this.props.isShowSideBar ? <h2 className='text-center' style={{
                                        fontSize: '1.2rem',
                                        color: 'white',
                                        position: 'absolute',
                                        left: (this.props.windowWidth > 800) ? '27%' : '34%',
                                        top: '85vh'
                                    }}>Version {package_json.version}</h2> : null}
                                  <li className={'active text-center'}>
                                    <Link  onClick={() => this.props.handleLogOut()} style={{padding: `${this.props.isShowSideBar ? '12px': '8px 6px'}`, margin: this.props.isShowSideBar ? '5px 17px 0px' : '0 16%', position: 'absolute', top: '90vh', width: this.props.isShowSideBar ? '90%' : '36px', height: 'auto'}} to={'/'}>
                                      <i className="fa fa fa-power-off" />
                                      {
                                        this.props.isShowSideBar ?
                                          <span>{'Logout'}</span> : null
                                      }
                                    </Link>
                                  </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRoute(Sidebar);

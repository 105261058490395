/**
 * Root Reducer
 */
import { combineReducers } from "redux";

// Import Reducers
import app from "./modules/App/AppReducer";
import reset from "./modules/App/ResetPageReducer";
import venue from "./modules/Venue/VenueReducer";
import member from "./modules/Member/MemberReducer.js";
import leads from "./modules/Lead/LeadReducer.js";
// import leadfunnel from './modules/LeadFunnel/LeadFunnelReducer.js';
import leadmanage from "./modules/LeadManage/LeadManageReducer.js";
import booking from "./modules/Booking/BookingReducer.js";
import listyourbusiness from "./modules/ListYourBusiness/ListYourBusinessReducers.js";

// Combine all reducers into one root reducer
export default combineReducers({
  app,
  venue,
  member,
  leadmanage,
  leads,
  booking,
  listyourbusiness,
  reset,
});

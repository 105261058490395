import {connect} from "react-redux";
import {Select, Avatar, Space, Drawer} from "antd";
import {getPartnerAccount} from "../../modules/App/AppReducer";
import './css/ProfileHeader.css'
import {useEffect, useState} from "react";
import {KeyboardArrowDown} from "@mui/icons-material";
import {VerifiedUserOutlined} from "@mui/icons-material";

const ProfileHeader = (props) => {
    const [selectState, setSelectState] = useState('profile')
    const [drawerOpen, seIsDrawerOpen] = useState(false)
    const [drawerContent, setDrawerContent] = useState(<div></div>)
    const [drawerTitle, setDrawerTitle] = useState('profile')


    const selectOptions = [
        {
            value: 'profile',
            label: props.partneraccount.contact_name ? props.partneraccount.contact_name : '--',
            icon: <Avatar shape="square" size={26} style={{backgroundColor: 'rgb(42, 82, 187)', fontSize: '12px', fontWeight: 200}}>
                        {props.partneraccount?.contact_name ? props.partneraccount?.contact_name?.slice(0, 1) : props.partneraccount?.first_name ? props.partneraccount?.first_name.slice(0, 1) : 'U'}
            </Avatar>
        },
    ]


    const handleChangeDrawerContent = (e) => {
        switch (e) {
            case 'profile':
                setDrawerContent(<div>
                    <p className={'drawerHeader'}>Basic Details</p>
                    <div className={'contentRow'}>
                        <span className={'contentKey'}>Name: </span>
                        <span className={'contentValue'}>{props.partneraccount.contact_name ? props.partneraccount.contact_name : (props.partneraccount.first_name && props.partneraccount.last_name) ? `${props.partneraccount.first_name} ${props.partneraccount.last_name}` : '--'}</span>
                    </div>
                    <div className={'contentRow'}>
                        <span className={'contentKey'}>Email: </span>
                        <span className={'contentValue'}>{props.partneraccount.contact_email ? props.partneraccount.contact_email : '--'}</span>
                    </div>
                    <div className={'contentRow'}>
                        <span className={'contentKey'}>Ph no: </span>
                        <span className={'contentValue'}>{props.partneraccount.contact_phone ? props.partneraccount.contact_phone : '--'}</span>
                    </div>
                    <div className={'contentRow'}>
                        <span className={'contentKey'}>Role: </span>
                        <span className={'contentValue'}>{props.partneraccount.role ? props.partneraccount.role : '--'}</span>
                    </div>
                </div>)
                setDrawerTitle('Profile')
                seIsDrawerOpen(true)
                setSelectState(e)
                break
            default:
                setDrawerContent(<div>
                    No Drawer content selected
                </div>)
        }
    }

    return (
        <>
            <Drawer placement={props.windowWidth > props.breakpoint ?  'right' : 'bottom'} onClose={() => seIsDrawerOpen(false)} width={'50vw'} title={drawerTitle} open={drawerOpen}>{drawerContent}</Drawer>
            <Select
                size={'large'}
                style={{
                    width: props.windowWidth > props.breakpoint ? '180px' : '50px',
            }}
                dropdownStyle={{
                    padding: '0px'
                }}
                onSelect={e => (handleChangeDrawerContent(e))}
                popupMatchSelectWidth={true}
                className='profileHeader'
                value={selectState}
                suffixIcon={props.windowWidth > props.breakpoint ? <KeyboardArrowDown /> : null}
            >
                {selectOptions.map((option, index) => {

                    return (
                        <Select.Option key={index} value={option.value}>
                                {option.icon}
                                {props.windowWidth > props.breakpoint ? '   ' + option.label : null}
                        </Select.Option>
                    )
                })}
            </Select>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        partneraccount: getPartnerAccount(state)
    }
}

export default connect(mapStateToProps)(ProfileHeader)

/**
 * Main store function
 */
import { applyMiddleware, compose } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import DevTools from "./modules/App/components/DevTools.js";
import rootReducer from "./reducers";

export default function createStore(initialState = {}) {
  // Middleware and store enhancers
  const enhancers = [applyMiddleware(thunk)];

  if (process.env.CLIENT && process.env.NODE_ENV === "development") {
    // Enable DevTools only when rendering on client and during development.
    enhancers.push(
      window.devToolsExtension
        ? window.devToolsExtension()
        : DevTools.instrument()
    );
  }

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    enhancers: [...enhancers],
  });

  // For hot reloading reducers
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      const nextReducer = require("./reducers").default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }
  return store;
}

import React, {Component, Suspense} from 'react'
import { PropTypes } from 'prop-types'
import {connect} from 'react-redux';
import {
  getActiveVenue,
  getActiveVenueId,
  getAuthenticatedState,
  getCheckingTokenStatus,
  getPartnerAccount,
  getSessionUserId,
  getShowHeaderStatus,
  getVenueDetail,
  getVenueList
} from './App/AppReducer';
import {checkToken, fetchAccessList, loadActiveVenue, logoutUser} from './App/AppActions';
import ScreenLoader from '../components/Loader/ScreenLoader'
import Header from '../components/Header/Header';
import { Outlet } from 'react-router';
import { MenuRounded } from '@mui/icons-material';
import Sidebar from '../components/SideBar/SideBar';
import Footer from './App/components/Footer/Footer';
import NotificationBox from '../components/Toasts/ToastBox.js';
import { loadVmLeadTrackContent, setVmLeadTrackContent } from '../util/vmCookieHandler';
import { withRoute } from 'util/withRoute';
import NewFooter from './App/components/Footer/NewFooter';
const url_withOut_sidebar = [
  '/',  '/login', '/register', '/terms', '/list-your-business', '/paid-partner/new-subscription'
];

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowSideBar: false,
      sidebarWidth: '230px',
      windowWidth: window.innerWidth,
    };
    this.isAuthenticated = false;
  }

  componentDidMount() {
      this.props.dispatch(checkToken());
    this.saveLeadTrackingData();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getParameterByNameForUrl = (name) => {
    let url = window.location.href;
    name = name.replace(/\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  captureUtmInfo = () => {
    let utmInfo = {};
    const trackers = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'keyword', 'utm_content', 'campaignid', 'adgroupid', 'feeditemid', 'matchtype', 'network', 'device', 'adposition', 'loc_interest_ms', 'loc_physical_ms', 'placement', 'gclid'];
    trackers.forEach((item) => {
      let temp = this.getParameterByNameForUrl(item);
      if (temp) {
        utmInfo[item] = temp;
      }
    });
    return utmInfo;
  };

  saveLeadTrackingData = () => {
    let pastStats = loadVmLeadTrackContent();
    if (!pastStats) {
      let stats = this.captureUtmInfo();
      stats.landingPage = window.location.pathname;
      setVmLeadTrackContent(stats);
    }
  };

  toggleSideBar = () => {
    this.setState({isShowSideBar: !!!this.state.isShowSideBar, sidebarWidth: `${!this.state.isShowSideBar ? '230px' : '55px'}`});
  };

  handleChangeActiveVenue = (venue) => {
    this.props.dispatch(loadActiveVenue(venue));
    this.props.dispatch(fetchAccessList(this?.props?.partneraccount?._id))
  };

  handleShowHeader = () => {
    let isPresent = url_withOut_sidebar.indexOf(this.props.currentUrl);
    return !(isPresent < 0);
  };
  handleLogOut = () => {
    this.props.dispatch(logoutUser());
    this.props.navigate('/')
  };



  render() {
    let contentSegment = this.props.children;
    let isShowHeader = this.props.isShowHeader;
    let breakpoint = 800
    return (

          <div  style={{backgroundColor: '#fff', display: 'inline-block', width: '100%', position: 'relative', minHeight: '80vh'}}>

          {
            !this.props.isCheckingForToken && isShowHeader ?
              <div style={{height: '60px', position: 'fixed', zIndex: '1000', top: 0, backgroundColor: '#fff', width: '100%', boxShadow: '0px 0px 10px 0px #cdcdcd'}}>
                <Header
                    navigate={this.props.navigate}
                  toggleSideBar={this.toggleSideBar}
                  breakpoint = {breakpoint}
                  windowWidth={this.state.windowWidth}
                  isShowSideBar={this.state.isShowSideBar}
                  venueList={this.props.venueList}
                  venueDetails={this.props.venueDetails}
                  activeVenue={this.props.activeVenue}
                  isAuthenticated={this.props.isAuthenticated}
                  handleChangeActiveVenue={this.handleChangeActiveVenue}
                />
              </div> : null
          }
          {this.props.isAuthenticated && (this.state.windowWidth < breakpoint) ? <div onClick={this.toggleSideBar} style={{padding: '10px 0 0 10px', position: 'fixed', zIndex: '1000', color: '#0057ff', cursor: 'pointer'}}>
            <MenuRounded style={{cursor: 'pointer'}} onClick={this.props.toggleSideBar}  fontSize='large'/>
            <div style={{ width: `90vw`, position: 'fixed', zIndex: 3300, top: 0, minHeight: '120vh', left: this.state.isShowSideBar ? '0' : '-110vw'}}>
            <Sidebar
              windowWidth={this.state.windowWidth}
              toggleSideBar={this.toggleSideBar}
              isShowSideBar={this.state.isShowSideBar}
              venueList={this.props.venueList}
              activeVenueId={this.props.activeVenueId}
              activeVenue={this.props.activeVenue}
              activeUrl={this.props.currentURL}
              user={this.props.user}
              handleLogOut={this.handleLogOut}
              userPermissions={this.props.userPermissions}
            />
            </div>
          </div> : null}
          <div style={{width: '100%', minHeight: '80vh', marginTop: `${isShowHeader ? '60px' : 0}`, marginBottom: `${isShowHeader ? '150px' : 0}`, display: 'flex'}}>
            <div style={{position: 'relative', width: '100%', minHeight: '80vh'}}>
              {
              contentSegment
            }
              <Suspense fallback={<ScreenLoader />}>
            <Outlet context={{state: this.state, props: {...this.props}}} />
              </Suspense>
            </div>
          </div>
          <div style={{width: '100%', position: 'relative'}}>
            { !this.props.isCheckingForToken && isShowHeader ?
              <NewFooter/> : null
            }
          </div>
          <NotificationBox notifications={this.props.notifications}/>
        </div>

    );
  }
}

App.propTypes = {
  children: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  // app: PropTypes.object.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(state, props) {
  return {
    redirectUrl: props?.location?.query?.ref || '', // Use optional chaining here
    currentUrl: props?.location?.pathname || '', // Use optional chaining here
    isAuthenticated: getAuthenticatedState(state),
    venueList: getVenueList(state),
    venueDetails: getVenueDetail(state),
    activeVenue: getActiveVenue(state),
    activeVenueId: getActiveVenueId(state),
    isShowHeader: getShowHeaderStatus(state),
    notifications: state.notifications,
    isCheckingForToken: getCheckingTokenStatus(state),
    userId: getSessionUserId(state),
    partneraccount: getPartnerAccount(state)
  };
}

export default connect(mapStateToProps)(withRoute(App));

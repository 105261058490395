import React from 'react';
import BasicLoader from './BasicLoader';
import _ from 'lodash';

export default function ScreenLoader(props) {
  return (
    <div className="desktop-screen-loader" style={!_.isEmpty(props.style) ? props.style : {}}>
      <BasicLoader size={30} />
    </div>
  );

}

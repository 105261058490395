import React from 'react';
import standalonestyles from './standalone.module.css'

function SelectionCard ({activeButton,onClick,title,iconName,iconName1}) {

    return (
<>
    <div className={standalonestyles['Container']} style={{backgroundColor: activeButton ? "#2a52bb" : "white"}}
    onClick={()=> onClick()}
    >
   {
    activeButton ?
    <div className={standalonestyles['iconstyle']}>
    <img src={'https://vmnk.gumlet.io/Tick.svg'} className={standalonestyles['icon']} style={{backgroundColor: activeButton ? "white" : "white"}}/>
     </div> : null
   }
      
        {
            !activeButton ?
            <img src={iconName} className={standalonestyles['icon-style']} /> :
            <img src={iconName1} className={standalonestyles['icon-style']} />
        }
       
        <span className={standalonestyles['card-text']} style={{alignSelf:'center',paddingTop:"5px", color: activeButton ? "white" : "black"}}>{title}</span>
        </div>
     
     </>
    )
}


export default SelectionCard;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAuthenticatedState } from './App/AppReducer';
import { Outlet } from 'react-router';
import { withRoute } from 'util/withRoute';

class NotAuthWrapper extends Component{
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    if(this.props.isAuthenticated) {
      this.props.navigate('/dashboard')
    }
  }

  gotoDashboard = () => {
    this.props.navigate('/dashboard')
  };

  render() {
    if(this.props.isAuthenticated) {
      this.gotoDashboard();
      return null
    } else {
      return (<>
        <Outlet />
      </>)
    }
  }
}

function mapStateToProps(state, props) {
  return {
    isAuthenticated: getAuthenticatedState(state),
  };
}

export default connect(mapStateToProps)(withRoute(NotAuthWrapper));

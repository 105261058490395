import React, { Component } from "react";
import _ from "lodash";
import Pubnub from "pubnub";
import { connect } from "react-redux";
import callEventApi from "./util/eventApiCaller";

export const GLOBAL_CHANNEL = "global";

class Connector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: {},
    };
  }

  componentDidMount() {
    callEventApi(`config`, "GET")
      .then((res) => {
        if (res && res.status === "Success") {
          const credentials = {
            publishKey: res.data.pubnub_publisher_key,
            subscribeKey: res.data.pubnub_subscriber_key,
            uuid: `agent-${this.props.user._id}`,
          };
          this.setState({ credentials }, () => {
            const hereNowObject = {
              channels: [this.props.user._id, GLOBAL_CHANNEL],
              includeUUIDs: true,
              includeState: false,
            };
            const pubnub = new Pubnub(this.state.credentials);
            pubnub.subscribe({
              channels: [this.props.user._id, GLOBAL_CHANNEL],
            });
            pubnub.addListener({
              message: (msg) => {},
            });
          });
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }

  render() {
    return <div style={{ color: "green" }}></div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Connector);

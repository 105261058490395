import {Avatar, Select, Space} from 'antd'
import React, {useEffect, useState} from "react";
import {KeyboardArrowDown} from "@mui/icons-material";
import { IMAGEKIT_SOURCE_IMAGE_URL } from 'constants/constants';
import { getImageKitUrl } from 'util/utility';
import { agentPivotLeads, fetchVenuekeys, statusPivotLeads } from 'modules/App/AppActions';
import { connect, useDispatch } from 'react-redux';
import { withRoute } from 'util/withRoute';
// import {imageKitUrl} from "../../constants/constants";

const NewHeader = (props) => {

const dispatch = useDispatch();

    const createSelectOptions = (itemList) => {
        let selectOptions = []
        selectOptions = itemList.map((item, index) => {
            const imageUrl =  item.image ?`${IMAGEKIT_SOURCE_IMAGE_URL}/assets/${item.city}/${item.vm_id}/images/original/${item.image}` : `${IMAGEKIT_SOURCE_IMAGE_URL}/venue_blue.svg`
            return {
                value: item._id,
                label: item.name,
                icon: <img style={{width: '28px', height: '28px', borderRadius: '5px'}}
                           src={getImageKitUrl(imageUrl)}
                           alt="img"/>
            }
        })
        return selectOptions;
    }


    useEffect(() => {
        if(props.activeVenue._id){
            dispatch(fetchVenuekeys(props.activeVenue._id))
        }
      }, [props.activeVenue._id]);

useEffect(()=> {
    if(props.activeVenue._id) {
    dispatch(statusPivotLeads(props.activeVenue._id))
    dispatch(agentPivotLeads(props.activeVenue._id))
    }
},[props.activeVenue._id])      

    const getVenueById = (venueId) => {
        let activeVenue = props.venueDetails.filter((item, index) => (item._id === venueId))

        props.handleChangeActiveVenue(activeVenue[0])
    }

    console.log(props.activeVenue, "venueid")

    return (
        <Select
            size={'large'}
            style={{
                width: props.windowWidth > props.breakpoint ? '18vw' : '150px',
            }}
            onChange={(e) => getVenueById(e)}
            popupMatchSelectWidth={true}
            className='profileHeader'
            value={props.activeVenue._id}
            suffixIcon={<KeyboardArrowDown />}
        >
            {createSelectOptions(props.venueDetails).map((option, index) => {
                return (
                    <Select.Option key={index} value={option.value}>
                        <Space size={'small'}>
                            {option.icon}
                            <span>{option.label}</span>
                        </Space>
                    </Select.Option>
                )
            })}
        </Select>
    )
}

export default connect()(withRoute(NewHeader));
import {isEmpty} from 'lodash';
import {createSelector} from 'reselect';
import {
  FETCHING_PREVENUE,
  FETCH_PREVENUE,
  SAVING_PREVENUE,
  SAVED_PREVENUE,
  FETCHING_PREPHOTOGRAPHER,
  FETCH_PREPHOTOGRAPHER,
  SAVING_PREPHOTOGRAPHER,
  SAVED_PREPHOTOGRAPHER,
  SET_ACTIVE_TAB,
  REQUEST_LISTING,
  LISTING_SUCCESS,
  FETCHING_CUISINES,
  FETCH_CUISINES,
  LOAD_UNPAID_LEAD_PAGE,
  LOADING_UNPAID_LEAD_PAGE,
  FETCH_SUBSCRIPTION_DATA,
  FETCH_FEATURED_VENUE,
  VERIFY_OTP,
  SEND_VERIFICATION_EMAIL,
  ADD_LISTBIZ_DATA,
  OPT_VERIFIED_STATUS,
  EMAIL_VERIFICATION_STATUS_UPDATE,
  EMAIL_VERIFICATION_SENDING_STATUS_UPDATE
} from './ListYourBusinessActions';

const initialState = {
  prevenue: {},
  prephotographer: {},
  listbiz: {},
  isFetching: true,
  financeFiles: [],
  packageFiles: [],
  cuisines: [],
  isSaving: false,
  isotpSent:false,
  activeTab: 1,
  paidpartner: {},
  unpaidpartner: {},
  isLoadingUnpaidPartnerPage: false,
  isSendingVerificationEmail: false,
  isOtpVerified: false,
  isEmailVerified: false,
  isVerifyingEmail: false
};

const ListYourBusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_VERIFICATION_SENDING_STATUS_UPDATE:
      return {
        ...state,
        isVerifyingEmail: action.isVerifyingEmail
      }
    case EMAIL_VERIFICATION_STATUS_UPDATE:
      return {
        ...state,
        isEmailVerified: action.isEmailVerified
      }
    case OPT_VERIFIED_STATUS:
      return {
        ...state,
        isOtpVerified: action.isOtpVerified
      }
    case ADD_LISTBIZ_DATA:
      return {
        ...state,
        listbiz: action.listbiz
      }
    case SEND_VERIFICATION_EMAIL:
      return {
        ...state,
        isSendingVerificationEmail: action.isSendingVerificationEmail
      }
    case FETCH_SUBSCRIPTION_DATA:
      return {
        ...state,
        paidpartner: action.paidpartner,
      };

    case FETCH_FEATURED_VENUE:
      return {
        ...state,
        featuredVenue: action.featuredVenue,
      };

    case FETCHING_PREVENUE:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case FETCH_PREVENUE:
      return {
        ...state,
        isFetching: action.isFetching,
        prevenue: action.prevenue,
      };

    case SAVING_PREVENUE:
      return {
        ...state,
        isSaving: action.isSaving,
      };

    case SAVED_PREVENUE:
      return {
        ...state,
        isSaving: action.isSaving,
        prevenue: action.prevenue
      };

    case FETCHING_PREPHOTOGRAPHER:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case FETCH_PREPHOTOGRAPHER:
      return {
        ...state,
        isFetching: action.isFetching,
        prephotographer: action.prephotographer,
      };

    case SAVING_PREPHOTOGRAPHER:
      return {
        ...state,
        isSaving: action.isSaving,
      };

    case SAVED_PREPHOTOGRAPHER:
      return {
        ...state,
        isSaving: action.isSaving,
        prephotographer: action.prephotographer
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };

    case REQUEST_LISTING:
      return {
        ...state,
        isSaving: action.isSaving,
      };

    case LISTING_SUCCESS:
      return {
        ...state,
        isSaving: action.isSaving,
      };

    case FETCHING_CUISINES:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case FETCH_CUISINES:
      return {
        ...state,
        isFetching: action.isFetching,
        cuisines: action.cuisines,
      };

      case LOADING_UNPAID_LEAD_PAGE:
      return {
        ...state,
        isLoadingUnpaidPartnerPage: action.isLoadingUnpaidPartnerPage,
      };

    case LOAD_UNPAID_LEAD_PAGE:
      return {
        ...state,
        isLoadingUnpaidPartnerPage: action.isLoadingUnpaidPartnerPage,
        unpaidpartner: action.unpaidpartner,
      };
    case VERIFY_OTP:
      return{
        ...state,
        isotpSent:action.isotpSent
      }

    default:
      return state;
  }
};


export const getFetchingState = state => state.listyourbusiness.isFetching;
export const getPrevenue = state => state.listyourbusiness.prevenue;
export const getSavingState = state => state.listyourbusiness.isSaving;
export const getSubProperty = state => state.listyourbusiness.prevenue && state.listyourbusiness.prevenue.subProperties ? state.listyourbusiness.prevenue.subProperties : [];
export const getBankInfo = state => state.listyourbusiness.prevenue && state.listyourbusiness.prevenue.bankDetails ? state.listyourbusiness.prevenue.bankDetails : [];
export const getActiveTab = state => state.listyourbusiness.activeTab;
export const getCuisines = state => state.listyourbusiness.cuisines ? state.listyourbusiness.cuisines : [];
export const getPrephotographer = state => state.listyourbusiness.prephotographer;
export const getPrephotographerBankInfo = state => state.listyourbusiness.prephotographer && state.listyourbusiness.prephotographer.bankDetails ? state.listyourbusiness.prephotographer.bankDetails : [];
export const getPaidPartnerDetails = state => state.listyourbusiness.paidpartner;
export const getOtpState = state => state.listyourbusiness.isotpSent
// unpaid partner lead generation
export const getOtpVerifiedState = state => state.listyourbusiness.isOtpVerified
export const getVerifiedEmailStatus = state => state.listyourbusiness.isEmailVerified
export const getVerifyingEmailStatus = state => state.listyourbusiness.isVerifyingEmail

export const getUnpaidPartnerDetails = state => state.listyourbusiness.unpaidpartner;
export const getUnpaidPartnerPageLoadingState = state => state.listyourbusiness.isLoadingUnpaidPartnerPage;
export const getListBizData = state => state.listyourbusiness.listbiz

export default ListYourBusinessReducer;

import _, { result } from 'lodash';
import {Navigate} from 'react-router';
import {userNotify} from '../../components/Toasts/ToastAdd';
import callApi from '../../util/apiCaller';
import callIdApi from '../../util/idApiCaller';


export const FETCHING_PREVENUE = 'FETCHING_PREVENUE';
export const FETCH_PREVENUE = 'FETCH_PREVENUE';
export const SAVING_PREVENUE = 'SAVING_PREVENUE';
export const SAVED_PREVENUE = 'SAVED_PREVENUE';
export const FETCHING_PREPHOTOGRAPHER = 'FETCHING_PREPHOTOGRAPHER';
export const FETCH_PREPHOTOGRAPHER = 'FETCH_PREPHOTOGRAPHER';
export const SAVING_PREPHOTOGRAPHER = 'SAVING_PREPHOTOGRAPHER';
export const SAVED_PREPHOTOGRAPHER = 'SAVED_PREPHOTOGRAPHER';
export const VERIFY_OTP='VERIFY_OTP';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const REQUEST_LISTING = 'REQUEST_LISTING';
export const REQUEST_VERIFY_OTP = 'REQUEST_VERIFY_OTP';
export const LISTING_SUCCESS = 'LISTING_SUCCESS';
export const FETCHING_CUISINES = 'FETCHING_CUISINES';
export const FETCH_CUISINES = 'FETCH_CUISINES';
export const FETCH_FEATURED_VENUE = 'FETCH_FEATURED_VENUE';
export const FETCH_SUBSCRIPTION_DATA = 'FETCH_SUBSCRIPTION_DATA';
export const SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL'
export const ADD_LISTBIZ_DATA = 'ADD_LISTBIZ_DATA'
export const OPT_VERIFIED_STATUS = 'OTP_VERIFIED_STATUS'
export const EMAIL_VERIFICATION_STATUS_UPDATE = 'EMAIL_VERIFICATION_STATUS_UPDATE'
export const EMAIL_VERIFICATION_SENDING_STATUS_UPDATE = 'EMAIL_VERIFICATION_SENDING_STATUS_UPDATE'

// unpaid partner page
export function updateEmailVerificationStatus(status) {
  return {
    type: EMAIL_VERIFICATION_STATUS_UPDATE,
    isEmailVerified: !!status
  }
}

export function emailVerificationSendingStatus(status) {
  return {
    type: EMAIL_VERIFICATION_SENDING_STATUS_UPDATE,
    isVerifyingEmail: !!status
  }
}

export function updateSendingVerificationEmailStatus(status) {
  return {
    type: SEND_VERIFICATION_EMAIL,
    isSendingVerificationEmail: !!status
  }
}

export function sendVerificationEmail(listbiz_id) {
  try{
    return (dispatch) => {
      dispatch(updateSendingVerificationEmailStatus(true));
      return callIdApi(`listbiz/${listbiz_id}/send-email-verification`, 'get'
      ).then(res => {
        if (res.status === 'Success') {
          dispatch(updateSendingVerificationEmailStatus(false));
          if (res.data) {
            userNotify(res.data.message ? res.data.message : "Verification Email Sent", "success");
          }
        } else {
          userNotify(res.data.message ? res.data.message : 'Failed to send verification email', "warning");
        }
      });
    };
  } catch (e) {
    console.log(e)
  }

}

export const confirmVerificationEmail = (type, email, code) => async (dispatch) => {
  try {
    dispatch(emailVerificationSendingStatus(true));

    const res = await callIdApi(`${type}/confirm-email-verification?email=${email}&code=${code}`, 'get');

    dispatch(emailVerificationSendingStatus(false));

    if (res.status === 'Success') {
      dispatch(updateEmailVerificationStatus(true));

      if (res.data) {
        userNotify(res.data.message ? res.data.message : 'Token has expired or invalid', 'success');
      }
    } else {
      userNotify(res.data.message ? res.data.message : 'Failed to confirm verification', 'error');
    }
  } catch (e) {
    console.error(e);
  }
};



export function loadSubscriptionData(data) {
  return {
    type: FETCH_SUBSCRIPTION_DATA,
    paidpartner: data,
  };
}

export function fetchSubscriptionData(id) {
  return dispatch => {
    dispatch(loadSubscriptionData({
      isLoadingPage: true,
      featuredVenue: {},
      venue: {},
      contact: {},
    }));
    return callApi(`/subscription/${id}`, 'get').then(res => {
      if (res.status === 'Success') {
        if (res.data && !_.isEmpty(res.data)) {
          dispatch(loadSubscriptionData({
            isLoadingPage: true,
            featuredVenue: res.data.featuredVenue,
            venue: res.data.venue,
            contact: res.data.contact,
          }));
        } else {
          dispatch(loadSubscriptionData({
            isLoadingPage: false,
            featuredVenue: {},
            venue: {},
            contact: {},
          }));
        }
      } else {
        userNotify('Failed to load prevenue data, please try again', 'error');
      }
    });
  };
}

export function handleSubmitData(data) {
  return (dispatch) => {
    dispatch(requestListing(true));
    return callApi(`list-business/vendor`, 'post', {
      listbiz: data,
    }).then(res => {
      dispatch(requestListing(false));
      if (res.status === 'Success') {
        if (res.data && res.data.url) {
          <Navigate to={res.data.url} />
        }
      } else {
        userNotify("Something went wrong. Please try again", "warning");
      }
    });
  };
}

export const LOADING_UNPAID_LEAD_PAGE = 'LOADING_UNPAID_LEAD_PAGE';
export const LOAD_UNPAID_LEAD_PAGE = 'LOAD_UNPAID_LEAD_PAGE';

//todo: change v1 to normal apis
export function isLoadingPage() {
  return {
    type: FETCHING_PREVENUE,
    isFetching: true,
  };
}

export function loadPage(data) {
  return {
    type: FETCH_PREVENUE,
    isFetching: false,
    prevenue: data,
  };
}

export function getPrevenuePage(id) {
  return dispatch => {
    dispatch(isLoadingPage());
    return callApi(`prevenue/${id}`, 'get').then(res => {
      if (res.status === 'Success') {
        if (res.data && _.isEmpty(res.data.prevenue)) {
          dispatch(loadPage({
            isError: true
          }));
        } else {
          dispatch(loadPage(res.data.prevenue));
        }
      } else {
        dispatch(loadPage({
          isError: true
        }));
        userNotify('Failed to load prevenue data, please try again', 'error');
      }
    });
  };
}

export function isSavingPrevenue(val) {
  return {
    type: SAVING_PREVENUE,
    isSaving: !!val,
  };
}

export function loadSavedPrevenue(prevenue) {
  return {
    type: SAVED_PREVENUE,
    isSaving: false,
    prevenue: prevenue
  };
}

export function setActiveTabValue(val) {
  return {
    type: SET_ACTIVE_TAB,
    activeTab: val
  };
}

export function updatePrevenue(id, data) {
  return (dispatch) => {
    dispatch(isSavingPrevenue(true));
    return callApi(`prevenue/${id}/update`, 'post', {
      prevenue: data
    })
      .then(res => {
        if (res.status === 'Success') {
          dispatch(isSavingPrevenue(false));
          dispatch(loadSavedPrevenue(res.data.prevenue));
        } else {
          dispatch(isSavingPrevenue(false));
        }
      });
  };
}

export function addSubPropertyToPrevenue(id, subVenue) {
  return (dispatch) => {
    dispatch(isSavingPrevenue(true));
    callApi(`prevenue/${id}/add/subvenue`, 'post', {
      subVenue: subVenue,
    }).then(res => {
      dispatch(loadSavedPrevenue(res.data.prevenue));
      userNotify('Sub-Property added', 'success');
    });
  };
}

export function updateSubPropertyInPrevenue(id, subid, subVenue) {
  return (dispatch) => {
    dispatch(isSavingPrevenue(true));
    callApi(`prevenue/${id}/update/subvenue/${subid}`, 'post', {
      subVenue: subVenue,
    }).then(res => {
      dispatch(loadSavedPrevenue(res.data.prevenue));
      userNotify('Sub-Property updated', 'success');
    });
  };
}

export function deleteSubVenueFromPrevenue(id, subid) {
  return (dispatch) => {
    dispatch(isSavingPrevenue(true));
    callApi(`prevenue/${id}/delete/subvenue/${subid}`, 'post')
      .then(res => {
        dispatch(loadSavedPrevenue(res.data.prevenue));
        userNotify('Sub-Property deleted', 'success');
      });
  };
}

export function addBankToPrevenue(id, newBank) {
  return (dispatch) => {
    dispatch(isSavingPrevenue(true));
    callApi(`prevenue/${id}/add/bank`, 'post', {
      newBank: newBank,
    }).then(res => {
      dispatch(loadSavedPrevenue(res.data.prevenue));
      userNotify('Bank added', 'success');
    });
  };
}

export function deleteBankFromPrevenue(id, bankid) {
  return (dispatch) => {
    dispatch(isSavingPrevenue(true));
    callApi(`prevenue/${id}/delete/bank/${bankid}`, 'get')
      .then(res => {
        dispatch(loadSavedPrevenue(res.data.prevenue));
        userNotify('Bank deleted', 'success');
      });
  };
}

export function updateBankInPrevenue(id, bankid, bankDetail) {
  return (dispatch) => {
    dispatch(isSavingPrevenue(true));
    callApi(`prevenue/${id}/update/bank/${bankid}`, 'post', {
      bank: bankDetail,
    }).then(res => {
      dispatch(loadSavedPrevenue(res.data.prevenue));
      userNotify('Bank updated', 'success');
    });
  };
}

export function getPrephotographerPage(id) {
  return dispatch => {
    dispatch(isLoadingPrephotographerPage());
    return callApi(`prephotographer/${id}`, 'get').then(res => {
      if (res.status === 'Success') {
        if (res.data && _.isEmpty(res.data.prephotographer)) {
          dispatch(loadPrephotographerPage({
            isError: true
          }));
        } else {
          dispatch(loadPrephotographerPage(res.data.prephotographer));
        }
      } else {
        dispatch(loadPrephotographerPage({
          isError: true
        }));
        userNotify('Failed to load prephotographer data, please try again', 'error');
      }
    });
  };
}

export function isLoadingPrephotographerPage() {
  return {
    type: FETCHING_PREPHOTOGRAPHER,
    isFetching: true,
  };
}

export function loadPrephotographerPage(data) {
  return {
    type: FETCH_PREPHOTOGRAPHER,
    isFetching: false,
    prephotographer: data,
  };
}

export function isSavingPrephotographer(val) {
  return {
    type: SAVING_PREPHOTOGRAPHER,
    isSaving: !!val,
  };
}

export function loadSavedPrephotographer(prephotographer) {
  return {
    type: SAVED_PREPHOTOGRAPHER,
    isSaving: false,
    prephotographer: prephotographer
  };
}

export function verifyotp(status) {
  return {
    type: VERIFY_OTP,
    isotpSent: !!status,
  };
}

export function updatePrephotographer(id, data) {
  return (dispatch) => {
    dispatch(isSavingPrephotographer(true));
    return callApi(`prephotographer/${id}/update`, 'post', {
      prephotographer: data
    })
      .then(res => {
        if (res.status === 'Success') {
          dispatch(isSavingPrephotographer(false));
          dispatch(loadSavedPrephotographer(res.data.prephotographer));
        } else {
          dispatch(isSavingPrephotographer(false));
        }
      });
  };
}

export function addBankToPrephotographer(id, newBank) {
  return (dispatch) => {
    dispatch(isSavingPrephotographer(true));
    callApi(`prephotographer/${id}/add/bank`, 'post', {
      newBank: newBank,
    }).then(res => {
      dispatch(loadSavedPrephotographer(res.data.prephotographer));
      userNotify('Bank added', 'success');
    });
  };
}

export function deleteBankFromPrephotographer(id, bankid) {
  return (dispatch) => {
    dispatch(isSavingPrephotographer(true));
    callApi(`prephotographer/${id}/delete/bank/${bankid}`, 'get')
      .then(res => {
        dispatch(loadSavedPrephotographer(res.data.prephotographer));
        userNotify('Bank deleted', 'success');
      });
  };
}

export function updateBankInPrephotographer(id, bankid, bankDetail) {
  return (dispatch) => {
    dispatch(isSavingPrephotographer(true));
    callApi(`prephotographer/${id}/update/bank/${bankid}`, 'post', {
      bank: bankDetail,
    }).then(res => {
      dispatch(loadSavedPrephotographer(res.data.prephotographer));
      userNotify('Bank updated', 'success');
    });
  };
}

export function sendSuccessResponse() {
  return {
    type: LISTING_SUCCESS,
    isSaving: false,
  };
}

export function requestListing() {
  return {
    type: REQUEST_LISTING,
    isSaving: true,
  };
}

export function requestVerifyOTP() {
  return {
    type: REQUEST_VERIFY_OTP,
    isSaving: true,
  };
}

export function handleListing(data) {
  return (dispatch) => {
    dispatch(requestListing());
    return callApi(`partner/list-business`, 'post', {
      bizlead: data,
    }).then(res => {
      dispatch(sendSuccessResponse());
      if (res.status === 'Success') {
        if (res.data) {
        dispatch(verifyotp(res.data));
        }
        else {
          userNotify("Some internal server error while sign up", "warning");
        }
      } else {
        userNotify("Something went wrong. Please try again", "warning");
      }
    });
  };
}

export function addBizData (data) {
  return {
    type: ADD_LISTBIZ_DATA,
    listbiz: data
  }
}

export function handleBusinessListing(data) {
  return (dispatch) => {
    dispatch(updateSendingVerificationEmailStatus(false));
    // dispatch(requestListing());
    return callIdApi(`listbiz/new`, 'post', data
    ).then(res => {
      if (res.status === 'Success') {
        if (res.data) {
            if(res.data.listbiz) {
              dispatch(addBizData(res.data.listbiz))
              dispatch(sendVerificationEmail(res.data.listbiz._id));
              dispatch(handleRequestOtp(res.data.listbiz._id));
            }
        }
        else {
            userNotify(res.data.message ? res.data.message : 'Failed to list your business', "warning");
        }
      } else {
            userNotify(res.data.message ? res.data.message : 'Failed to list your business', "warning");
      }
    });
  };
}

export function handleRequestOtp (listbiz_id) {
  return (dispatch) => {
    dispatch(verifyotp(true))
    return callIdApi(`listbiz/${listbiz_id}/request-otp`, 'get').then(res => {
      if (res.status === 'Success') {
        if (res.data) {
          userNotify(res.data.message ? res.data.message : 'OTP sent on your mobile number', 'success')
        }
        else {
          userNotify("Some internal server error while sending otp", "warning");
        }
      } else {
        userNotify("Something went wrong. Please try again", "error");
      }
    });
  };
}

export function updateIsOtpVerifiedState (status) {
  return {
    type: OPT_VERIFIED_STATUS,
    isOtpVerified: !!status
  }
}

export function handleVerifyOtp(listbiz_id, code) {
  return (dispatch) => {
    dispatch(requestVerifyOTP());
    return callIdApi(`listbiz/${listbiz_id}/verify-otp?code=${code}`, 'get').then(res => {
      // dispatch(sendSuccessResponse());
      if (res.status === 'Success') {
        if (res.data) {
          dispatch(verifyotp(false));
          dispatch(updateIsOtpVerifiedState(true))
          userNotify(res.data.message ? res.data.message : 'Phone number verified', 'success')
        // dispatch(otpVerified(res.data));
        }
        else {
          userNotify("Some internal server error while verifying otp", "warning");
        }
      } else {
        if(res.data) {
          userNotify(res.data.message ? res.data.message : "OTP has expired or invalid", "error");
        }
        userNotify("Something went wrong. Please try again", "error");
      }
    });
  };
}

export function isLoadingCuisines() {
  return {
    type: FETCHING_CUISINES,
    isFetching: true,
  };
}

export function loadCuisines(data) {
  return {
    type: FETCH_CUISINES,
    isFetching: false,
    cuisines : data
  };
}

export function getCuisinesForPrevenue() {
  return dispatch => {
    dispatch(isLoadingCuisines());
    return callApi(`cuisines/list`, 'post').then(res => {
      if (res.status === 'Success') {
        dispatch(loadCuisines(res.data.cuisines));
      } else {
        dispatch(loadCuisines({
          isError: true
        }));
        userNotify('Failed to load prevenue cusines data, please try again', 'error');
      }
    });
  };
}


export function isLoadingUnpaidPartnerPage(val) {
  return {
    type: LOADING_UNPAID_LEAD_PAGE,
    isLoadingUnpaidPartnerPage: !!val,
  };
}

export function loadUnpaidPartnerPage(data) {
  return {
    type: LOAD_UNPAID_LEAD_PAGE,
    isLoadingUnpaidPartnerPage: false,
    unpaidpartner: data,
  };
}

export function fetchUnpaidPartnerInfo(venueid) {
  return dispatch => {
    dispatch(isLoadingUnpaidPartnerPage(true));
    return callApi(`paid-partner-venue/${venueid}/info`, 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadUnpaidPartnerPage(res.data));
      } else {
        dispatch(isLoadingUnpaidPartnerPage(false));
        userNotify('Failed to load prevenue cusines data, please try again', 'error');
      }
    });
  };
}

export function generateLeadForUnpaidPartner(lead) {
  return dispatch => {
    dispatch(isSavingPrevenue(true));
    return callApi(`paid-partner-venue/new-lead`, 'post', {
      lead: lead
    }).then(res => {
      if (res.status === 'Success') {
        userNotify('Thanks, We will reach you soon!', 'error');
        dispatch(isSavingPrevenue(false));
      } else {
        dispatch(isSavingPrevenue(false));
        userNotify('Failed, please try again', 'error');
      }
    });
  };
}

// export function handleStageCompletion(stage) {
//     switch (stage) {
//       case stageOneCompleted:
//         return {
//           type: STAGE_ONE_COMPLETION,
//           prevenue.isStageOneComplete : true,
//         }
//     }
// }

// export function uploadFileToPrevenue(data, next) {
//   if (data.s3_url) {
//     callApi('vassets/venue/new', 'post', {
//       url: data.s3_url,
//       filetype: data.filetype
//     }).then(res => {
//       const xhr = new XMLHttpRequest();
//       xhr.open("PUT", res.signed_request);
//       xhr.setRequestHeader('x-amz-acl', 'public-read');
//       xhr.onreadystatechange (e){
//         if (xhr.readyState === 4) {
//           if (xhr.status === 200) {
//             return next(true);
//           } else {
//             return next(null);
//           }
//         }
//       };
//       xhr.send(data.file);
//     });
//   }
// }
//
// export function deleteFile(url) {
//   callApi('prevenue/delete', 'post', {
//     url: url,
//   }).then(res => {
//     // console.log(res);
//     this.refreshFileList();
//   });
// }
//
// export function fetchVenueImagesFromS3() {
//   callApi(`prevenue/${this.state.prevenueId}/list/images`, 'get')
//     .then(res => {
//       let imageFiles = res.data;
//       let prevenue = this.state.prevenue;
//       prevenue.imageFiles = imageFiles;
//       this.setState({
//         prevenue: prevenue
//       });
//     });
// }
// export function fetchVenuePackagesFromS3() {
//   callApi(`prevenue/${this.state.prevenueId}/list/packages`, 'get')
//     .then(res => {
//       let packageFiles = res.data;
//       let prevenue = this.state.prevenue;
//       prevenue.packageFiles = packageFiles;
//       this.setState({
//         prevenue: prevenue,
//       });
//     });
// }
// export function fetchVenueFinancesFromS3() {
//   callApi(`prevenue/${this.state.prevenueId}/list/finances`, 'get')
//     .then(res => {
//       let financeFiles = res.data;
//       let prevenue = this.state.prevenue;
//       prevenue.financeFiles = financeFiles;
//       this.setState({
//         prevenue: prevenue,
//       });
//     });
// }
//
//
//


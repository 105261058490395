import {
  Drawer,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Radio,
  Space,
  Spin,
} from "antd";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import _ from "lodash";

import { useState } from "react";
import { SLOT_OPTIONS, STATUS_OPTIONS } from "constants/venueCalendarEnums";

export default function AddEventForm(props) {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [isAdding, setIsAdding] = useState(false);



  useEffect(() => {
    if (!_.isEmpty(props.selectInfo)) {

      form.setFieldsValue({
        date: [dayjs(props.selectInfo.start), dayjs(props.selectInfo.end)],
      });
    }
  }, [props.selectInfo]);

  const onClose = () => {
    props.resetSelectInfo();
    props.setAddDrawerVisible(false);
  };

  const onFinish = () => {

    form.validateFields().then(() => {
    setIsAdding(true);
      let value = form.getFieldsValue();
      let payload = {};
      payload.start_date = value.date[0].startOf('day').toISOString();
      payload.end_date = value.date[1].startOf('day').toISOString();
      payload.time_slot = value.slot;
      payload.time_slot_status = value.status;
      payload.venuespaces = value.venuespaces
        ? value.venuespaces
        : [props.venue._id];
      props.handleEventAdd(payload).then(() => {
        setIsAdding(false);
        onClose();
      }).catch(()=>{
        setIsAdding(false)
      });
    });
  };

  const dateFieldValidator = () => {
    let date = form.getFieldValue("date");
    if (date[0] && date[1]) {
      let diff = dayjs(date[1]).diff(dayjs(date[0]), "days");
      if (diff <= 0) {
        return Promise.reject("Date Start cannot be Less than or equal");
      } else return Promise.resolve();
    } else {
      return Promise.reject("Date start and end is required");
    }
  };

  const venueSpaceValidator = () => {
    let venueSpaces = form.getFieldValue("venuespaces");
    if (props.venueSpacesList.length <= 0) {
      return Promise.resolve();
    } else {
      if (venueSpaces && !_.isEmpty(venueSpaces)) {
        return Promise.resolve();
      } else return Promise.reject("VenueSpace Cannot be empty");
    }
  };

  return (

      <Drawer
        title="Add Event"
        placement="right"
        onClose={() => onClose()}
        open={props.open}
        width={400}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button type="primary" onClick={onClose} disabled={isAdding}>
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isAdding}
              onClick={onFinish}
            >
              Ok
            </Button>
          </div>
        }
      >
        <Spin spinning={isAdding}>
          <Form form={form} name="eventForm" layout="vertical">
            <Form.Item
              name="date"
              label={<b>From-To</b>}
              rules={[
                {
                  validator: dateFieldValidator,
                },
              ]}
            >
              <RangePicker format={"DD/MM/YYYY"}></RangePicker>
            </Form.Item>
            <Form.Item
              name="slot"
              label={<b>Slot</b>}
              rules={[
                { required: true, message: "Slot needed to be selected" },
              ]}
            >
              <Select size={"large"} style={{ width: "140px" }} mode="multiple"  dropdownStyle={{zIndex:"2500"}}>
                {SLOT_OPTIONS.map((item) => (
                  <Select.Option value={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="venuespaces"
              label={<b>Venue spaces</b>}
              rules={[{ validator: venueSpaceValidator }]}
            >
              <Select
               showSearch
               dropdownStyle={{zIndex:"2500"}}
                style={{ width: "150px" }}
                mode="multiple"
                disabled={props.venueSpacesList.length <= 0}
              >
                {props.venueSpacesList.map((item) => (
                  <Select.Option value={item._id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="status"
              rules={[{ required: true, message: "Status Cannot be empty" }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  {STATUS_OPTIONS.map((item)=>(
                  <Radio value={item.value}>{item.label}</Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
  );
}

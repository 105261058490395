import React, { useState, useEffect } from 'react';
import SelectionCard from './SelectionCard';
import standalonestyles from './standalone.module.css';
import { Link } from 'react-router-dom';
import { selectedCardType } from 'modules/App/AppActions';
import { useDispatch } from 'react-redux';

function VenueVendorsSelectionPage() {
    const [activeButton, setActiveButton] = useState('');
    const [selectedVenue, setSelectedVenue] = useState('');
    const [width, setWidth] = useState(0);

    const dispatch = useDispatch()

    const venuesstandalone = [
        { name: "venues", icon: "https://vmnk.gumlet.io/Venueimg.svg", icon2: "https://vmnk.gumlet.io/venuewhite.svg" },
        { name: "vendors", icon: "https://vmnk.gumlet.io/Vendorimg.svg", icon2: "https://vmnk.gumlet.io/vendorwhite.svg" },
    ];

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
    };

    const handleCardClick = (venueName) => {
        setActiveButton(venueName);  
        setSelectedVenue(venueName)
        dispatch(selectedCardType(venueName));
        console.log(`Selected: ${venueName}`); 
    };

    return (
        <div className={standalonestyles['standalonetype-container']}>
            <div className={standalonestyles['desktop-container']}>
                <div className={standalonestyles['selection-container']}>
                    <span className={standalonestyles['standalone-header']}>Select One</span>
                    <div className={standalonestyles['cardcontainer']}>
                        {venuesstandalone.map((venue) => (
                            <SelectionCard
                                key={venue.name}
                                onClick={() => handleCardClick(venue.name)}
                                activeButton={activeButton === venue.name}
                                title={venue.name}
                                iconName={venue.icon}
                                iconName1={venue.icon2}
                            />
                        ))}
                    </div>
                    <>
                        {width < 768 ? (
                            <div className={standalonestyles['button-container']}>
                                <button
                                    className={standalonestyles['next-btn']}
                                    style={{
                                        backgroundColor: activeButton !== '' ? '#592b7e' : '#E7E7E7',
                                    }}
                                    disabled={!selectedVenue}
                                    onClick={() => console.log('Next button clicked')}
                                >
                                    <span className={standalonestyles['btn-text']} style={{ color: activeButton !== '' ? 'white' : '#6F6F6F' }}>NEXT</span>
                                </button>
                            </div>
                        ) : null}
                        <div className={standalonestyles['desktop-button']}>
                            {width > 768 ? (
                                <div className={standalonestyles['button-container']}>
                                     <Link to="/list-your-business">
                                    <button
                                        className={standalonestyles['next-btn']}
                                        style={{
                                            backgroundColor: activeButton !== '' ? '#2a52bb' : '#fff',
                                        }}
                                        disabled={!selectedVenue}
                                    >
                                        <span className={standalonestyles['btn-text']} style={{ color: activeButton !== '' ? 'white' : '#2a52bb' }}>NEXT</span>
                                    </button>
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
}

export default VenueVendorsSelectionPage;

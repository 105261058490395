import callApi from "../../util/apiCaller";
import callEventApi from "../../util/eventApiCaller";
import { userNotify } from '../../components/Toasts/ToastAdd';
import { fetchLeadDetails, loadLeadDetails, updateFetchingLeadStatus } from "../LeadManage/LeadManageActions";

export const GET_LEAD_LIST = 'GET_LEAD_LIST';
export const LEAD_LIST_STATUS = 'LEAD_LIST_STATUS';
export const LOAD_BASE_FILTERS = 'LOAD_BASE_FILTERS';
export const UPDATING_LEAD_STATUS = 'UPDATING_LEAD_STATUS';
export const UPDATE_ACCEPTING_STATUS = 'UPDATE_ACCEPTING_STATUS';
export const UPDATE_LEAD_TO_LIST = 'UPDATE_LEAD_TO_LIST';
export const UPDATE_REJECTING_STATUS = 'UPDATE_REJECTING_STATUS';
export const DELETE_REJECTED_LEAD = 'DELETE_REJECTED_LEAD';
export const UPDATING_CALL_LEAD_STATUS = "UPDATING_CALL_LEAD_STATUS"

export function fetchLeadListStatus(status) {
  return {
    type: LEAD_LIST_STATUS,
    isFetching: status
  }
}


export function handleDialer(venuemember, contact_phone, venuebundleid) {
  return async (dispatch) => {
    if (venuemember.is_teleforce_enabled) {
      let payload = {}
      payload.agent = venuemember
      payload.venue_id = venuemember.venueId
      payload.contact_phone = contact_phone
      payload.ref_id = venuebundleid
      payload.ref_name = "venuebundle"
      let res = await callEventApi('api/dialer/partner-call-via-teleforce', 'POST', payload)
      if (res.status === 'Success') {
        userNotify('Call Initiated Successfully', 'success')
      } else {
        userNotify('Error while initating call', 'error')
      }

    } else {
      window.open(`tel:${contact_phone}`);
    }

  }
}

export function loadLeadList(data, pageNum) {
  return {
    type: GET_LEAD_LIST,
    list: data.leads,
    count: data.totalCount,
    pageNum: pageNum,
    isFetching: false,
    monthLeadCount: data.monthLeadCount
  };
}

export function fetchLeadList(venueId, filters) {
  return (dispatch) => {
    dispatch(fetchLeadListStatus(true));
    return callEventApi(`api/v3/partner-dashboard/leads/venue/${venueId}/lead-list`, 'POST', { filters: filters }).then(
      (res) => {
        if (res.status === 'Success') {
          dispatch(loadLeadList(res.data, filters.pageNum));
        } else {
          dispatch(fetchLeadListStatus(false));
        }
      }).catch((err) => { });
  };
}

export function loadBaseFilters(filters) {
  return {
    type: LOAD_BASE_FILTERS,
    leadBaseFilters: filters.baseFilters,
    leadSortFilters: filters.sortFilters,
  };
}


export function fetchBaseFilters() {
  return (dispatch) => {
    return callEventApi('api/v3/partner-dashboard/leads/base-filters', 'get').then(res => {
      if (res.status === 'Success') {
        dispatch(loadBaseFilters(res.data));
      }
    });
  };
}

export function updateLeadToList(data) {
  return {
    type: UPDATE_LEAD_TO_LIST,
    lead: data.lead,
    isUpdatingLead: false,
    isAcceptingLead: false,
    isRejectingLead: false,
  };
}

export function updatingLeadStatus(leadId, status) {
  return {
    type: UPDATING_LEAD_STATUS,
    leadId: leadId,
    isUpdatingLead: !!status,
  };
}

export function updatingLeadCallStatus(leadId, status) {
  return {
    type: UPDATING_CALL_LEAD_STATUS,
    leadId: leadId,
    isUpdatingCallStatus: !!status,
  };
}

export function updateAcceptingStatus(leadId, status) {
  return {
    type: UPDATE_ACCEPTING_STATUS,
    isAcceptingLead: !!status,
    leadId: leadId,
  };
}

export function updateRejectingStatus(leadId, status) {
  return {
    type: UPDATE_REJECTING_STATUS,
    isRejectingLead: !!status,
    leadId: leadId,
  };
}

export function updateLeadStatus(leadId, lead) {
  return (dispatch) => {
    dispatch(updatingLeadStatus(leadId, true));
    return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/update`, 'post', { lead: lead }).then(res => {
      if (res.status === 'Success') {
        userNotify('Lead Status Updated', 'success')
        dispatch(updateLeadToList(res.data));
      } else {
        dispatch(updatingLeadStatus(leadId, false));
      }
    });
  };
}

export function updateLastCallStatus(leadId, lead, venueId,filters) {
  return async (dispatch) => {
    let updateRes = await callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/update-agent-active-call-status-follow-up-date`, 'post', { lead: lead })
    if (updateRes.status == "Success") {
      dispatch(fetchLeadDetails(leadId))
      dispatch(fetchLeadList(venueId,filters))
    } else {
      userNotify("Updated Sucesss", "success")
    }
  };
}

export function acceptAvailabilityForEvent(agent, leadId, lead) {
  return (dispatch) => {
    dispatch(updateAcceptingStatus(leadId, true));
    return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/initial-rejection`, 'post', {
      agent: agent,
      lead: lead
    }).then(res => {
      if (res.status === 'Success') {
        userNotify('Lead Accepted', 'success')
        dispatch(updateLeadToList(res.data));
        dispatch(updateAcceptingStatus(leadId, false));
        // window.location.reload();
      } else {
        userNotify('Failed! try again', 'error')
        dispatch(updateAcceptingStatus(leadId, false));
      }
    });
  };
}

export function deleteRejectedLead(data) {
  return {
    type: DELETE_REJECTED_LEAD,
    leadId: data.lead._id,
    isRejectingLead: false,
  };
}

export function rejectAvailabilityForEvent(agent, leadId, lead) {
  return (dispatch) => {
    dispatch(updateRejectingStatus(leadId, true));
    return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/initial-rejection`, 'post', {
      agent: agent,
      lead: lead
    }).then(res => {
      if (res.status === 'Success') {
        userNotify('Lead Status Updated', 'success')
        dispatch(deleteRejectedLead(res.data));
      } else {
        dispatch(updateRejectingStatus(leadId, false));
      }
    });
  };
}

// export function updateLastCallStatus(leadId, lead) {
//   return (dispatch) => {
//     // dispatch(updateAcceptingStatus(leadId,true));
//     return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/call-status-update`, 'post', {lead: lead}).then(res => {
//       if(res.status === 'Success') {
//         userNotify('Call Status Updated', 'success')
//         dispatch(updateLeadToList(res.data));
//       } else {
//         userNotify('Failed! try again', 'error')
//         // dispatch(updateAcceptingStatus(leadId,false));
//       }
//     });
//   };
// }

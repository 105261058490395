import React,{Component} from 'react';

const faqs = [
  {
    ques: 'What is Venuemonk',
    ans: 'Venuemonk is event Planning company.'
  },
  {
    ques: 'What is Venuemonk',
    ans: 'Venuemonk is event Planning company.'
  },
  {
    ques: 'What is Venuemonk',
    ans: 'Venuemonk is event Planning company.'
  }
];
class FAQ extends Component{
  constructor (props) {
    super(props);
    this.state = {
      faqs: []
    };
  }

  componentDidMount() {
    this.handlePrepareFAQs();
  }

  handlePrepareFAQs = () => {
    let FAQs = faqs.map((item, index) => {
      if(index === 0) {
        item.isShow = true;
      } else {
        item.isShow = false;
      }
      return item;
    });
    this.setState({
      faqs: FAQs
    });
  };

  handleToggleItem = (index) => {
    let FAQs = this.state.faqs;
    FAQs = FAQs.map((item, indx) => {
      if(indx === index) {
        item.isShow = !item.isShow;
      }
      return item;
    });
    this.setState({
      faqs: FAQs
    });
  };

  render(){
    return(
      <div style={{position: 'relative', marginTop: '20px', marginBottom: '20px', width: '100%'}}>
        <div style={{fontSize: '24px'}}>{`Frequently Asked Questions`}</div>
        <div style={{marginTop: '30px'}}>
          {
            this.state.faqs && this.state.faqs.length > 0 && this.state.faqs.map((item, index) => (
              <div onClick={() => this.handleToggleItem(index)} style={{padding: '10px', cursor: 'pointer', color: '#353535', backgroundColor: '#fff', borderRadius: '3px', border: '1px solid #E2E2E2', marginBottom: '15px'}}>
                <div>
                  <span>{`Ques: ${item.ques}`}</span>
                  <span className="pull-right"><i className={`fa ${item.isShow ? 'fa-angle-up' : 'fa-angle-down'}`}></i></span>
                </div>
                {
                  item.isShow ?
                  <div style={{marginTop: '15px', fontSize: '18px'}}>
                    {`Ans: ${item.ans}`}
                  </div> : null
                }
              </div>
            ))
          }
        </div>
      </div>
    );
  }

}
export default FAQ;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import dayjs from "dayjs";
import _ from "lodash";
import { withRoute } from "util/withRoute";
import CustomCard, { CardBody, CardHeader, } from "../../../../components/CardComponent/CustomCard";
import { Button } from "react-bootstrap";
import { DatePicker, Drawer, Input, Modal, Radio, Select } from "antd";

import {
    getActiveVenueId,
    getLeadClosedLostReasons,
    getLeadManageParentPath,
    getLeadManageStaticContent,
    getLeadStatusList,
    getPartnerAccount,
    getUserAgent,
} from "../../../App/AppReducer";
import {
    addVenuebundleNotesByVenuebundleId,
    fetchLeadDetails,
    fetchLeadLogsByLeadId,
    fetchVenuebundleNotesByVenuebundleId,
    removeVenuebundleNotesByVenuebundleId,
    saveVenueNote,
    showFullViewTabVisibleState,
    updateLead,
    updateLeadStatusToClosedLost,
    updateLeadStatusToClosedWon,
    updateOtherLeadStatus,
    updateVenuebundleNotesByVenuebundleId,
} from "../../LeadManageActions";
import {
    getAddingVenueBundleNote,
    getEditingVenueBundleNote,
    getFullViewTabVisiblestate,
    getLeadDetails,
    getLeadLogId,
    getLeadLogs,
    getLeadUpdatingStatus,
    getSelectedLeadId,
    getVenueBundleNotes,
} from "../../LeadManageReducer";
import NavBar from "../../components/NavBar";
import Breadcrumb from "../../components/Beadcrumb";
import { userNotify } from "../../../../components/Toasts/ToastAdd";
import { fetchClosedLostReasonList, fetchLeadStatusList, } from "../../../App/AppActions";
import { getLeadPageNum, getRejectingLeadStatus } from "../../../Lead/LeadReducer";
import RejectLeadForm from "../../components/RejectLeadForm";
import CloseWonLeadForm from "../../components/CloseWonLeadForm";
import MobileNavigationBar from "../../components/MobileNavigationBar";
import { getPartnerAccountObjectMap } from "../../../Member/MemberReducer";
import { Helmet } from "react-helmet";
import PageAccessControl from "../../../../components/PermissionLocks/PageAccessControl";
import SwitchControl from "../../../../components/PermissionLocks/SwitchControl";
import { fetchLeadList, updateLastCallStatus } from "modules/Lead/LeadActions";
import '../../../App/App.css'
import { Label } from "recharts";
import moment from "moment";
import { callingStatusList } from "constants/constants";
import CallStatus from "./CallStatus";

class BasicDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lead: {},
            venueNote: "",
            status: "",
            isShowRejectLeadForm: false,
            isShowClosedWonLeadForm: false,
            wonLeadId: "",
            rejectedLeadId: "",
            isEditGuest: false,
            isEditEventDate: false,
            isEditBudget: false,
            isEditProspect: false,
            isNoteDrawerVisible: false,
            noteDrawerType: "Add Note",
            activeNote: {},
            notes: [],
            activeNoteIndex: "",
            isDeletingNote: false,
            venuebundlenotes: [],
            isEditingVenueBundleNote: false,
            editRawEventDate: false,
            followpDate: '',
            appliedFilters: {
                datetype: 'event_passed_at',
                pageNum: this.props.pageNum,
                pageSize: 25,
                sortBy: '',
                status: '',
                isFiltering: true,
                isSearching: false
            },
            lastCallStatus: '',
            followupDate: null
        };
    }

    componentDidMount() {
        let leadId = this.props?.leadid ? this.props?.leadid : this.props.params.leadId
        if (leadId && leadId !== "") {
            this.props.dispatch(fetchLeadDetails(leadId));
            this.props.dispatch(fetchLeadLogsByLeadId(leadId));
        }

        if (_.isEmpty(this.state.lead)) {
            this.setState({
                lead: this.props.lead,
            });
        }

        this.props.dispatch(fetchLeadStatusList(leadId));
        this.props.dispatch(fetchClosedLostReasonList(leadId));
        this.props.dispatch(
            fetchVenuebundleNotesByVenuebundleId(leadId ? leadId : "", {})
        );

        if (
            this.props.venuebundlenotes &&
            !_.isEmpty(this.props.venuebundlenotes)
        ) {
            this.setState({
                venuebundlenotes: this.props.venuebundlenotes,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let leadId = this.props.leadid;

        if (!_.isEqual(leadId, prevProps.leadid)) {
            this.props.dispatch(fetchLeadDetails(leadId));
            this.props.dispatch(fetchVenuebundleNotesByVenuebundleId(leadId));
        }
        if (
            !_.isEqual(
                prevProps.venuebundlenotes && prevProps.venuebundlenotes.length,
                this.props.venuebundlenotes && this.props.venuebundlenotes.length
            )
        ) {
            this.setState({
                venuebundlenotes: this.props.venuebundlenotes,
            });
        }
        if (
            !_.isEqual(prevProps.lead, this.props.lead) ||
            !_.isEqual(prevState.lead, this.state.lead)
        ) {
            this.setState({
                lead: this.props.lead,
            });
        }
        if (
            !_.isEqual(
                prevProps.isEditingVenueBundleNote,
                this.props.isEditingVenueBundleNote
            )
        ) {
            this.setState({
                isEditingVenueBundleNote: this.props.isEditingVenueBundleNote,
                venuebundlenotes: this.props.venuebundlenotes,
            });
        }
    }

    handleChangeStatus = (status) => {
        let newLead = { ...this.state.lead };
        newLead.status = status;
        this.setState(
            {
                lead: newLead,
            },
            () => {
                if (
                    status &&
                    status !== "" &&
                    (this.props.leadid || this.props.leadid || this.props.params.leadId) &&
                    this.props.leadid !== ""
                ) {
                    this.handleUpdateStatus(status);
                }
            }
        );
    };

    // todo raghav
    handleUpdateStatus = (status) => {
        let lead = {};
        lead.status = status;
        let agent = this.props.agent;
        let logId = this.props.logId;
        let newLead = { ...this.state.lead };
        newLead.status = status;
        this.setState({
            lead: newLead,
        });
        if (status === "Closed - Won") {
            // this.props.dispatch(updateLeadStatusToClosedWon(this.props.leadid, lead, agent, logId));
            this.handleOpenWonLeadForm(
                this.state?.lead?._id ? this.state?.lead?._id : this.props.leadid || this.props.params.leadId
            );
        } else if (status === "Closed - Lost") {
            this.handleOpenRejectLeadForm(
                this.state?.lead?._id ? this.state?.lead?._id : this.props.leadid || this.props.params.leadId
            );
            // this.props.dispatch(updateLeadStatusToClosedLost(this.props.leadid, lead, agent, logId));
        } else {
            this.props.dispatch(
                updateOtherLeadStatus(
                    this.state?.lead?._id
                        ? this.state?.lead?._id
                        : this.props.leadid || this.props.params.leadId,
                    lead,
                    agent,
                    logId
                )
            );
        }
    };

    handleSaveVenueNote = () => {
        let note = this.state.venueNote;
        let lead = {};
        lead.venueNote = note;
        if (lead.venueNote && lead.venueNote !== "") {
            this.props.dispatch(
                saveVenueNote(this.props.lead._id || this.props.leadid, lead)
            );
        } else {
            userNotify("Please add some Note!", "warning");
        }
    };

    handleChangeNote = (val) => {
        this.setState({ venueNote: val });
    };

    handleRejectAvailability = (leadId, lead) => {
        let agent = this.props.agent;
        let logId = this.props.logId;
        if (leadId) {
            this.props.dispatch(
                updateLeadStatusToClosedLost(leadId, lead, agent, logId)
            );
            this.handleCloseRejectLeadForm();
            this.props.navigate("/lead/list");
            // this.props.dispatch(rejectAvailabilityForEvent(leadId, lead));
        }
    };

    handleCloseWonEvent = (leadId, lead) => {
        let agent = this.props.agent;
        let logId = this.props.logId;
        if (leadId) {
            this.props.dispatch(
                updateLeadStatusToClosedWon(
                    this.props.leadid,
                    lead,
                    agent,
                    logId
                )
            );
            this.handleCloseWonLeadForm();
            // this.props.dispatch(rejectAvailabilityForEvent(leadId, lead));
        }
    };

    showMessage = (msg, msgtype) => {
        userNotify(msg, msgtype);
    };

    handleCloseRejectLeadForm = () => {
        this.setState({ isShowRejectLeadForm: false, rejectedLeadId: "" });
    };

    handleOpenRejectLeadForm = (leadId) => {
        this.setState({ isShowRejectLeadForm: true, rejectedLeadId: leadId });
    };

    handleCloseWonLeadForm = () => {
        this.setState({ isShowClosedWonLeadForm: false, wonLeadId: "" });
    };

    handleOpenWonLeadForm = (leadId) => {
        this.setState({ isShowClosedWonLeadForm: true, wonLeadId: leadId });
    };

    // handleToggleEditForm = (type) => {
    //     switch (type) {
    //         case 'budget':
    //             this.setState({isEditBudget:dispatch !this.state.isEditBudget});
    //             return;
    //
    //         case 'event_date':
    //             this.setState({isEditEventDate: !this.state.isEditEventDate});
    //             return;
    //
    //         case 'guest':
    //             this.setState({isEditGuest: !this.state.isEditGuest});
    //             return;
    //
    //         case 'prospect':
    //             this.setState({isEditProspect: !this.state.isEditProspect});
    //             return;
    //     }
    // };

    handleChange = (keyName, keyValue) => {

        this.setState({
            editRawEventDate: false,
        });
        let newLead = { ...this.state.lead };
        newLead[keyName] = keyValue;
        this.setState(
            {
                lead: newLead,
            },
            () => this.handleUpdateKey(keyName, keyValue)
        );
    };

    handleUpdateKey = (keyName, keyValue, type) => {
        let lead = { ...this.state.lead };
        lead[keyName] = keyValue;
        this.setState({
            lead: lead,
        });
        // this.handleToggleEditForm(type);
    };

    handleLeadSave = () => {
        this.props.dispatch(
            updateLead(
                this.state.lead._id,
                this.state.lead,
                this.props.agent,
                this.props.logId
            )
        );
        this.props.dispatch(fetchLeadList(this.props.venueId, this.state.appliedFilters));
    };

    handleAddNote = (agent) => {
        if (agent._id) {
            let note = {};
            note = {
                text: this.state.venueNote,
                partneraccount_id: agent._id,
                venuebundle_id: this.props.lead._id,
                venue_id: this.props.context.activeVenue._id,
                event_id: this.props.lead.event,
            };

            this.setState({
                venueNote: "",
            });
            this.props.dispatch(
                addVenuebundleNotesByVenuebundleId(this.state.lead._id, note)
            );
            this.handleDrawerToggle();
        } else {
            userNotify("No valid agent id provided", "error");
            this.handleDrawerToggle();
        }
    };

    handleUpdateNote = (note_id) => {
        if (note_id) {
            let note = { ...this.state.activeNote };
            note.text = this.state.venueNote;

            let updatedNote = {
                venuebundlenote: {
                    event_id: note.event_id,
                    is_deleted: false,
                    partneraccount_id: note.partneraccount_id,
                    text: note.text,
                    venue_id: note.venue_id,
                    venuebundle_id: note.venuebundle_id,
                    _id: note._id,
                },
            };

            this.props.dispatch(
                updateVenuebundleNotesByVenuebundleId(
                    this.state.lead._id
                        ? this.state.lead._id
                        : this.props.lead._id
                            ? this.props.lead._id
                            : "",
                    note._id ? note._id : "",
                    updatedNote ? updatedNote : {}
                )
            );
            // this.props.dispatch(updateNoteById(note_id, note_text))
            this.setState({
                venueNote: "",
            });
            this.handleDrawerToggle();
        } else {
            userNotify("No valid note id provided", "error");
            this.handleDrawerToggle();
        }
    };

    handleDeleteNote = () => {
        this.props.dispatch(
            removeVenuebundleNotesByVenuebundleId(
                this.props.lead._id
                    ? this.props.lead._id
                    : this.state.lead._id
                        ? this.props.lead._id
                        : "",
                this.state.activeNote._id ? this.state.activeNote._id : "",
                this.props.agent._id ? this.props.agent._id : ""
            )
        );
        this.setState({
            isDeletingNote: false,
        });
    };

    handleToggleDeleteModal = (note) => {
        let prevState = this.state.isDeletingNote;
        this.setState({
            isDeletingNote: !prevState,
            activeNote: note,
        });
    };

    handleDrawerToggle = () => {
        let prevValue = this.state.isNoteDrawerVisible;
        this.setState({
            isNoteDrawerVisible: !prevValue,
        });
    };

    handleTextAreaChange = () => {
    };

    handleShowFullView = (val) => {
        this.props.dispatch(showFullViewTabVisibleState(val));
    }

    saveEditItem = () => {
        let lead = { ...this.state.lead };
        lead.active_follow_up_date = this.state.followupDate;
        this.props.dispatch(updateLastCallStatus(this.state.lead._id, lead,this.props.venueId,this.state.appliedFilters));

    };

    saveCallStatus = () => {
        let lead = { ...this.state.lead };
        lead.active_call_status = this.state.lastCallStatus;
        this.props.dispatch(updateLastCallStatus(this.state.lead._id, lead,this.props.venueId,this.state.appliedFilters));

    };

    // handleChangeFollowUpDate = (stateToBeChanged, value) => {
    //     this.setState({
    //         ...this.state,
    //         [stateToBeChanged]: value,
    //     });
    // };


    handleChangeCallingStatus = (status) => {
      this.setState({
        lastCallStatus : status?.value
      })
    };

    handleChangeDate = (date) => {
        this.setState({
            followupDate : date
        })
    }

    render() {

        let lead = this.state.lead;
        let staticContent = this.props.staticContent;
        let { TextArea } = Input;
        let breakpoint = 800;

        let basicDetailsBodyContent = [
            {
                label: "FollowUp Date",
                value: (
                    <div>
                        <div
                            className="quality-edit-popover"
                            style={{ display: "flex" }}
                        >
    <div>
        <DatePicker
            style={{
                width: '100%'
            }}
            format={"DD MMM YYYY"}
            value={this.state.followupDate ? dayjs(this.state.followupDate) : (lead?.active_follow_up_date ? dayjs(lead?.active_follow_up_date) : null)}
            onChange={(date) => this.handleChangeDate( date ? date.toISOString() : null)}
        />
</div>
                            <div
                                className="editable-buttons"
                                style={{ display: "inline-block", marginLeft: "5px" }}
                            >
                                <button
                                    style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        backgroundColor: '#004ad9',
                                        border: 'none',
                                        borderRadius: '4px',
                                        padding: '4px 8px'
                                    }}
                                    onClick={() => this.saveEditItem()}
                                    bsStyle="success"
                                    bsSize="xsmall"
                                >
                                    <i className="fa fa-check"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                )
            },
            {
                label: 'Call Status',
                value: (
                    <div style={{width: this.props.width >768 ? '100%' : '30%'}}>
                    
                    <form className="form-inline editableform">
      <div className="control-group form-group">
        <div
          className="quality-edit-popover"
          style={{ display: "flex" }}
        >
          <div
            className="editable-input"
            style={{ display: "inline-block" }}
          >
        <Select
  style={{ width: this.props.windowWidth > 768 ? '170px' : '75px'}}
  placeholder="Select CallStatus"
  labelInValue={true}
  onChange={this.handleChangeCallingStatus}
  value={this.state.lastCallStatus ? this.state.lastCallStatus : lead.active_call_status}
  options={callingStatusList}
  className='selectStyles'
/>
          </div>
          <div
            className="editable-buttons"
            style={{ display: "inline-block", marginLeft: "5px" }}
          >
            <Button
            style={{
                textAlign: 'center',
                color: 'white',
                backgroundColor: '#004ad9',
                border: 'none',
                borderRadius:'4px',
                padding:'4px 8px'
            }}
              onClick={() => this.saveCallStatus()}
              bsStyle="success"
              bsSize="xsmall"
            >
              <i className="fa fa-check"></i>
            </Button>
          </div>
        </div>
      </div>
    </form>
            
            </div>
                )
            },
            {
                label:
                    staticContent.BASIC_DETAIL_PAGE_CUSTOMER_TEXT +
                    " " +
                    staticContent.BASIC_DETAIL_PAGE_NAME_TEXT,
                value: (
                    <span style={{ fontSize: "14px", color: "#000" }}>
                        {lead.contact_name ? lead.contact_name : "--"}
                    </span>
                ),
            },
            {
                label:
                    staticContent.BASIC_DETAIL_PAGE_CUSTOMER_TEXT +
                    " " +
                    staticContent.BASIC_DETAIL_PAGE_MOBILE_TEXT,
                value: (
                    <p style={{ fontSize: "14px", margin: 0 }}>
                        <span>{lead.contact_phone}</span>
                        <a
                            href={`https://api.whatsapp.com/send?phone=91${lead.contact_phone}`}
                            target={"_blank"}
                            style={{ marginLeft: "10px", color: "#06a332" }}
                            className="btn-btn-sm btn-default"
                        >
                            <i
                                style={{
                                    border: "1px solid #25BE50",
                                    padding: "2px",
                                    borderRadius: "2px",
                                    fontSize: "14px",
                                }}
                                className="fa fa-whatsapp"
                            ></i>
                        </a>
                    </p>
                ),
            },
            {
                label:
                    staticContent.BASIC_DETAIL_PAGE_CUSTOMER_TEXT +
                    " " +
                    staticContent.BASIC_DETAIL_PAGE_EMAIL_TEXT,
                value: (
                    <span style={{ fontSize: "14px", color: "#000" }}>
                        {lead.contact_email ? lead.contact_email : "--"}
                    </span>
                ),
            },
        ];
        let bottomBasicDetailsContent = [
            {
                label:
                    staticContent.BASIC_DETAIL_PAGE_ADMIN_TEXT +
                    " " +
                    staticContent.BASIC_DETAIL_PAGE_NAME_TEXT,
                value: lead.admin_name ? lead.admin_name : "--",
            },
            {
                label:
                    staticContent.BASIC_DETAIL_PAGE_ADMIN_TEXT +
                    " " +
                    staticContent.BASIC_DETAIL_PAGE_MOBILE_TEXT,
                value: lead.admin_phone ? lead.admin_phone : "--",
            },
            {
                label:
                    staticContent.BASIC_DETAIL_PAGE_ADMIN_TEXT +
                    " " +
                    staticContent.BASIC_DETAIL_PAGE_EMAIL_TEXT,
                value: lead.admin_email ? lead.admin_email : "--",
            },
        ];
        let eventDetailsBodyContent = [
            {
                label: staticContent.BASIC_DETAIL_PAGE_OCCASION_TEXT,
                value: lead.occasion
                    ? lead.occasion
                    : lead.category
                        ? lead.category
                        : "--",
            },
            {
                label: staticContent.BASIC_DETAIL_PAGE_PACKAGE_TEXT,
                value: lead.packagetype ? lead.packagetype : "--",
            },
            {
                label: staticContent.BASIC_DETAIL_PAGE_CITY_TEXT,
                value: lead.city ? lead.city : "--",
            },
            {
                label: "",
                event: "",
            },
            {
                label: <>{staticContent.BASIC_DETAIL_PAGE_EVENT_DATE_TEXT} {this.state.editRawEventDate && this.props.windowWidth < breakpoint &&
                    <Button style={{ marginLeft: "4px", fontSize: "10px", padding: "5px" }} onClick={() => {
                        this.setState({ editRawEventDate: false })
                    }}>
                        Close
                    </Button>
                } </>,
                value: (
                    <SwitchControl
                        shouldHavePermissions={["leads:edit"]}
                        userPermissions={
                            this.props.partneraccount.permissions
                                ? this.props.partneraccount.permissions
                                : []
                        }
                        yes={
                            <>
                                {this.state.lead.event_date ? (
                                    <DatePicker
                                        style={{
                                            padding: "7px 8px 6px 8px",
                                            border: "1px solid #B0B0B0",
                                        }}
                                        showTime={true}
                                        format={"DD MMM YYYY hh:mm a"}
                                        value={
                                            this.state.lead.event_date
                                                ? dayjs(this.state.lead.event_date)
                                                : null
                                        }
                                        onChange={(date) =>
                                            date &&
                                            this.handleChange("event_date", date.toISOString())
                                        }
                                    />
                                ) : !this.state.editRawEventDate ? (
                                    <input
                                        style={{
                                            padding: "6px 14px",
                                            border: "1px solid #B0B0B0",
                                            borderRadius: "4px",
                                            color: "#1A1A1A",
                                            width: "46%",
                                        }}
                                        onClick={() => {
                                            this.setState({ editRawEventDate: true });
                                        }}
                                        value={this.state.lead.raw_event_date}
                                    />
                                ) : (
                                    <>
                                        <DatePicker
                                            style={{
                                                padding: "7px 8px 6px 8px",
                                                border: "1px solid #B0B0B0",
                                            }}
                                            //   showTime={true}
                                            open={this.state.editRawEventDate}
                                            onOpenChange={(open) => {
                                                this.setState({ editRawEventDate: open });
                                            }}
                                            format={"DD MMM YYYY hh:mm a"}
                                            value={
                                                this.state.lead.event_date
                                                    ? dayjs(this.state.lead.event_date)
                                                    : null
                                            }
                                            onChange={(date) =>
                                                date &&
                                                this.handleChange("event_date", date.toISOString())
                                            }
                                        />
                                        {this.props.windowWidth > breakpoint &&
                                            <Button style={{ marginLeft: "4px", height: "35px" }} onClick={() => {
                                                this.setState({ editRawEventDate: false })
                                            }}>
                                                Close
                                            </Button>
                                        }
                                    </>
                                )}
                            </>

                        }
                        no={
                            this.state.lead.event_date ?
                                <DatePicker
                                    disabled={true}
                                    style={{
                                        padding: "7px 8px 6px 8px",
                                        border: "1px solid #B0B0B0",
                                    }}
                                    showTime={true}
                                    format={"DD MMM YYYY hh:mm a"}
                                    value={
                                        this.state.lead.event_date
                                            ? dayjs(this.state.lead.event_date)
                                            : ""
                                    }
                                    onChange={(date) =>
                                        date && this.handleChange("event_date", date.toISOString())
                                    }
                                /> :
                                <input
                                    style={{
                                        padding: "6px 14px",
                                        border: "1px solid #B0B0B0",
                                        borderRadius: "4px",
                                        color: "#1A1A1A",
                                        width: "46%",
                                    }}
                                    disabled
                                    value={this.state.lead.raw_event_date}
                                />

                        }
                    />
                ),
            },
            {
                label: staticContent.BASIC_DETAIL_PAGE_GUEST_TEXT,
                value: (
                    <SwitchControl
                        shouldHavePermissions={["leads:edit"]}
                        userPermissions={
                            this.props.partneraccount.permissions
                                ? this.props.partneraccount.permissions
                                : []
                        }
                        yes={
                            <input
                                style={{
                                    padding: "6px 14px",
                                    border: "1px solid #B0B0B0",
                                    borderRadius: "4px",
                                    color: "#1A1A1A",
                                    width: "90%",
                                }}
                                min={0}
                                onChange={(e) =>
                                    this.handleChange("guests", parseInt(e.target.value))
                                }
                                value={this.state.lead.guests ? this.state.lead.guests : 0}
                                type={"number"}
                            />
                        }
                        no={
                            <input
                                disabled={true}
                                style={{
                                    padding: "6px 14px",
                                    border: "1px solid #B0B0B0",
                                    borderRadius: "4px",
                                    color: "#1A1A1A",
                                    width: "90%",
                                }}
                                min={0}
                                onChange={(e) =>
                                    this.handleChange("guests", parseInt(e.target.value))
                                }
                                value={this.state.lead.guests ? this.state.lead.guests : 0}
                                type={"number"}
                            />
                        }
                    />
                ),
            },
            {
                label: staticContent.BASIC_DETAIL_PAGE_BUDGET_TEXT,
                value: (
                    <SwitchControl
                        shouldHavePermissions={["leads:edit"]}
                        userPermissions={
                            this.props.partneraccount.permissions
                                ? this.props.partneraccount.permissions
                                : []
                        }
                        yes={
                            <span>
                                <input
                                    style={{
                                        padding: "6px 14px",
                                        border: "1px solid #B0B0B0",
                                        borderRadius: "4px",
                                        color: "#1A1A1A",
                                        width: "46%",
                                    }}
                                    min={0}
                                    onChange={(e) =>
                                        this.handleChange("budget_start", e.target.value)
                                    }
                                    value={
                                        this.state.lead.budget_start
                                            ? this.state.lead.budget_start
                                            : 0
                                    }
                                    type={"number"}
                                />
                                <span>{" - "}</span>
                                <input
                                    style={{
                                        padding: "6px 14px",
                                        border: "1px solid #B0B0B0",
                                        borderRadius: "4px",
                                        color: "#1A1A1A",
                                        width: "46%",
                                    }}
                                    min={0}
                                    onChange={(e) =>
                                        this.handleChange("budget_end", e.target.value)
                                    }
                                    value={
                                        this.state.lead.budget_end ? this.state.lead.budget_end : 0
                                    }
                                    type={"number"}
                                />
                            </span>
                        }
                        no={
                            <span>
                                <input
                                    disabled={true}
                                    style={{
                                        padding: "6px 14px",
                                        border: "1px solid #B0B0B0",
                                        borderRadius: "4px",
                                        color: "#1A1A1A",
                                        width: "46%",
                                    }}
                                    min={0}
                                    onChange={(e) =>
                                        this.handleChange("budget_start", e.target.value)
                                    }
                                    value={
                                        this.state.lead.budget_start
                                            ? this.state.lead.budget_start
                                            : 0
                                    }
                                    type={"number"}
                                />
                                <span>{" - "}</span>
                                <input
                                    disabled={true}
                                    style={{
                                        padding: "6px 14px",
                                        border: "1px solid #B0B0B0",
                                        borderRadius: "4px",
                                        color: "#1A1A1A",
                                        width: "46%",
                                    }}
                                    min={0}
                                    onChange={(e) =>
                                        this.handleChange("budget_end", e.target.value)
                                    }
                                    value={
                                        this.state.lead.budget_end ? this.state.lead.budget_end : 0
                                    }
                                    type={"number"}
                                />
                            </span>
                        }
                    />
                ),
            },
            {
                label: staticContent.BASIC_DETAIL_PAGE_PROSPECT_TEXT,
                value: (
                    <SwitchControl
                        shouldHavePermissions={["leads:edit"]}
                        userPermissions={
                            this.props.partneraccount.permissions
                                ? this.props.partneraccount.permissions
                                : []
                        }
                        yes={
                            <Radio.Group
                                style={{ display: "flex", flexWrap: "wrap" }}
                                onChange={(e) => this.handleChange("prospect", e.target.value)}
                                value={this.state.lead.prospect && this.state.lead.prospect}
                            >
                                <Radio value={"Cold"}>Cold</Radio>
                                <Radio value={"Warm"}>Warm</Radio>
                                <Radio value={"Hot"}>Hot</Radio>
                            </Radio.Group>
                        }
                        no={
                            <Radio.Group
                                disabled={true}
                                style={{ display: "flex", flexWrap: "wrap" }}
                                onChange={(e) => this.handleChange("prospect", e.target.value)}
                                value={this.state.lead.prospect && this.state.lead.prospect}
                            >
                                <Radio value={"Cold"}>Cold</Radio>
                                <Radio value={"Warm"}>Warm</Radio>
                                <Radio value={"Hot"}>Hot</Radio>
                            </Radio.Group>
                        }
                    />
                ),
            },
        ];
        let pastFollowUpContent = [
            {
                label: 'Past Follow Up Dates',
                value: (
                    <div>
                        {lead && lead.past_follow_up_dates && (
                            <div>

                                {lead.past_follow_up_dates.map((item) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', width: this.props.windowWidth > breakpoint ? "800px" : "240px" }}>
                                        <span style={{ padding: "5px 0px 5px 0px" }}>{this.props.partneraccount.first_name && this.props.partneraccount.last_name ? this.props.partneraccount.first_name+ " "+ this.props.partneraccount.last_name : this.props.partneraccount.contact_name}</span>
                                        <div style={{ fontSize: '14px', color: 'black', padding: '5px 0px 5px 0px', alignSelf: 'center' }} key={item.time}>
                                            {' - '} {moment(item.date).format("DD-MMM-YY HH:mm a")}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )
            },
        ]
        let pastCallStatusContent = [
            {
                label: "Past Call Status",
                value: (
                    <div>
                        {lead && lead.past_call_statuses && lead.past_call_statuses.length > 0 ? (
                            <div style={{ width: this.props.windowWidth > breakpoint ? "800px" : "240px" }}>
                                {lead.past_call_statuses.map((item) => (
                                    <div style={{ fontSize: '14px', color: 'black' }} key={item.time}>
                                        {item.status} - {moment(item.time).format("DD-MMM-YY HH:mm a")}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>{'--'}</div>
                        )}
                    </div>
                )
            }
        ]
        

        return (
            <PageAccessControl
                shouldHavePermissions={["leads:read"]}
                userPermissions={
                    this.props.partneraccount.permissions
                        ? this.props.partneraccount.permissions
                        : []
                }
            >

                <div
                    className="row lead-manage-page"
                    style={{
                        padding: this.props.windowWidth > breakpoint ? "10px 30px" : "10px",
                        marginTop: "0px",
                    }}
                >
                    <Helmet>
                        <title>Basic Details</title>
                    </Helmet>
                    <Drawer
                        placement={this.props.windowWidth > breakpoint ? "right" : "bottom"}
                        value={this.state.venueNote}
                        onChange={(e) =>
                            this.setState({
                                venueNote: e.target.value,
                            })
                        }
                        width={"50%"}
                        extra={
                            <Button
                                onClick={() => {
                                    if (
                                        !this.state.venueNote ||
                                        this.state.venueNote.length === 0
                                    ) {
                                        userNotify("Please Write a note", "warning");
                                    } else {
                                        this.state.noteDrawerType === "Edit Note"
                                            ? this.handleUpdateNote(
                                                this.state.activeNote._id
                                                    ? this.state.activeNote._id
                                                    : ""
                                            )
                                            : this.handleAddNote(
                                                this.props.agent ? this.props.agent : {}
                                            );
                                    }
                                }}
                                style={{
                                    borderRadius: "4px",
                                    backgroundColor: "#2A52BB",
                                    border: "1px solid #B0B0B0",
                                    color: "white",
                                }}
                            >
                                <span>{staticContent.BASIC_DETAIL_PAGE_SAVE_NOTE_TEXT}</span>
                            </Button>
                        }
                        open={this.state.isNoteDrawerVisible}
                        title={this.state.noteDrawerType}
                        onClose={this.handleDrawerToggle}
                    >
                        <p style={{ marginLeft: "3px" }}>
                            {this.state.noteDrawerType === "Edit Note"
                                ? "Edit your note"
                                : "Add a new note for everyone"}
                        </p>
                        <TextArea
                            value={this.state.venueNote}
                            onChange={(e) =>
                                this.setState({
                                    venueNote: e.target.value,
                                })
                            }
                            style={{ minHeight: "200px", resize: "none" }}
                            placeholder="Write your note here...."
                        />
                    </Drawer>
                    <Modal
                        title="Delete a note"
                        open={this.state.isDeletingNote}
                        onOk={this.handleDeleteNote}
                        onCancel={this.handleToggleDeleteModal}
                    >
                        <p>Are you sure you want to delete the note</p>
                    </Modal>
                    <div

                        style={{
                            minHeight: this.props.windowWidth > breakpoint ? "70vh" : "130vh",
                            display: "flex",
                            padding:
                                this.props.windowWidth > breakpoint
                                    ? "35px"
                                    : "10px 24px 15vw 24px",
                            justifyContent:
                                this.props.windowWidth > breakpoint
                                    ? "space-between"
                                    : "flex-start",
                            flexDirection:
                                this.props.windowWidth > breakpoint ? "row" : "column",
                            gap: this.props.windowWidth > breakpoint ? "0" : "24px",
                            flexWrap: "wrap",
                            rowGap: "30px",
                        }}
                    >

                        <CustomCard
                            customStyles={{
                                width: this.props.windowWidth > breakpoint ? "45%" : "100%",
                                minWidth: "48%",
                                justifyContent: "flex-start",
                                height: "max-content",
                            }}
                        >
                            <CardHeader
                                customStyles={{
                                    backgroundColor: "#CDDEFF",
                                    padding: "8px 24px",
                                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "18px",
                                        color: "#5c5c5c",
                                        fontWeight: "600",
                                    }}
                                >
                                    {staticContent.BASIC_DETAIL_PAGE_TITLE}
                                </span>
                                <SwitchControl
                                    shouldHavePermissions={["leads:edit"]}
                                    userPermissions={
                                        this.props.partneraccount.permissions
                                            ? this.props.partneraccount.permissions
                                            : []
                                    }
                                    yes={
                                        <Select
                                            value={lead.status}
                                            onChange={(e) => this.handleChangeStatus(e)}
                                            listHeight={1000}
                                            options={
                                                this.props.leadStatusList &&
                                                this.props.leadStatusList.length > 0 &&
                                                this.props.leadStatusList
                                            }
                                            style={{
                                                border: "none",
                                                boxSizing: "border-box",
                                                background: "transparent",
                                                width: "180px",
                                                outline: "none",
                                            }}
                                            dropdownStyle={{
                                                width: "max-content",
                                            }}
                                        />
                                    }
                                    no={
                                        <Select
                                            disabled={true}
                                            value={lead.status}
                                            onChange={(e) => this.handleChangeStatus(e)}
                                            listHeight={1000}
                                            options={
                                                this.props.leadStatusList &&
                                                this.props.leadStatusList.length > 0 &&
                                                this.props.leadStatusList
                                            }
                                            style={{
                                                border: "none",
                                                boxSizing: "border-box",
                                                background: "transparent",
                                                width: "180px",
                                                outline: "none",
                                            }}
                                            dropdownStyle={{
                                                width: "max-content",
                                            }}
                                        />
                                    }
                                />
                            </CardHeader>
                            <CardBody
                                customStyles={{ justifyContent: "space-between" }}
                                data={basicDetailsBodyContent}
                            />
                            <div
                                style={{
                                    height: "1px",
                                    width: "90%",
                                    alignSelf: "center",
                                    backgroundImage:
                                        "repeating-linear-gradient(0deg, #cdeff0, #cdeff0 6px, transparent 6px, transparent 14px, #cdeff0 14px), repeating-linear-gradient(90deg, #cdeff0, #cdeff0 6px, transparent 6px, transparent 14px, #cdeff0 14px), repeating-linear-gradient(180deg, #cdeff0, #cdeff0 6px, transparent 6px, transparent 14px, #cdeff0 14px), repeating-linear-gradient(270deg, #cdeff0, #cdeff0 6px, transparent 6px, transparent 14px, #cdeff0 14px)",
                                    backgroundSize: "2px 100%, 100% 2px, 2px 100% , 100% 2px",
                                    backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                                    backgroundRepeat: "no-repeat",
                                }}
                            ></div>
                            <CardBody
                                data={bottomBasicDetailsContent}
                                customStyles={{ justifyContent: "space-between" }}
                            />
                        </CustomCard>
                        <CustomCard
                            customStyles={{
                                width: this.props.windowWidth > breakpoint ? "45%" : "100%",
                                minWidth: "48%",
                                height: "max-content",
                                padding: this.props.windowWidth > breakpoint ? "0px 0px 75px 0px" : "0px",
                                justifyContent: "space-between",
                            }}
                        >
                            <CardHeader
                                customStyles={{
                                    backgroundColor: "#CDDEFF",
                                    padding: "10.5px 24px",
                                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "18px",
                                        color: "#5c5c5c",
                                        fontWeight: "600",
                                    }}
                                >
                                    {staticContent.BASIC_DETAIL_PAGE_EVENT_TITLE}
                                </span>
                                <SwitchControl
                                    shouldHavePermissions={["leads:edit"]}
                                    userPermissions={
                                        this.props.partneraccount.permissions
                                            ? this.props.partneraccount.permissions
                                            : []
                                    }
                                    yes={
                                        <Button
                                            onClick={this.handleLeadSave}
                                            style={{
                                                borderRadius: "4px",
                                                backgroundColor: "#2A52BB",
                                            }}
                                        >
                                            <span>{staticContent.BASIC_DETAIL_PAGE_SAVE}</span>
                                        </Button>
                                    }
                                    no={
                                        <Button
                                            disabled={true}
                                            onClick={this.handleLeadSave}
                                            style={{
                                                borderRadius: "4px",
                                                backgroundColor: "#2A52BB",
                                            }}
                                        >
                                            <span>{staticContent.BASIC_DETAIL_PAGE_SAVE}</span>
                                        </Button>
                                    }
                                />
                            </CardHeader>
                            <CardBody
                                customStyles={{ justifyContent: "space-between" }}
                                data={eventDetailsBodyContent}
                            />
                        </CustomCard>






                        <CustomCard
                            customStyles={{
                                width: this.props.windowWidth > breakpoint ? "45%" : "100%",
                                minWidth: "48%",
                                height: "max-content",
                                justifyContent: "space-between",
                            }}
                        >
                            <CardHeader
                                customStyles={{
                                    backgroundColor: "#CDDEFF",
                                    padding: "10.5px 24px",
                                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
                                    border: "none",
                                    // display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "18px",
                                        color: "#5c5c5c",
                                        fontWeight: "600",
                                    }}
                                >
                                    {'Past Call Status'}
                                </span>
                            </CardHeader>
                            <CardBody
                                customStyles={{

                                }}
                                data={pastCallStatusContent}
                            />
                        </CustomCard>
                        <CustomCard
                            customStyles={{
                                width: this.props.windowWidth > breakpoint ? "45%" : "100%",
                                minWidth: "48%",
                                height: "max-content",
                                justifyContent: "space-between",
                            }}
                        >
                            <CardHeader
                                customStyles={{
                                    backgroundColor: "#CDDEFF",
                                    padding: "10.5px 24px",
                                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "18px",
                                        color: "#5c5c5c",
                                        fontWeight: "600",
                                    }}
                                >
                                    {'Past FollowUp Dates'}
                                </span>
                            </CardHeader>
                            <CardBody
                                customStyles={{
                                }}
                                data={pastFollowUpContent}
                            />
                        </CustomCard>






                        <CustomCard
                            customStyles={{ width: "100%", border: "1px solid #B0B0B0" }}
                        >
                            <CardHeader
                                customStyles={{
                                    display: "flex",
                                    width: "100%",
                                    backgroundColor: "white",
                                    borderBottom: "1px solid #E0E0E0",
                                    background: "transparent",
                                    padding: "15px 20px",
                                    gap: this.props.windowWidth > breakpoint ? "18px" : "0px",
                                    justifyContent:
                                        this.props.windowWidth > breakpoint
                                            ? "flex-start"
                                            : "space-between",
                                }}
                            >
                                <Button
                                    disabled={true}
                                    onClick={() => console.log("notes")}
                                    style={{ borderRadius: "4px", backgroundColor: "#2A52BB" }}
                                >
                                    <span>
                                        {staticContent.BASIC_DETAIL_PAGE_ACTIVE_NOTE_TEXT}
                                    </span>
                                </Button>
                                <SwitchControl
                                    shouldHavePermissions={["leads:edit"]}
                                    userPermissions={
                                        this.props.partneraccount.permissions
                                            ? this.props.partneraccount.permissions
                                            : []
                                    }
                                    yes={
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    noteDrawerType: "Add Note",
                                                    venueNote: "",
                                                });
                                                this.handleDrawerToggle();
                                            }}
                                            style={{
                                                borderRadius: "4px",
                                                backgroundColor: "white",
                                                border: "1px solid #B0B0B0",
                                                color: "#1A1A1A",
                                            }}
                                        >
                                            <span>
                                                {staticContent.BASIC_DETAIL_PAGE_ADD_NOTE_TEXT}
                                            </span>
                                        </Button>
                                    }
                                    no={
                                        <Button
                                            disabled={true}
                                            onClick={() => {
                                                this.setState({
                                                    noteDrawerType: "Add Note",
                                                    venueNote: "",
                                                });
                                                this.handleDrawerToggle();
                                            }}
                                            style={{
                                                borderRadius: "4px",
                                                backgroundColor: "white",
                                                border: "1px solid grey",
                                                color: "grey",
                                            }}
                                        >
                                            <span>
                                                {staticContent.BASIC_DETAIL_PAGE_ADD_NOTE_TEXT}
                                            </span>
                                        </Button>
                                    }
                                />
                            </CardHeader>
                            <CardBody
                                customStyles={{
                                    height: this.props.windowWidth > breakpoint ? "50vh" : "40vh",
                                    overflow: "auto",
                                }}
                            >
                                {this.state.venuebundlenotes ? (
                                    this.state.venuebundlenotes.map((note, index) => {
                                        return (
                                            <div
                                                key={note?._id}
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        flexWrap: "wrap",
                                                        gap: "15px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                this.props.windowWidth > breakpoint
                                                                    ? "row"
                                                                    : "column",
                                                            marginBottom: "15px",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                marginRight: "30px",
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                color: "#1A1A1A",
                                                            }}
                                                        >
                                                            {this.props.partnerAccountObjectMap &&
                                                                this.props.partnerAccountObjectMap[
                                                                note.partneraccount_id
                                                                ]
                                                                ? (this.props.partnerAccountObjectMap[
                                                                    note.partneraccount_id
                                                                ].first_name &&
                                                                    this.props.partnerAccountObjectMap[
                                                                        note.partneraccount_id
                                                                    ].first_name) +
                                                                " " +
                                                                (this.props.partnerAccountObjectMap[
                                                                    note.partneraccount_id
                                                                ].last_name &&
                                                                    this.props.partnerAccountObjectMap[
                                                                        note.partneraccount_id
                                                                    ].last_name)
                                                                : "--"}
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                color: "#616161",
                                                            }}
                                                        >
                                                            {note?.updated_at &&
                                                                note.created_at &&
                                                                !dayjs(note.updated_at).isSame(note?.created_at)
                                                                ? dayjs(note.update_at).format(
                                                                    "DD MMM YYYY HH:MM a"
                                                                ) + " Edited"
                                                                : dayjs(note.created_at).format(
                                                                    "DD MMM YYYY HH:MM a"
                                                                )}
                                                        </span>
                                                    </div>
                                                    <span>
                                                        {note?.partneraccount_id ===
                                                            this.props?.agent?._id ? (
                                                            <span>
                                                                <Button
                                                                    onClick={() =>
                                                                        this.handleToggleDeleteModal(note)
                                                                    }
                                                                    className="btn btn-sm btn-white"
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            noteDrawerType: "Edit Note",
                                                                            activeNote: note,
                                                                            venueNote: note.text,
                                                                        });
                                                                        this.handleDrawerToggle();
                                                                    }}
                                                                    className="btn btn-sm btn-white"
                                                                    style={{ marginLeft: "15px" }}
                                                                >
                                                                    <i className="fa fa-pencil"></i>
                                                                </Button>
                                                            </span>
                                                        ) : null}
                                                    </span>
                                                </div>
                                                <span
                                                    style={{
                                                        color: "rgba(26, 26, 26, 0.87)",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {note?.text}
                                                </span>
                                                <span
                                                    style={{
                                                        borderBottom: "1px solid rgba(26, 26, 26, 0.1)",
                                                        marginBottom: "15px",
                                                        marginTop: "15px",
                                                        width: "100%",
                                                        height: "1px",
                                                        alignSelf: "center",
                                                    }}
                                                ></span>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <span
                                        style={{
                                            alignSelf: "center",
                                            margin: "auto",
                                        }}
                                    >
                                        You don’t have any Notes yet
                                    </span>
                                )}
                            </CardBody>
                        </CustomCard>
                    </div>

                    {this.state.isShowRejectLeadForm ? (
                        <Drawer
                            open={this.state.isShowRejectLeadForm}
                            onClose={this.handleCloseRejectLeadForm}
                            overlayTitle={``}
                            width={500}
                        >
                            <RejectLeadForm
                                leadId={this.state.rejectedLeadId}
                                closedLostReasons={this.props.closedLostReasons}
                                showMessage={this.showMessage}
                                isRejectingLead={this.props.isRejectingLead}
                                handleRejectAvailability={this.handleRejectAvailability}
                            />
                        </Drawer>
                    ) : null}
                    {this.state.isShowClosedWonLeadForm ? (
                        <Drawer
                            open={this.state.isShowClosedWonLeadForm}
                            onClose={this.handleCloseWonLeadForm}
                            overlayTitle={`Closed Won Lead Form`}
                            width={500}
                        >
                            <CloseWonLeadForm
                                leadId={this.state.wonLeadId}
                                closedWonReasons={[]}
                                showMessage={this.showMessage}
                                isRejectingLead={this.props.isRejectingLead}
                                handleCloseWonEvent={this.handleCloseWonEvent}
                                lead={this.state.lead.venue}
                            />
                        </Drawer>
                    ) : null}
                </div>

            </PageAccessControl>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        venueId: getActiveVenueId(state),
        leadId: props.params?.leadId,
        leadid: getSelectedLeadId(state),
        status: "",
        currentUrl: props.location?.pathname,
        staticContent: getLeadManageStaticContent(state),
        lead: getLeadDetails(state),
        logId: getLeadLogId(state),
        agent: getUserAgent(state),
        isLeadManageFromLeadList: getLeadManageParentPath(state),
        isUpdatingLead: getLeadUpdatingStatus(state),
        leadStatusList: getLeadStatusList(state),
        closedLostReasons: getLeadClosedLostReasons(state),
        isRejectingLead: getRejectingLeadStatus(state),
        leadLogs: getLeadLogs(state),
        venuebundlenotes: getVenueBundleNotes(state),
        isAddingVenueBundleNote: getAddingVenueBundleNote(state),
        isEditingVenueBundleNote: getEditingVenueBundleNote(state),
        partnerAccountObjectMap: getPartnerAccountObjectMap(state),
        partneraccount: getPartnerAccount(state),
        pageNum: getLeadPageNum(state),
        isFullViewTabvisible: getFullViewTabVisiblestate(state),

    };
}

BasicDetailPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.object,
};

export default connect(mapStateToProps)(withRoute(BasicDetailPage));


import React,{Component} from 'react';


class Banner extends Component{
  constructor (props) {
    super(props);
  }

  render(){
    return(
      <div className="col-sm-12" style={{minHeight: '250px'}}>
        <h2 className={'text-black'} style={{marginTop:'65px', fontSize:'24px'}}>Our Happy Clients</h2>
        <div className="sponsers-segment" style={{display: 'flex', gap: '30px'}}>
          {
            this.props.sponsers && this.props.sponsers.length > 0 && this.props.sponsers.map((sponser, index) => (
              <img key={index} src={`https://cdn.venuemonk.com/images/icons/${sponser}`} alt="" style={{width: `${index === 0 ? '80px': '150px'}`, margin: '15px 0px'}}/>
            ))
          }
        </div>
      </div>
    );
  }

}
export default Banner;

import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {withRoute} from 'util/withRoute';
import {openLeadManageModalVisibleState} from '../LeadManageActions';
import {getLeadManageSelectedTab} from '../LeadManageReducer';
import "../../App/App.css"
import BasicDetailPage from '../pages/BasicDetailPage/BasicDetailPage';
import PackageListPage from '../pages/PackageListPage/PackageListPage';
import VisitPage from '../pages/VisitPage/VisitPage';
import { Tab, Tabs } from 'react-bootstrap';

const LeadManage = ({leadId}) => {
    const [activetab,setActiveTab] = useState(1)

    const [width, setWidth] = useState(0);

    const dispatch = useDispatch();
    const selectedTab = useSelector(state => getLeadManageSelectedTab(state))

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
    };

    const handleTabChange = (key) => {
        setActiveTab(key)
        
        // dispatch(openLeadManageModalVisibleState(true, key));
    };

    return (
        <div className={width > 800 ? "lead-manage-body-container" : ""}>
            <Tabs activeKey={activetab} onSelect={handleTabChange} style={{width: '100%'}}>
                <Tab title="Basic Details" eventKey={1}>
                    {
                        activetab == 1 &&
                        <BasicDetailPage
                        viewMode={"TabbedPage"}
                        leadId={leadId}
                      />
                    }
                   
                </Tab>
                <Tab title="Package" eventKey={2}>
                    {
                        activetab == 2 &&
                        <PackageListPage
                        viewMode={"TabbedPage"}
                      />

                    }
                </Tab>
                 <Tab title="Visit" eventKey={3}>
                    {
                 activetab == 3 &&
                     <VisitPage
                       viewMode={"TabbedPage"}
                     />}
                 </Tab>
            </Tabs>
        </div>
    );
};

function mapStateToProps(state, props) {
    return {
        leadid: props.params?.leadid,
    };
}

export default connect(mapStateToProps)(withRoute(LeadManage));
import React,{Component} from 'react';
import VmIcon from "../../../../../components/Icons/VmIcon";
import LoginPage from '../../LoginPage/LoginPage';
import { Link } from 'react-router-dom';

class MobileBanner extends Component{

  handleScroll = () => {
    window.scrollTo({top:400, behavior:'smooth'})
}
  render(){
    return(
      <>
        <div id="venuemonkBanner" style={{position: 'relative',backgroundImage: 'url("https://cdn.venuemonk.com/images/banner/corporate-home-page-mobile-background.svg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '550px', padding: '50px 10px', fontSize: '1.5rem'}}>
        <div className="col-sm-12" style={{color:'#FFFFFF', textAlign:'center'}}>
          <div style={{fontSize:'16px', fontWeight:500}}>
            Delhi NCR's Biggest Event Planning Platform
          </div>
          <div style={{marginTop:'20px',fontSize:'18px', fontWeight: 600}}>Corporate Events Made Easy!</div>
          <div style={{fontSize:'22px'}}>
            <div style={{marginTop:'25px'}}  >
              <div style={{fontWeight:600}}>50+</div>
              <div style={{fontWeight:500}}>Verified Events</div>
            </div>
            <div style={{marginTop:'25px'}}>
              <div style={{fontWeight:600}}>20000+</div>
              <div style={{fontWeight:500}}>Verified Venues</div>
            </div>
            <div style={{marginTop:'25px'}}>
              <div style={{fontWeight:600}}>20+</div>
              <div style={{fontWeight:500}}>Category of Events</div>
            </div>
          </div>
        </div>
        <div className="col-sm-12" style={{ display: 'flex', position: 'absolute', bottom: '-15px'}}>
          <span style={{margin: 'auto', width:'50px', height:'50px', borderRadius:'25px', border:'1px solid white', backgroundColor:'white'}} onClick={() => this.handleScroll()}>
             <VmIcon
               targetClass="banner-down-arrow"
               iconName="icon-vm-down-arrow" />
          </span>

        </div>
      </div>
            {
                !this.props.isAuthenticated ?
                <LoginPage styles={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '90%',
                  margin: '40px auto',
                }} />:
                  <div className="col-sm-12 text-center" style={{backgroundColor: '#fff', paddingBottom: '25px', borderRadius: '3px', minHeight: '200px', paddingTop: '30px'}}>
                    <div style={{width: '100%'}}>
                      <label >{`You are Logged In`}</label>
                    </div>
                    <div style={{width: '100%', marginTop: '30px'}}>
                      <Link to={`/dashboard`} className="btn btn-primary full-width" style={{width: '80%'}}>{`VISIT DASHBOARD`}</Link>
                    </div>
                  </div>
              }
      </>
    );
  }

}
 export default MobileBanner;

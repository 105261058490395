import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

function Breadcrumb(props) {
  return (
    <div className="col-12 breadcrumb" style={{marginBottom: '25px', backgroundColor: 'white', width: '50%'}}>
      <Link className="breadcrumb-item" to={props.isLeadManageFromLeadList ? '/lead/list' : '/leadfunnel/list'}>
        {props.isLeadManageFromLeadList ? 'All Leads' : 'Lead Funnel'}
      </Link>
      <span className="breadcrumb-item active">
            {props.contact_name && props.contact_name !== '' ? _.startCase(`${props.contact_name}`) + '\'s Event' : `Event Detail`}
      </span>
    </div>
  );
}

export default Breadcrumb;

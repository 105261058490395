import React from 'react';
import {BounceLoader} from 'react-spinners'

export function BasicLoader(props) {
  return (
    <div className="text-center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <BounceLoader color={props.color ? props.color : '#491369'} size={props.size ? props.size : 15} />
    </div>
  );
}

export default BasicLoader;

import callApi from '../../util/apiCaller';
import callEventApi from "../../util/eventApiCaller";
import { userNotify } from '../../components/Toasts/ToastAdd';

export const GET_LEAD_DETAILS = 'GET_LEAD_DETAILS';
export const UPDATE_FETCH_LEAD_STATUS = 'UPDATE_FETCH_LEAD_STATUS';
export const GET_SUBPACKAGE_LIST = 'GET_SUBPACKAGE_LIST';
export const UPDATE_FETCH_SUBPACKAGE_STATUS = 'UPDATE_FETCH_SUBPACKAGE_STATUS';
export const GET_SMS_LIST = 'GET_SMS_LIST';
export const UPDATE_FETCH_SMS_STATUS = 'UPDATE_FETCH_SMS_STATUS';
export const GET_EMAIL_LIST = 'GET_EMAIL_LIST';
export const UPDATE_FETCH_EMAIL_STATUS = 'UPDATE_FETCH_EMAIL_STATUS';
export const UPDATE_FETCH_VISIT_STATUS = 'UPDATE_FETCH_VISIT_STATUS';
export const GET_VISIT_LIST = 'GET_VISIT_LIST';
export const UPDATE_VISIT_LIST = 'UPDATE_VISIT_LIST';
export const ADD_NEW_SUB_PACKAGE = 'ADD_NEW_SUB_PACKAGE';
export const ADDING_NEW_SUB_PACKAGE_STATUS = 'ADDING_NEW_SUB_PACKAGE_STATUS';
export const UPDATE_SUB_PACKAGE_LIST = 'UPDATE_SUB_PACKAGE_LIST';
export const DELETE_SUB_PACKAGE = 'DELETE_SUB_PACKAGE';
export const GET_SMS_TEMPLATE_LIST = 'GET_SMS_TEMPLATE_LIST';
export const GET_EMAIL_TEMPLATE_LIST = 'GET_EMAIL_TEMPLATE_LIST';
export const GET_LEAD_LIST = 'GET_LEAD_LIST';
export const LEAD_LIST_STATUS = 'LEAD_LIST_STATUS';
export const SENDING_SMS_STATUS = 'SENDING_SMS_STATUS';
export const SENDING_EMAIL_STATUS = 'SENDING_EMAIL_STATUS';
export const UPDATING_SUB_PACKAGE_STATUS = 'UPDATING_SUB_PACKAGE_STATUS';
export const LEAD_UPDATING_STATUS = 'LEAD_UPDATING_STATUS';
export const LOAD_LEAD_LOGS = 'LOAD_LEAD_LOGS';
export const LOAD_VENUEBUNDLE_NOTES = 'LOAD_VENUEBUNDLE_NOTES';
export const UPDATE_FETCH_VENUEBUNDLE_NOTES_STATUS = 'UPDATE_FETCH_VENUEBUNDLE_NOTES_STATUS';
export const ADD_VENUEBUNDLE_NOTE = 'ADD_VENUEBUNDLE_NOTE'
export const UPDATE_VENUEBUNDLE_NOTE = 'UPDATE_VENUEBUNDLE_NOTE'
export const REMOVE_VENUEBUNDLE_NOTE = 'REMOVE_VENUEBUNDLE_NOTE'
export const ADDING_NEW_VENUEBUNDLE_NOTE_STATUS = 'ADDING_NEW_VENUEBUNDLE_NOTE_STATUS';
export const NOTE_UPDATING_STATUS = 'NOTE_UPDATING_STATUS';
export const UPDATE_ADDING_VISIT_STATUS = 'UPDATE_ADDING_VISIT_STATUS'
export const UPDATE_REMOVING_VISIT_STATUS = 'UPDATE_REMOVING_VISIT_STATUS'
export const GET_PRIMARY_ASSETS_LIST = 'GET_PRIMARY_ASSETS_LIST'
export const UPDATE_FETCH_PRIMARY_ASSETS_STATUS = 'UPDATE_FETCH_PRIMARY_ASSETS_STATUS'
export const LEAD_MANAGE_MODAL_VISIBLE = 'LEAD_MANAGE_MODAL_VISIBLE'
export const SET_LEAD_ID = 'SET_LEAD_ID'
export const SHOW_FULL_VIEW_TAB_VISIBLE = 'SHOW_FULL_VIEW_TAB_VISIBLE'

export function updateFetchingLeadStatus(status) {
    return {
        type: UPDATE_FETCH_LEAD_STATUS,
        isFetchingLeadDetail: status,
    };
}

export function  setSelectedLeadId(leadId) {
    return (dispatch) => {
        dispatch({
            type: SET_LEAD_ID,
            selectedLeadId: leadId,
        });
    };
}

export function openLeadManageModalVisibleState(val,selectedTab) {
    return {
        type: LEAD_MANAGE_MODAL_VISIBLE,
        isLeadManageModalVisible: val,
        selectedTab: selectedTab
    };
}


export function showFullViewTabVisibleState(val) {
    return {
        type: SHOW_FULL_VIEW_TAB_VISIBLE,
        isFullViewTabvisible: val,
    };
}

export function loadLeadDetails(data) {
    return {
        type: GET_LEAD_DETAILS,
        lead: data.lead,
        isFetchingLeadDetail: false,
    };
}

export function fetchLeadDetails(leadId) {
    return (dispatch) => {
        dispatch(updateFetchingLeadStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/info`, 'GET')
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(loadLeadDetails(res.data));
                        return res.data;
                    } else {
                        dispatch(updateFetchingLeadStatus(false));
                    }
                })
            .catch((err) => {
            });
    };
}

export function updateFetchingSubPackageStatus(status) {
    return {
        type: UPDATE_FETCH_SUBPACKAGE_STATUS,
        isFetchingSubPackage: status,
    };
}

export function updateFetchingPrimaryAssetsStatus(status) {
    return {
        type: UPDATE_FETCH_PRIMARY_ASSETS_STATUS,
        isFetchingAssets: status,
    };
}

export function getSubPackageList(data, pageNum) {
    return {
        type: GET_SUBPACKAGE_LIST,
        subpackages: data.subpackages,
        isFetchingSubPackage: false,
    };
}

export function getPrimaryAssets(data) {
    return {
        type: GET_PRIMARY_ASSETS_LIST,
        packages: data.packages,
        menus: data.menus,
        contact: data.contact,
        isFetchingAssets: false,
    };
}

// export function clearPackageCache(venueId) {
//     return (dispatch) => {
//         return callEventApi(`api/v3/dashboard/venue/${venueId}/primary-assets/delete-cache`, 'get')
//             .then(
//                 (res) => {
//                     if (res.status === 'Success') {
//                         userNotify('Cache Cleared', 'success')
//                     } else {
//                         userNotify('Cache was not cleared', 'error')
//                     }
//                 })
//             .catch((err) => {
//                 console.log(err)
//             });
//     };
// }

export function fetchSubPackageList(eventId, venueId) {
    
        return (dispatch) => {
            dispatch(updateFetchingSubPackageStatus(true));
            return callEventApi(`api/v3/partner-dashboard/event/${eventId}/venue/${venueId}/user-packages`, 'get')
                .then(
                    (res) => {
                        if (res.status === 'Success') {
                            dispatch(getSubPackageList(res.data));
                        } else {
                            dispatch(updateFetchingSubPackageStatus(false));
                        }
                    })
                .catch((err) => {
                });
        };
}

export function fetchPrimaryAssets(venueId) {
    
    return (dispatch) => {
        dispatch(updateFetchingPrimaryAssetsStatus(true));
        return callEventApi(`api/v3/dashboard/venue/${venueId}/primary-assets`, 'get')
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(getPrimaryAssets(res.data));
                    } else {
                        dispatch(updateFetchingPrimaryAssetsStatus(false));
                    }
                })
            .catch((err) => {
            });
    };
}


export function getSmsTemplateList(data) {
    return {
        type: GET_SMS_TEMPLATE_LIST,
        smsTemplates: data.smstemplates,
        smsTemplateCount: data.totalCount,
    };
}

export function fetchSmsTemplateList(venueId) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/leads/venue/${venueId}/sms-template-list`, 'POST')
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(getSmsTemplateList(res.data));
                    } else {
                    }
                })
            .catch((err) => {
            });
    };
}

export function sendingSmsStatus(status) {
    return {
        type: SENDING_SMS_STATUS,
        isSendingSms: !!status,
    };
}

export function sendSms(leadId, sms) {
    return (dispatch) => {
        dispatch(sendingSmsStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/send-sms`, 'POST', { sms: sms })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(sendingSmsStatus(false));
                        userNotify('Sms Sent Successfully!', 'success');
                    } else {
                        dispatch(sendingSmsStatus(false));
                        userNotify('Failed!', 'error');
                    }
                })
            .catch((err) => {
                dispatch(sendingSmsStatus(false));

            });
    };
}


export function sendingEmailStatus(status) {
    return {
        type: SENDING_EMAIL_STATUS,
        isSendingEmail: !!status,
    };
}

export function sendEmail(leadId, email) {
    return (dispatch) => {
        dispatch(sendingEmailStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/send-email`, 'POST', { email: email })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(sendingEmailStatus(false));
                        userNotify('E-mail sent Successfully', 'success');
                    } else {
                        dispatch(sendingEmailStatus(false));
                        userNotify('Failed!', 'error');
                    }
                })
            .catch((err) => {
                dispatch(sendingEmailStatus(false));
            });
    };
}

export function addNewSubPackageToList(data) {
    return {
        type: ADD_NEW_SUB_PACKAGE,
        subpackage: data.subpackage,
        isAddingSubPackage: false,
    };
}

export function addingNewSubPackageStatus(status) {
    return {
        type: ADDING_NEW_SUB_PACKAGE_STATUS,
        isAddingSubPackage: !!status,
    };
}

// todo raghav
export function addNewSubPackage(eventId, bundleId, venueId, subpackage) {
    return async (dispatch) => {
        dispatch(addingNewSubPackageStatus(true));
        try {
            const res = await callEventApi(`api/v3/partner-dashboard/bundle/${bundleId}/venue/${venueId}/add-package`, 'POST', {
                subpackage: subpackage
            });
            if (res.status === 'Success') {
                dispatch(addingNewSubPackageStatus(false));
                // dispatch(addNewSubPackageToList(res.data ? res.data : {subpackage: subpackage}));
                dispatch(fetchSubPackageList(eventId, venueId))
                userNotify('Package Added!', 'success');
            } else {
                userNotify('Failed! 1', 'error');
                dispatch(addingNewSubPackageStatus(false));
            }
        } catch (err) {
            console.error(err);
        }
    };
}

export function cloneNewSubpackage(leadId ,eventId, venueId, packageId, newPackage) {
    return async (dispatch) => {
        dispatch(addingNewSubPackageStatus(true));
        try {
            const res = await callEventApi(`api/v3/partner-dashboard/event/${eventId}/venue/${venueId}/package/${packageId}/clone-package`, 'POST', {
                package: newPackage
            });
            if (res.status === 'Success') {
                dispatch(addingNewSubPackageStatus(false));
                // dispatch(addNewSubPackageToList(res.data ? res.data : {subpackage: subpackage}));
                dispatch(fetchSubPackageList(eventId, venueId))
                userNotify('Package Added!', 'success');
            } else {
                userNotify('Failed! 1', 'error');
                dispatch(addingNewSubPackageStatus(false));
            }
        } catch (err) {
            console.error(err);
        }
    };
}

export function updateSubPackageList(data) {
    return {
        type: UPDATE_SUB_PACKAGE_LIST,
        subpackage: data.subpackage,
        isUpdatingSubPackage: false,
    };
}

export function updatingSubPackageStatus(status) {
    return {
        type: UPDATING_SUB_PACKAGE_STATUS,
        isUpdatingSubPackage: !!status,
    };
}

// todo ragahv
export function updateSubPackage(subpackageId, subpackage, logId, agent) {
    return (dispatch) => {
        dispatch(updatingSubPackageStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/subpackage/${subpackageId}/update`, 'POST', {
            subpackage: subpackage,
        })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(updateSubPackageList(res.data));
                        // dispatch(fetchSubPackageList(res.data.subpackages[0].eventId, res.data.subpackages[0].venueId));
                        userNotify('Package Updated!', 'success');
                    } else {
                        dispatch(updatingSubPackageStatus(false));
                        userNotify('Failed! res status', 'error');
                    }
                })
            .catch((err) => {
                dispatch(updatingSubPackageStatus(false));
                userNotify('Failed!', 'error');
                console.log(err)
            });
    };
}

export function removeSubPackageFromList(data) {
    return {
        type: DELETE_SUB_PACKAGE,
        subpackage: data.subpackage,
    };
}

// todo raghav
export function deleteSubPackage(subpackageId, logId, agent) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/leads/subpackage/${subpackageId}/remove`, 'POST', {
            logId: logId,
            agent: agent,
        })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(removeSubPackageFromList(res.data));
                        userNotify('Package deleted!', 'success')
                    } else {
                        userNotify('Failed, try again!', 'error')
                    }
                })
            .catch((err) => {
                userNotify('Failed, try again!', 'error')
            });
    };
}

export function updateFetchingSmsStatus(status) {
    return {
        type: UPDATE_FETCH_SMS_STATUS,
        isFetchingSms: status,
    };
}

export function getSmsList(data, pageNum) {
    return {
        type: GET_SMS_LIST,
        smsList: data.smsList,
        smsCount: data.totalCount,
        isFetchingSms: false,
        smsPageNum: pageNum,
    };
}

export function fetchSmsList(eventId, filters) {
    return (dispatch) => {
        dispatch(updateFetchingSmsStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/event/${eventId}/past-sms-list`, 'POST', { filters: filters })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(getSmsList(res.data, filters.pageNum));
                    } else {
                        dispatch(updateFetchingSmsStatus(false));
                    }
                })
            .catch((err) => {
            });
    };
}

export function updateFetchingEmailStatus(status) {
    return {
        type: UPDATE_FETCH_EMAIL_STATUS,
        isFetchingEmails: status,
    };
}

export function getEmailList(data, pageNum) {
    return {
        type: GET_EMAIL_LIST,
        emailList: data.emailList,
        emailCount: data.totalCount,
        isFetchingEmails: false,
        emailPageNum: pageNum,
    };
}

export function fetchEmailList(eventId, filters) {
    return (dispatch) => {
        dispatch(updateFetchingEmailStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/event/${eventId}/past-email-list`, 'POST', { filters: filters })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(getEmailList(res.data, filters.pageNum));
                    } else {
                        dispatch(updateFetchingEmailStatus(false));
                    }
                })
            .catch((err) => {
            });
    };
}

export function getEmailTemplateList(data) {
    return {
        type: GET_EMAIL_TEMPLATE_LIST,
        emailTemplates: data.emailtemplates,
        emailTemplateCount: data.totalCount,
    };
}

export function fetchEmailTemplateList(venueId) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/leads/venue/${venueId}/email-template-list`, 'POST')
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(getEmailTemplateList(res.data));
                    } else {
                    }
                })
            .catch((err) => {
            });
    };
}

export function updateFetchingVisitStatus(status) {
    return {
        type: UPDATE_FETCH_VISIT_STATUS,
        isFetchingVisits: status,
    };
}

export function updateAddingVisitStatus(status) {
    return {
        type: UPDATE_ADDING_VISIT_STATUS,
        isAddingVisit: status,
    };
}

export function updateRemovingVisitStatus(status) {
    return {
        type: UPDATE_REMOVING_VISIT_STATUS,
        isRemovingVisit: status,
    };
}

export function getVisitList(data) {
    return {
        type: GET_VISIT_LIST,
        visits: data.visits,
        visitCount: data.totalCount,
        isFetchingVisits: false,
    };
}

export function fetchVisitList(eventId, venueId) {
    return (dispatch) => {
        dispatch(updateFetchingVisitStatus(true));
        return callEventApi(`api/v3/dashboard/event/${eventId}/venue/${venueId}/user-visits`, 'get')
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(getVisitList(res.data));
                    } else {
                        dispatch(updateFetchingVisitStatus(false));
                    }
                })
            .catch((err) => {
            });
    };
}

export function updateVisitList(data) {
    return {
        type: UPDATE_VISIT_LIST,
        visit: data.visit,
    };
}

// todo raghav
export function updateVisit(visitId, visit, agent, logId) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/leads/visit/${visitId}/update`, 'POST', {
            visit: visit,
            agent: agent,
            logId: logId,
        })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        userNotify('Visit Updated!', 'success');
                        dispatch(fetchVisitList(res.data.visit.eventId, res.data.visit.venueId));
                    } else {
                        userNotify(res.data.message ? res.data.message : 'Failed, try again', 'error');
                    }
                })
            .catch((err) => {
                userNotify('Failed, try again', 'error');
            });
    };
}

export function addVisit(eventId, venueId, visit) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/event/${eventId}/venue/${venueId}/plan-visit`, 'POST', {
            visit: visit,
        })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        userNotify('Visit Added!', 'success');
                        dispatch(fetchVisitList(eventId, venueId));
                    } else {
                        userNotify(res.data.message ? res.data.message : 'Failed, try again', 'error');
                    }
                })
            .catch((err) => {
                userNotify('Failed, try again', 'error');
            });
    };
}

export function removeVisit(eventId, venueId, visitId, visit) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/event/${eventId}/venue/${venueId}/visit/${visitId}/remove-visit`, 'get')
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        userNotify('Visit Removed Successfully!', 'success');
                        dispatch(fetchVisitList(eventId, venueId));
                    } else {
                        userNotify(res.data.message ? res.data.message :'Failed, try again', 'error');
                    }
                })
            .catch((err) => {
                userNotify('Failed, try again', 'error');
            });
    };
}


export function fetchLeadListStatus(status) {
    return {
        type: LEAD_LIST_STATUS,
        isFetchingLeads: status,
    };
}

export function getLeadList(data) {
    return {
        type: GET_LEAD_LIST,
        leads: data.leads,
        leadCount: data.totalCount,
        isFetchingLeads: false,
    };
}

export function fetchLeadList(venueId, filters) {
    return (dispatch) => {
        dispatch(fetchLeadListStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/venue/${venueId}/lead-list`, 'POST', { filters: filters })
            .then(
                (res) => {
                    if (res.status === 'Success') {
                        dispatch(getLeadList(res.data));
                    } else {
                        dispatch(fetchLeadListStatus(false));
                    }
                })
            .catch((err) => {
            });
    };
}

export function leadUpdatingStatus(status) {
    return {
        type: LEAD_UPDATING_STATUS,
        isUpdatingLead: !!status,
    };
}

export function saveVenueNote(leadId, lead, agent, logId) {
    return (dispatch) => {
        dispatch(leadUpdatingStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/update`, 'post', {
            lead: lead,
            agent: agent,
            logId: logId,
        })
            .then(res => {
                if (res.status === 'Success') {
                    dispatch(leadUpdatingStatus(false));
                    userNotify('Note Added!', 'success');
                } else {
                    dispatch(leadUpdatingStatus(false));
                    userNotify('failed to Update, try again', 'error');
                }
            });
    };
}

export function updateLeadStatusToClosedLost(leadId, lead, agent, logId) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/closed-lost`, 'post', {
            lead: lead,
            agent: agent,
            logId: logId,
        })
            .then(res => {
                if (res.status === 'Success') {
                    userNotify('Status Updated', 'success');
                } else {
                    userNotify('Failed to Update, try again', 'error');
                }
            });
    };
}

// todo raghav
export function updateLeadStatusToClosedWon(leadId, lead, agent, logId) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/closed-won`, 'post', {
            lead: lead,
            agent: agent,
            logId: logId,
        })
            .then(res => {
                if (res.status === 'Success') {
                    userNotify('Status Updated', 'success');
                } else {
                    userNotify('Failed to Update, try again', 'error');
                }
            });
    };
}

// todo raghav
export function updateOtherLeadStatus(leadId, lead, agent, logId) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/status-update`, 'post', {
            lead: lead,
            agent: agent,
            logId: logId,
        })
            .then(res => {
                if (res.status === 'Success') {
                    userNotify('Status Updated', 'success');
                } else {
                    userNotify('Failed to Update, try again', 'error');
                }
            });
    };
}

export function loadLeadLogs(data) {
    return {
        type: LOAD_LEAD_LOGS,
        leadLogs: data.logs,
    };
}

export function fetchLeadLogsByLeadId(leadId) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/log-info`, 'get')
            .then(res => {
                if (res.status === 'Success') {
                    dispatch(loadLeadLogs(res.data));
                } else {

                }
            });
    };
}

export function updateLead(leadId, lead, agent, logId) {
    return (dispatch) => {
        // dispatch(leadUpdatingStatus(true));
        return callEventApi(`api/v3/partner-dashboard/leads/lead/${leadId}/update`, 'post', {
            lead: lead,
            agent: agent,
            logId: logId,
        })
            .then(res => {
                if (res.status === 'Success') {
                    dispatch(loadLeadDetails(res.data));
                    dispatch(leadUpdatingStatus(false));
                    userNotify('Updated!', 'success');
                } else {
                    dispatch(leadUpdatingStatus(false));
                    userNotify('failed to Update, try again', 'error');
                }
            });
    };
}

export function loadVenueBundleNotes(data) {
    return {
        type: LOAD_VENUEBUNDLE_NOTES,
        venuebundlenotes: data.venuebundlenotes,
        venuebundlenotesCount: data.count
    };
}

export function fetchVenuebundleNotesByVenuebundleId(venuebundle_id, filters) {
    {
        return (dispatch) => {
            dispatch(updateFetchingVenueBundleNote(true));
            return callEventApi(`api/v3/partner-dashboard/venuebundle/${venuebundle_id}/list`, 'post', {filters: filters}).then(
                res => {
                    if (res.status === 'Success') {
                        dispatch(loadVenueBundleNotes(res.data));
                    } else {
                        // userNotify(res.data.message.toString(), 'error')
                    }
                }
            )
        }
    }
}

export function addVenueBundleNote(data) {
    return {
        type: ADD_VENUEBUNDLE_NOTE,
        venuebundlenote: data.venuebundlenote,
    };
}

export function addVenuebundleNotesByVenuebundleId(venuebundle_id, venuebundlenote) {
    return (dispatch) => {
        dispatch(addingNewVenueBundleNoteStatus(true));
        return callEventApi(`api/v3/partner-dashboard/venuebundle/${venuebundle_id}/add-note`, 'post', {venuebundlenote: venuebundlenote}).then(
            res => {
                if(res.status === 'Success') {
                    // dispatch(addVenueBundleNote(res.data));
                    dispatch(fetchVenuebundleNotesByVenuebundleId(venuebundle_id, {}))
                    userNotify('Added Note Successfully', 'success')
                } else {
                    // userNotify(res.data.message.toString(), 'error')
                }
            }
        )
    }
}

export function updateVenueBundleNote(data) {
    return {
        type: UPDATE_VENUEBUNDLE_NOTE,
        venuebundlenote: data,
    };
}

export function updateVenuebundleNotesByVenuebundleId(venuebundle_id, note_id, updatedvenuebundlenote) {
    return (dispatch) => {
        dispatch(updatingNewVenueBundleNoteStatus(true))
        return callEventApi(`api/v3/partner-dashboard/venuebundle/${venuebundle_id}/note/${note_id}/update-note`, 'post', updatedvenuebundlenote).then(
            res => {
                if(res.status === 'Success') {
                    dispatch(updateVenueBundleNote(res.data));
                    dispatch(fetchVenuebundleNotesByVenuebundleId(venuebundle_id, {}))
                    userNotify('Updated Note Successfully', 'success')
                } else {
                    // userNotify(res.data.message.toString(), 'error')
                }
            }
        )
    }
}

export function removeVenueBundleNote(data) {
    return {
        type: REMOVE_VENUEBUNDLE_NOTE,
        venuebundlenote: data.venuebundlenote,
    };
}

export function removeVenuebundleNotesByVenuebundleId(venuebundle_id, note_id, agent_id) {
    return (dispatch) => {
        return callEventApi(`api/v3/partner-dashboard/venuebundle/${venuebundle_id}/note/${note_id}/remove-note`, 'post', {agent_id: agent_id}).then(
            res => {
                if(res.status === 'Success') {
                    // dispatch(removeVenueBundleNote(res.data));
                    dispatch(fetchVenuebundleNotesByVenuebundleId(venuebundle_id, {}))
                    userNotify('Removed Note Successfully', 'success')
                } else {
                    // userNotify(res.data.message.toString(), 'error')
                }
            }
        )
    }
}

export function updateFetchingVenueBundleNote(status) {
    return {
        type: UPDATE_FETCH_VENUEBUNDLE_NOTES_STATUS,
        isFetchingVenueBundleNotes: status,
    };
}
export function addingNewVenueBundleNoteStatus(status) {
    return {
        type: ADDING_NEW_VENUEBUNDLE_NOTE_STATUS,
        isAddingVenueBundleNote: !!status,
    };
}
export function updatingNewVenueBundleNoteStatus(status) {
    return {
        type: NOTE_UPDATING_STATUS,
        isUpdatingVenueBundleNote: !!status,
    };
}

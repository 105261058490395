import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function userNotify(msg, msgtype) {
  switch (msgtype) {
    case 'warning':
      toast.warning(msg, { position: 'top-center', autoClose: 1000 });
      break;
    case 'error':
      toast.error(msg, { position: 'top-center', autoClose: 1000 });
      break;
    case 'info':
      toast.info(msg, { position: 'top-center', autoClose: 1000 });
      break;
    case 'success':
      toast.success(msg, { position: 'top-center', autoClose: 1000 });
      break;
    default:
      toast(msg, { position: 'top-center', autoClose: 1000 });
      break;
  }
}

class ToastBox extends React.Component {
  render() {
    const { notifications } = this.props;

    return (
      <div>
        <ToastContainer position="top-center" autoClose={2000} />
        {notifications.map((notification, index) => {
          const { message, level } = notification;
          switch (level) {
            case 'warning':
              toast.warning(message, { position: 'top-center', autoClose: 2000 });
              break;
            case 'error':
              toast.error(message, { position: 'top-center', autoClose: 2000 });
              break;
            case 'info':
              toast.info(message, { position: 'top-center', autoClose: 2000 });
              break;
            case 'success':
              toast.success(message, { position: 'top-center', autoClose: 2000 });
              break;
            default:
              toast(message, { position: 'top-center', autoClose: 2000 });
              break;
          }
          return null;
        })}
      </div>
    );
  }
}

ToastBox.propTypes = {
  notifications: PropTypes.array,
};

export default connect((state) => ({ notifications: state.toasts }))(ToastBox);

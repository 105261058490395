const { AWS_RAW_SOURCE_IMAGE_URL, AWS_SOURCE_IMAGE_URL, IMAGEKIT_SOURCE_IMAGE_URL, GUMLET_URL_BASE_URL } = require('constants/constants');
const _ = require('lodash');

function getImageKitUrl(rawUrl, height = null, width = null, formatStructure = null) {
    if (rawUrl) {
        let newImageKitUrl;
        let POST_HOST_URL;
        const AWS_RAW_URL = AWS_RAW_SOURCE_IMAGE_URL;
        const AWS_CDN_URL = AWS_SOURCE_IMAGE_URL;
        const IMAGEKIT_URL = IMAGEKIT_SOURCE_IMAGE_URL;
        const GUMLET_URL = GUMLET_URL_BASE_URL
        if (rawUrl?.startsWith(AWS_RAW_URL)) {
            newImageKitUrl = rawUrl.replace(AWS_RAW_URL, GUMLET_URL);
            POST_HOST_URL = rawUrl.split(AWS_RAW_URL)[1];
        } else if (rawUrl.startsWith(AWS_CDN_URL)) {
            newImageKitUrl = rawUrl.replace(AWS_CDN_URL, GUMLET_URL);
            POST_HOST_URL = rawUrl.split(AWS_CDN_URL)[1];
        } else if (rawUrl.startsWith(IMAGEKIT_URL)) {
            newImageKitUrl = rawUrl.replace(IMAGEKIT_URL, GUMLET_URL);
            POST_HOST_URL = rawUrl.split(AWS_CDN_URL)[1];
        } else if (rawUrl.startsWith(GUMLET_URL)) {
            newImageKitUrl = rawUrl.replace(GUMLET_URL, GUMLET_URL);
            POST_HOST_URL = rawUrl.split(GUMLET_URL)[1];
        } else {
            return rawUrl;
        }
  
        if (height === null && width === null) {
            return newImageKitUrl;
        }  else if (height !== null && width !== null) {
            // tr:w-300,h-360
            return `${GUMLET_URL}${POST_HOST_URL}?h=${height}&w=${width}`;
            // return `${IMAGEKIT_URL}/tr:vm${height}${width}${POST_HOST_URL}`;
        } else {
            return `${GUMLET_URL}${POST_HOST_URL}?w=${width}&h=${height}`;
            // return `${IMAGEKIT_URL}/tr:vm${height}${width},f-${formatStructure}${POST_HOST_URL}`;
        }
    } else {
        return null;
    }
}
const getLabelValue = (value) => {
    return (value && value !== '') ? value : 'venuemonk'
}

const getProspectColor = (prospect) => {
    switch (prospect) {
        case 'Hot':
            return '#FF4F61';

        case 'Cold':
            return '#0057FF';

        case 'Warm':
            return '#FFA800';
    }
};

const getBookingStatusColor = (status) => {
    switch (status) {
        case 'Closed - Won':
            return '#25BE50';

        case 'Closed - Lost':
            return '#FF3C3C';

        case 'Postponed':
            return '#F8B917';

        default:
            return '#25BE50'
    }
};

const getLabelStyle = (label) => {
    let style = {
        borderRadius: '500px',
        padding: '0px 8px',
        color: 'white',
        fontSize: '12px',
        height: '18px',
        marginLeft: '3px'
    }
    switch (label) {
        case 'venuemonk':
            style.backgroundColor = '#491369'
            break
        case 'venue':
            style.backgroundColor = '#2A52BB'
            break
        case 'corporate':
            style.backgroundColor = '#5CA829'
            break
        case 'new':
            style.backgroundColor = '#FF4F61'
            break
        case 'social booking':
            style.backgroundColor = '#C91A02'
            break
        default:
            style.backgroundColor = '#491369'
            break
    }
    return style
}

function splitName(name) {
    var model = {
        fname: "",
        lname: "",
    };
    var fullName = name.split(" ", 3);
    if (fullName && fullName.length > 0) {
        fullName.forEach(function (item, i) {
            if (i === 0) {
                model.fname = sentenceCase(item);
            } else {
                model.lname += sentenceCase(item) + " ";
            }
        });
    }
    model.lname = model.lname.trim();
    return model;
}

function sentenceCase(name) {
    return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
}

function groupAllData(data) {
    let temp = [];
    for (let a in data) {
        temp = temp.concat(data[a]);
    }
    return temp;
}

function removeDuplicateElements(events) {
    let newEvents = [];
    for (let i = 0; i < events.length; i++) {
        let flag = 0;
        if (events[i]) {
            for (let j = i + 1; j < events.length; j++) {
                if (events[i] === events[j]) {
                    flag = 1;
                    break;
                }
            }
            if (flag === 0) {
                newEvents.push(events[i]);
            }
        }
    }
    return newEvents;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function getUserInfo(user) {
    return {
        _id: user._id,
        username: user.local.name,
        email: user.local.email,
        phone: user.phone,
        access_level: user.access_level,
        user_type: user.user_type,
        venue_list: user.venue_list
    };
}

function isValidIndianMobile(phone) {
    let newPhone = _.toString(phone);
    return newPhone && newPhone.length === 10 && (newPhone[0] === '9' || newPhone[0] === '8' || newPhone[0] === '7' || newPhone[0] === '6');
}

// sorts in descending order
function sortByKey(array, key) {
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? 1 : ((x > y) ? -1 : 0));
    });
}

function hideMobileNumber(phone) {
    if (phone) {
        let strings = _.toString(phone).split('');
        if (strings && strings.length > 4) {
            strings = _.map(strings, function (item, index) {
                if (index === 2 || index === 3 || index === 5 || index === 7 || index === 8) {
                    return 'X';
                } else {
                    return item;
                }
            });
            return strings.join('');
        } else {
            return '';
        }
    } else {
        return '';
    }
}

function hideHalfChars(string, odd) {
    if (string && string !== '') {
        let strings = _.toString(string).split('');
        if (odd) {
            strings = _.map(strings, function (item, index) {
                if (index % 2 !== 0) {
                    return 'x';
                } else {
                    return item;
                }
            });
        } else {
            strings = _.map(strings, function (item, index) {
                if (index % 2 === 0) {
                    return 'x';
                } else {
                    return item;
                }
            });
        }
        return strings.join('');
    } else {
        return string;
    }
}

function hideEmailAddress(email) {
    if (email) {
        let strings = _.toString(email).toLowerCase().split('.');
        let ext = strings.pop();
        strings = _.toString(strings.join('.')).split('@');
        if (strings && strings.length > 1) {
            let firstPart = hideHalfChars(strings[0], 1);
            let secondPart = hideHalfChars(strings[1], 0);
            return firstPart + "@" + secondPart + "." + ext;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

function bool(str) {
    if (str === void 0) return false;
    return str.toLowerCase() === 'true';
}

function getPages(count, pagesize) {
    if (count > 0) {
        return Math.ceil(count / pagesize);
    } else {
        return 0;
    }
}

module.exports = {
    splitName: splitName,
    sentenceCase: sentenceCase,
    removeDuplicateElements: removeDuplicateElements,
    groupAllData: groupAllData,
    capitalizeFirstLetter: capitalizeFirstLetter,
    randomIntFromInterval: randomIntFromInterval,
    getUserInfo: getUserInfo,
    isValidIndianMobile: isValidIndianMobile,
    sortByKey: sortByKey,
    hideMobileNumber: hideMobileNumber,
    hideEmailAddress: hideEmailAddress,
    bool: bool,
    getProspectColor: getProspectColor,
    getLabelStyle: getLabelStyle,
    getBookingStatusColor: getBookingStatusColor,
    getPages: getPages,
    getLabelValue: getLabelValue,
    getImageKitUrl:getImageKitUrl
};

import callApi from '../../util/apiCaller';
import idCallApi from '../../util/idApiCaller'
import { userNotify } from '../../components/Toasts/ToastAdd';

export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export function requestForgotPassword() {
  return {
    type: REQUEST_FORGOT_PASSWORD,
    isFetching: true,
  };
}

export function sendFailedResponse(message) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    isFetching: false,
    message: message,
  };
}

export function sendSuccessResponse(message) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    isFetching: false,
    message: message,
  };
}

export function requestResetPassword() {
  return {
    type: REQUEST_RESET_PASSWORD,
    isFetching: true,
  };
}

export function sendResetFailedResponse(message) {
  return {
    type: RESET_PASSWORD_FAILURE,
    isFetching: false,
    message: message,
  };
}

export function sendResetSuccessResponse(message) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    isFetching: false,
    message: message,
  };
}

export function forgotUserPassword(creds) {
  return (dispatch) => {
    dispatch(requestForgotPassword());
    return idCallApi(`api/auth/partner/forgot-password`, 'post', creds)
      .then(res => {
          if(res.status === 'Success') {
              if(res.data) {
                  userNotify(res.data.message ? res.data.message : 'Link sent in your mail id...!', 'success');
                  dispatch(sendSuccessResponse(res.data.message ? res.data.message : 'Link sent in your mail id...!', 'success'));
              }
          } else {
              dispatch(sendFailedResponse(''));
              userNotify('', 'warning');
          }

        // const { userExists, message,notActive } = res;
        // if (userExists&&!notActive) {
        //   userNotify('Reset link mailed to you', 'success');
        //   dispatch(sendSuccessResponse(message));
        // } else if (!userExists&&notActive) {
        //   dispatch(sendFailedResponse(message));
        //   userNotify(message, 'warning');
        //   return Promise.reject(message);
        // }else {
        //   dispatch(sendFailedResponse(message));
        //   userNotify('You don\'t have account with this E-mail', 'warning');
        //   return Promise.reject(message);
        // }
      });
  };
}

export function getUserByUserToken(id) {
  return (dispatch) => {
    return callApi(`auth/reset/${id}`, 'get')
      .then(res => {
        const { userExists, message } = res;
        if (!userExists) {
          dispatch(sendResetFailedResponse(message));
        } else {
          dispatch(sendResetSuccessResponse(message));
        }
      });
  };
}

export function resetPasswordForUserToken(data) {
  return (dispatch) => {
    dispatch(requestResetPassword());
    return callApi(`auth/reset/${data.id}`, 'post', {
      password: data.password,
    })
      .then(res => {
        const { isReset, message } = res;
        if (!isReset) {
          dispatch(sendResetFailedResponse(message));
          return Promise.reject(message);
        }
        userNotify('Password reset successfully', 'success');
        dispatch(sendResetSuccessResponse(message));
        window.location = '/login?reset=ok';
      });
  };
}

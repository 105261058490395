import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import {withRoute} from "util/withRoute";
import {Tab, Tabs} from "react-bootstrap";

import {
    fetchLeadDetails,
    fetchLeadLogsByLeadId,
    fetchVenuebundleNotesByVenuebundleId,
    setSelectedLeadId,
} from "../LeadManageActions";
import {getLeadManageSelectedTab} from "../LeadManageReducer";
import {fetchClosedLostReasonList, fetchLeadStatusList, updateLeadListToLeadManagePath,} from "../../App/AppActions";
import PageAccessControl from "../../../components/PermissionLocks/PageAccessControl";
import '../../App/App.css'
import BasicDetailPage from "./BasicDetailPage/BasicDetailPage";
import PackageListPage from "./PackageListPage/PackageListPage";
import VisitPage from "./VisitPage/VisitPage";
import {openLeadManageModalVisibleState} from "../LeadManageActions";
import {getLeadManageParentPath, getPartnerAccount} from "../../App/AppReducer";
import Breadcrumb from "../components/Beadcrumb";
import '../../App/App.css'
import { padding } from "styled-system";

class LeadManagePage extends Component {

    // componentDidMount() {
    //     let leadId = this.props.leadId;
    //     this.loadLeadDetails(leadId);
    // }

    // componentDidUpdate(prevProps) {
    //     if (prevProps && this.props && prevProps.leadId !== this.props.leadId) {
    //         this.loadLeadDetails(this.props.leadId);
    //     }
    // }

    loadLeadDetails = (leadId) => {
        
        this.props.dispatch(updateLeadListToLeadManagePath(true, 1));
        this.props.dispatch(setSelectedLeadId(leadId));
        // this.props.dispatch(openLeadManageModalVisibleState(true, 1))
        this.props.navigate(`/event/detail`)
    }

    handleTabChange = (key) => {
        this.props.dispatch(openLeadManageModalVisibleState(true, key));
    };

    render() {
        const {selectedTab} = this.props;
        return (
            <PageAccessControl
                shouldHavePermissions={["leads:read"]}
                userPermissions={
                    this.props.partneraccount.permissions
                        ? this.props.partneraccount.permissions
                        : []
                }
            >
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%',padding:'20px 20px 0px 20px'}}>
                    <div style={{width:'100%'}}>
                      <Breadcrumb
                        isLeadManageFromLeadList={this.props.isLeadManageFromLeadList}
                        contact_name={""}
                    />
                    </div>
                    {/* <div>
                    <button
                    style={{padding:'8px',backgroundColor:'#004ad9',borderRadius:'4px',fontSize:'12px',height:'35px',border:'none',color:'white',marginRight:'50px'}}
                    onClick={this.loadLeadDetails}
                    >TabbedPage</button>
                    </div> */}
                 </div>   
                <div className={"lead-manage-body-container-leadmanage"}>
              
                    <Tabs activeKey={selectedTab} onSelect={this.handleTabChange} style={{width: '100%'}}>
                        <Tab title="Basic Details" eventKey={1}>
                            <BasicDetailPage
                                viewMode={"FullPage"}
                            />
                        </Tab>
                        <Tab title="Package" eventKey={2}>
                            <PackageListPage
                                viewMode={"FullPage"}
                            />
                        </Tab>
                        <Tab title="Visit" eventKey={3}>
                            <VisitPage
                                viewMode={"FullPage"}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </PageAccessControl>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        leadId: props.params?.leadId,
        selectedTab: getLeadManageSelectedTab(state),
        partneraccount: getPartnerAccount(state),
        isLeadManageFromLeadList: getLeadManageParentPath(state),
    };
}

LeadManagePage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.object,
};

export default connect(mapStateToProps)(withRoute(LeadManagePage));


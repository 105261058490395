import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import isNumeric from 'validator/lib/isNumeric';
import SpinnerLoader from '../../../../../components/Loader/Spinner';
import _ from 'lodash'
import {Checkbox, Input} from "antd";
import {cloneNewSubpackage} from "../../../LeadManageActions";

class AddPackage extends Component{
  constructor(props){
    super(props);
    this.state = {
      package: {},
      menu: []
    };
  }

  componentDidMount() {
      this.setState({
        package: {...this.props.package},
        menu: [...this.props.menu]
      }, () => {
        this.handlePrapareMenu(this.state.menu);
      })
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(this.props, prevProps)) {
      let menu = this.props.menu ? [...this.props.menu] : [];
      this.setState({
        package: this.props.package,
        menu: menu
      }, () => {
        this.handlePrapareMenu(menu);
      })
    }
  }

  handlePrapareMenu = (menu) => {
    const newMenu = menu.map(item => {
      const newItem = { ...item }; // Create a new object
      if (newItem.count && newItem.count > 0) {
        newItem.isSelected = true;
        newItem.count = newItem.count;
      } else {
        newItem.isSelected = false;
        newItem.count = 0;
      }
      return newItem;
    });

    this.setState({
      menu: newMenu
    });
  };

  handleMethodOfContactChange = () => {

  };


  checkPackageValidation = (pack) => {
    let isValid = true;
    if(!!!pack.name || pack.name === '') {
      isValid = false;
      this.props.showMessage('Please Provide Package Name.', 'warning');
    } else if(!!!pack.package_price || pack.package_price === '') {
      isValid = false;
      this.props.showMessage('Please Provide Package price', 'warning');
    } else if(!isNumeric(pack.package_price?.toString())) {
      isValid = false;
      this.props.showMessage('Please Enter Numeric Package price', 'warning');
    } else if(!!!pack.menuItems || pack.menuItems.length === 0) {
      isValid = false;
      this.props.showMessage('Please Select Some Menu Items', 'warning');
    }
    return isValid;
  };

  handleAddPackage = () => {
    try {
      const newPackage = { ...this.state.package }; // Create a new package object
      newPackage.menuItems = this.state.menu.filter(item => item.isSelected === true).map(item => {
        const menu = { ...item }; // Create a new menu item object
        menu.count = item.count;
        menu.menuId = item._id;
        return menu;
      });
      newPackage.eventId = this.props.eventId;
      newPackage.venueId = this.props.venueId;
      newPackage.isCreatedByVenue = true;
      newPackage.isCreatedByAdmin = false;
      if (this.checkPackageValidation(newPackage)) {
        this.props.addPackage(newPackage);
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleIncrementMenuItem = (id) => {
    const menu = this.state.menu.map(item => {
      if (item._id === id) {
        const newItem = { ...item }; // Create a new menu item object
        if (newItem.count === 0) {
          newItem.isSelected = true;
        }
        if (newItem.count) {
          if (newItem.count < newItem.count) {
            newItem.count = newItem.count + 1;
          } else {
            this.props.showMessage('Max count reached', 'warning');
          }
        } else {
          newItem.count = newItem.count + 1;
        }
        return newItem;
      }
      return item;
    });

    this.setState({
      menu: menu
    });
  };

  handleDecrementMenuItem = (id) => {
    const menu = this.state.menu.map(item => {
      if (item._id === id) {
        const newItem = { ...item }; // Create a new menu item object
        if (newItem.count > 0) {
          newItem.count = newItem.count - 1;
        }
        if (newItem.count === 0) {
          newItem.isSelected = false;
        }
        return newItem;
      }
      return item;
    });

    this.setState({
      menu: menu
    });
  };

  handleSelectMenuItem = (id, value) => {
    const menu = this.state.menu.map(item => {
      if (item._id === id) {
        const newItem = { ...item }; // Create a new menu item object
        newItem.isSelected = !value;
        if (newItem.isSelected) {
          newItem.count = 1;
        } else {
          newItem.count = 0;
        }
        return newItem;
      }
      return item;
    });

    this.setState({
      menu: menu
    });
  };

  handleAddProposedPackage = (proposedPackage) => {
    if (this.checkPackageValidation(proposedPackage)) {
      if(this?.props?.lead?._id && this.props.lead.event && this.props.venueId && proposedPackage._id) {
        this.props.dispatch(cloneNewSubpackage(this.props.lead._id ,this.props.lead.event , this.props.venueId, proposedPackage._id, proposedPackage ));
      }
    }
  }

  render() {
    let staticContent = this.props.staticContent;
    let packages = this.props.packages
    return (
      <div className="col-sm-12" style={{padding: 0}}>
        <div className="row" style={{marginTop: '10px'}}>
            <p style={{marginTop: '10px', fontSize: "18px"}}>{staticContent?.PACKAGE_PAGE_PROPOSED_PACKAGES}</p>
          {packages && packages.map(item => {
            return (
                <div key={item._id} style={{width: '80%', display: 'flex', marginBottom: '8px', gap: '10px', alignItems: "center"}}>
                  <span style={{fontSize: '16px', width: '20%'}}>{item.name && _.startCase(item.name)}</span>
                  <span style={{fontSize: '16px', width: '20%'}}>{item.package_price && item.package_price}</span>
                  <span style={{marginRight: '30px'}}>
                    {
                      item.menuItems.map((menuItem, index) =>
                        <span key={index} style={{width: '40%'}}>
                          <span style={{marginRight: '4px'}}>
                            {menuItem?.name}
                          </span>
                          <span style={{marginRight: '10px'}}>
                            {menuItem?.count}
                            </span>
                        </span>
                      )
                    }
                  </span>
                  <Button onClick={() => this.handleAddProposedPackage(item)} style={{fontSize: '24px', fontWeight: 500, padding: '0px 10px'}}>
                    +
                  </Button>
                </div>
            )
              })}
          <div style={{marginTop: '10px', fontSize: "18px"}}>{staticContent.PACKAGE_PAGE_PACKAGE_ADD_CUSTOM_PACKAGE}</div>
          <div className="col-6" style={{position: 'relative', display: 'block', marginTop: '10px'}}>
               <label>{staticContent.PACKAGE_PAGE_PACKAGE_NAME_LABEL ? staticContent.PACKAGE_PAGE_PACKAGE_NAME_LABEL : 'Package Name'}</label>
               <Input onChange={(e) => {
                 let newPackage = {...this.state.package}
                 newPackage.name = e.target.value
                 this.setState({
                   package: newPackage
                 })
               }} ref={(ref) => this.packageName = ref} placeholder={staticContent.PACKAGE_PAGE_PACKAGE_NAME_LABEL ? staticContent.PACKAGE_PAGE_PACKAGE_NAME_LABEL : 'Package Name'} value={this?.state?.package?.name ? this.state.package.name : ''} />
            {/*<label >{staticContent.PACKAGE_PAGE_PACKAGE_NAME_LABEL}</label>*/}
            {/*<div className="input-group">*/}
            {/*  <input*/}
            {/*    type="text"*/}
            {/*    defaultValue={this.state.package ? this.state.package.name : ''}*/}
            {/*    className="form-control"*/}
            {/*    aria-label="Phone"*/}
            {/*    aria-describedby="userSms-addon"*/}
            {/*    ref = {(ref) => this.packageName = ref}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
          <div className="col-6" style={{position: 'relative', display: 'block', marginTop: '10px'}}>
            <label>{staticContent.PACKAGE_PAGE_PACKAGE_PRICE_LABEL}</label>
            <Input min={0} type={'number'} onChange={(e) => {
              let newPackage = {...this.state.package}
              newPackage.package_price = e.target.value
              this.setState({
                package: newPackage
              })
            }} placeholder={staticContent.PACKAGE_PAGE_PACKAGE_PRICE_LABEL} value={this.state.package ? this.state.package.package_price : ''} />

            {/*<label >{staticContent.PACKAGE_PAGE_PACKAGE_PRICE_LABEL}</label>*/}
            {/*<div className="input-group">*/}
            {/*  <input*/}
            {/*    type="text"*/}
            {/*    defaultValue={this.state.package ? this.state.package.package_price : ''}*/}
            {/*    className="form-control"*/}
            {/*    aria-label="Phone"*/}
            {/*    aria-describedby="userSms-addon"*/}
            {/*    ref = {(ref) => this.packagePrice = ref}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>

        <div style={{position: 'relative', display: 'block', marginTop: '25px'}}>
          <p>{staticContent.PACKAGE_PAGE_PACKAGE_MENU_TITLE}</p>
          <div style={{position: 'relative', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            {
              this.state.menu && this.state.menu.length > 0 && this.state.menu.map(item => (
                <div key={item._id} style={{position: 'relative', display: 'flex', justifyContent: 'space-between', marginTop: '5px', width: '48%'}}>
                  <Checkbox checked={item.isSelected && item.isSelected} onChange={() => this.handleSelectMenuItem(item._id, item.isSelected)}  />
                  <span>{item.name}</span>
                  {
                    item.isSelected && item.count > 0 ?
                      <span  style={{border: '1px solid #D6D6D6', borderRadius: '3px', fontSize: '12px', display: 'flex', lineHeight: '1.7'}}>
                      <span style={{padding: '0px 7px', color: '#0057FF', cursor: 'pointer'}} onClick={() => this.handleDecrementMenuItem(item._id)}>-</span>
                      <span style={{padding: '0px 7px', backgroundColor: '#f8f8f8'}}>{item.count}</span>
                      <span style={{padding: '0px 7px', color: '#0057FF', cursor: 'pointer'}} onClick={() => this.handleIncrementMenuItem(item._id)} >+</span>
                    </span> : null
                  }
                  {
                    item.count === 0 ?
                      <span onClick={() => this.handleIncrementMenuItem(item._id)} style={{cursor: 'pointer', border: '1px solid #D6D6D6', borderRadius: '3px', color: '#737373', fontSize: '12px', display: 'flex', lineHeight: '1.7'}}>
                              <span style={{padding: '0px 7px', marginRight: '3px'}}>{'Add'}</span>
                              <span style={{padding: '0px 7px', color: '#0057FF'}}>+</span>
                           </span>: null
                  }
                  {/*{*/}
                  {/*  !item.isSelected ?*/}
                  {/*    <span style={{border: '1px solid #D6D6D6', borderRadius: '3px', fontSize: '12px', display: 'flex', lineHeight: '1.7', cursor: 'pointer'}}>*/}
                  {/*          <span style={{padding: '0px 7px', marginRight: '3px', color: '#737373'}}>{'Add'}</span>*/}
                  {/*          <span style={{padding: '0px 7px', color: '#0057FF', opacity: 0.6}}>+</span>*/}
                  {/*    </span> : null*/}
                  {/*}*/}
                </div>
              ))
            }
          </div>
        </div>


        <div style={{position: 'relative',  display: 'block', marginTop: '35px'}}>
          <Button disabled={this.props.isAddingSubPackage} className="btn btn-primary pull-right" style={{width: '200px'}} onClick={() => this.handleAddPackage(this.state.package)} >{this.props.isAddingSubPackage ? <SpinnerLoader/> : staticContent.PACKAGE_PAGE_PACKAGE_SAVE_BUTTON_TEXT}</Button>
        </div>
      </div>
    );
  }
}

export default AddPackage;

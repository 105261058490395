import fetch from 'isomorphic-fetch';
import moment from 'moment';
import {CURRENT_STAGE, ENV_PRODUCTION} from "../constants/enum";

const API_URL = process.env.REACT_APP_EVENT_API_URL || `https://event-api.venuemonk.com`
const CORE_URL = process.env.REACT_APP_CORE_API_URL || `https://core-api.venuemonk.com`


export default function callEventApi(endpoint, method = 'get', body) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    // headers['api-key'] = '1c052eb1-010c-4c38-807c-80c29bd78764';
    // if (body.apiKey) {
    //     headers['api-key'] = body.apiKey;
    // }
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${API_URL}/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}


export function callEventApiWithAPIKey(endpoint, method = 'get', body, apiKey) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (apiKey) {
        headers['api-key'] = apiKey;
    }
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${API_URL}/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}

export function callCoreApi(endpoint, method = 'get', body) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${CORE_URL}/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}

export function callEventApiAndCache(endpoint, method = 'get', body, expireHour) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    let cacheResponse = window.localStorage.getItem(`event-api/${endpoint}`);
    if (cacheResponse) {
        let parsedResponse = JSON.parse(cacheResponse);
        if (parsedResponse.expires && moment(parsedResponse.expires).isAfter(moment())) {
            return Promise.resolve(parsedResponse.data);
        }
    }
    return fetch(`${API_URL}/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            let cachedResponse = {
                data:  json,
                expires: moment().startOf('hour').add(expireHour ? expireHour : 1, 'hour').toISOString()
            }
            window.localStorage.setItem(`api/${endpoint}`, JSON.stringify(cachedResponse));
            return json;
        })
        .then(
            response => response,
            error => error
        );
}


import { FETCH_BOOKING_LIST_STATUS,
  LOAD_BOOKING_LIST
} from './BookingActions';

const initialState = {
  bookingList: [],
  isFetchingBookings: false,
  bookingPageNum: 1,
  bookingPageSize: 15,
  bookingCount: 0
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_BOOKING_LIST_STATUS:
      return {
        ...state,
        isFetchingBookings: action.isFetchingBookings
      };
    case LOAD_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.bookingList,
        isFetchingBookings: action.isFetchingBookings,
        bookingCount: action.bookingCount,
        bookingPageNum: action.bookingPageNum,
      };

    default:
      return {
        ...state,
      };
  }
};

export const getBookingList = (state) => state.booking.bookingList;
export const getBookingListFetchingStatus = (state) => state.booking.isFetchingBookings;
export const getBookingPageNum = (state) => state.booking.bookingPageNum;
export const getBookingPageSize = (state) => state.booking.bookingPageSize;
export const getBookingsTotalCount = (state) => state.booking.bookingCount;


export default bookingReducer;

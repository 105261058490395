import { useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';

export const withRoute = (Component) => {
  const Wrapper = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWidthChange = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWidthChange)


    const context = useOutletContext()
    const navigate = useNavigate();
    const params = useParams()
    const location = useLocation()

    return (
      <Component
        context={context}
        windowWidth={windowWidth}
        location={location}
        navigate={navigate}
        params={params}
        {...props}
        />
    );
  };
  return Wrapper;
};
import React from 'react';

export function VmIcon(props) {
  return (
    <svg viewBox="0 0 25 25" className={props.targetClass ? props.targetClass : 'no-class'}>
      <use xlinkHref={`#${props.iconName}`} />
    </svg>
  );
}

export default VmIcon;

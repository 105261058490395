import callApi from '../../util/apiCaller';
import { userNotify } from '../../components/Toasts/ToastAdd';

export const FETCH_MENU_STATUS = 'FETCH_MENU_STATUS';
export const LOAD_MENU_LIST = 'LOAD_MENU_LIST';
export const FETCH_PACKAGE_STATUS = 'FETCH_PACKAGE_STATUS';
export const LOAD_PACKAGE_LIST = 'LOAD_PACKAGE_LIST';
export const FETCH_VENUE_INFO_STATUS = 'FETCH_VENUE_INFO_STATUS';
export const LOAD_VENUE_INFO = 'LOAD_VENUE_INFO';
export const FETCH_CLONE_VENUE_INFO_STATUS = 'FETCH_CLONE_VENUE_INFO_STATUS';
export const LOAD_CLONE_VENUE_INFO = 'LOAD_CLONE_VENUE_INFO';
export const UPDATE_CLONE_VENUE_INFO_STATUS = 'UPDATE_CLONE_VENUE_INFO_STATUS';
export const UPDATE_CLONE_VENUE_INFO = 'UPDATE_CLONE_VENUE_INFO';
export const FETCH_CLONE_PACKAGE_STATUS = 'FETCH_CLONE_PACKAGE_STATUS';
export const LOAD_CLONE_PACKAGE_LIST = 'LOAD_CLONE_PACKAGE_LIST';
export const FETCH_CLONE_MENU_STATUS = 'FETCH_CLONE_MENU_STATUS';
export const LOAD_CLONE_MENU_LIST = 'LOAD_CLONE_MENU_LIST';
export const UPDATE_CLONE_MENU_STATUS = 'UPDATE_CLONE_MENU_STATUS';
export const UPDATE_CLONE_MENU_TO_LIST = 'UPDATE_CLONE_MENU_TO_LIST';
export const UPDATE_CLONE_PACKAGE_STATUS = 'UPDATE_CLONE_PACKAGE_STATUS';
export const UPDATE_CLONE_PACKAGE_TO_LIST = 'UPDATE_CLONE_PACKAGE_TO_LIST';
export const DELETE_CLONE_PACKAGE_STATUS = 'DELETE_CLONE_PACKAGE_STATUS';
export const DELETE_CLONE_PACKAGE = 'DELETE_CLONE_PACKAGE';
export const DELETE_CLONE_MENU_STATUS = 'DELETE_CLONE_MENU_STATUS';
export const DELETE_CLONE_MENU_FROM_LIST = 'DELETE_CLONE_MENU_FROM_LIST';
export const ADD_CLONE_MENU_STATUS = 'ADD_CLONE_MENU_STATUS';
export const ADD_CLONE_MENU_TO_LIST = 'ADD_CLONE_MENU_TO_LIST';
export const ADD_CLONE_PACKAGE_STATUS = 'ADD_CLONE_PACKAGE_STATUS';
export const ADD_CLONE_PACKAGE_TO_LIST = 'ADD_CLONE_PACKAGE_TO_LIST';
export const UPDATE_CLONE_PACKAGE_ACTIVE_STATUS = 'UPDATE_CLONE_PACKAGE_ACTIVE_STATUS';
export const UPDATE_CLONE_MENU_ACTIVE_STATUS = 'UPDATE_CLONE_MENU_ACTIVE_STATUS';

export function isFetchingMenu(status) {
  return {
    type: FETCH_MENU_STATUS,
    isFetchingMenu: status
  };
}

export function loadMenuList(data) {
  return {
    type: LOAD_MENU_LIST,
    menuList: data.menus,
    menuCount: data.totalCount,
    isFetchingMenu: false
  };
}

export function fetchMenuList(venueid) {
  return (dispatch) => {
    dispatch(isFetchingMenu(true));
    callApi(`venues/venue/${venueid}/menu-list`, 'POST').then((res) => {
      if(res.status === 'Success'){
        dispatch(loadMenuList(res.data));
      } else {
        dispatch(isFetchingMenu(false));
      }
    }).catch(() => {});
  };
}

export function showFetchingPackageLoader(status) {
  return {
    type: FETCH_PACKAGE_STATUS,
    isFetchingPackage: !!status
  };
}

export function loadPackageList(data) {
  return {
    type: LOAD_PACKAGE_LIST,
    packageList: data.packages,
    packageCount: data.totalCount,
    isFetchingPackage: false
  };
}

export function fetchPackageList(venueid) {
  return (dispatch) => {
    dispatch(showFetchingPackageLoader());
    callApi(`venues/venue/${venueid}/package-list`, 'POST', {}).then((res) => {
      if(res.status === 'Success'){
        dispatch(loadPackageList(res.data));
      } else {
        dispatch(showFetchingPackageLoader(false));
      }
    }).catch(() => {});
  };
}

export function showFetchingVenueInfoLoader(val) {
  return {
    type: FETCH_VENUE_INFO_STATUS,
    isFetchingVenueInfo: !!val
  };
}

export function loadVenueInfo(data) {
  return {
    type: LOAD_VENUE_INFO,
    venueInfo: data.venue,
    isFetchingVenueInfo: false
  };
}

export function fetchVenueInfo(venueid) {
  return (dispatch) => {
    dispatch(showFetchingVenueInfoLoader(true));
    callApi(`venues/venue/${venueid}/info`, 'get').then((res) => {
      if(res.status === 'Success'){
        dispatch(loadVenueInfo(res.data));
      } else {
        dispatch(showFetchingVenueInfoLoader(false));
      }
    }).catch(() => {});
  };
}

export function showFetchingCloneVenueInfoStatus(val) {
  return {
    type: FETCH_CLONE_VENUE_INFO_STATUS,
    isFetchingCloneVenueInfo: !!val
  };
}

export function loadCloneVenueInfo(data) {
  return {
    type: LOAD_CLONE_VENUE_INFO,
    cloneVenueInfo: data.venue,
    isFetchingCloneVenueInfo: false
  };
}

export function fetchCloneVenueInfo(venueid) {
  return (dispatch) => {
    dispatch(showFetchingCloneVenueInfoStatus(true));
    callApi(`venues/venue/${venueid}/clone-info`, 'get').then((res) => {
      if(res.status === 'Success'){
        dispatch(loadCloneVenueInfo(res.data));
      } else {
        dispatch(showFetchingCloneVenueInfoStatus(false));
      }
    }).catch(() => {});
  };
}

export function updatingCloneVenueInfoStatus(status) {
  return {
    type: UPDATE_CLONE_VENUE_INFO_STATUS,
    isUpdatingCloneVenueInfo: !!status
  };
}

export function loadUpdatedCloneVenueInfo(data) {
  return {
    type: UPDATE_CLONE_VENUE_INFO,
    cloneVenueInfo: data.venue,
    isUpdatingCloneVenueInfo: false
  };
}

export function updateCloneVenueInfo(clonevenueid, venue, userId) {
  return (dispatch) => {
    dispatch(updatingCloneVenueInfoStatus(true));
    callApi(`venues/clonevenue/${clonevenueid}/update`, 'POST', {
      venue: venue,
      userId: userId
    }).then((res) => {
      if(res.status === 'Success'){
        userNotify('Updated!', 'success');
        dispatch(loadUpdatedCloneVenueInfo(res.data));
      } else {
        userNotify('Failed! to update, try again', 'error');
        dispatch(updatingCloneVenueInfoStatus(false));
      }
    }).catch(() => {
        userNotify('Failed! to update, try again', 'error');
    });
  };
}

export function fetchingClonePackageListStatus(status) {
  return {
    type: FETCH_CLONE_PACKAGE_STATUS,
    isFetchingClonePackageList: status
  };
}

export function loadClonePackageList(data) {
  return {
    type: LOAD_CLONE_PACKAGE_LIST,
    clonePackageList: data.packages,
    clonePackageCount: data.totalCount,
    isFetchingClonePackageList: false
  };
}

export function fetchClonePackageList(venueid) {
  return (dispatch) => {
    dispatch(fetchingClonePackageListStatus(true));
    callApi(`venues/venue/${venueid}/clone-package-list`, 'POST').then((res) => {
      if(res.status === 'Success'){
        dispatch(loadClonePackageList(res.data));
      } else {
        dispatch(fetchingClonePackageListStatus(false));
      }
    }).catch(() => {});
  };
}

export function fetchingCloneMenuListStatus(status) {
  return {
    type: FETCH_CLONE_MENU_STATUS,
    isFetchingCloneMenuList: status
  };
}

export function loadCloneMenuList(data) {
  return {
    type: LOAD_CLONE_MENU_LIST,
    cloneMenuList: data.menus,
    cloneMenuCount: data.totalCount,
    isFetchingCloneMenuList: false
  };
}

export function fetchCloneMenuList(venueid) {
  return (dispatch) => {
    dispatch(fetchingCloneMenuListStatus(true));
    callApi(`venues/venue/${venueid}/clone-menu-list`, 'POST').then((res) => {
      if(res.status === 'Success'){
        dispatch(loadCloneMenuList(res.data));
      } else {
        dispatch(fetchingCloneMenuListStatus(false));
      }
    }).catch(() => {});
  };
}

export function updateCloneMenuStatus(status) {
  return {
    type: UPDATE_CLONE_MENU_STATUS,
    isUpdatingCloneMenu: status
  };
}

export function updateCloneMenuToList(data) {
  return {
    type: UPDATE_CLONE_MENU_TO_LIST,
    menu: data.menu,
    isUpdatingCloneMenu: false
  };
}

export function updateCloneMenu(clonemenuid, menu, userId) {
  return (dispatch) => {
    dispatch(updateCloneMenuStatus(true));
    callApi(`venues/clonemenu/${clonemenuid}/update`, 'POST', {
      menu: menu,
      userId: userId,
    }).then((res) => {
      if(res.status === 'Success'){
        userNotify('Updated!', 'success');
        dispatch(updateCloneMenuToList(res.data));
      } else {
        userNotify('Failed! to update', 'error');
        dispatch(updateCloneMenuStatus(false));
      }
    }).catch(() => {});
  };
}


export function updateCloneMenuActiveStatus(id, status) {
  return {
    type: UPDATE_CLONE_MENU_ACTIVE_STATUS,
    menuId: id,
    isUpdatingActiveStatus: !!status
  };
}

export function updateActiveStatusCloneMenu(clonemenuid, menu, userId) {
  return (dispatch) => {
    dispatch(updateCloneMenuActiveStatus(clonemenuid, true));
    callApi(`venues/clonemenu/${clonemenuid}/update`, 'POST', {
      menu: menu,
      userId: userId
    }).then((res) => {
      if(res.status === 'Success'){
        userNotify('Updated!', 'success');
        dispatch(updateCloneMenuToList(res.data));
      } else {
        userNotify('Failed!', 'error');
        dispatch(updateCloneMenuActiveStatus(clonemenuid, false));
      }
    }).catch(() => {});
  };
}

export function updateClonePackageStatus(status) {
  return {
    type: UPDATE_CLONE_PACKAGE_STATUS,
    isUpdatingClonePackage: status
  };
}

export function updateClonePackageToList(data) {
  return {
    type: UPDATE_CLONE_PACKAGE_TO_LIST,
    package: data.package,
    isUpdatingClonePackage: false
  };
}

export function updateClonePackage(clonepackageid, updatedPackage, userId) {
  return (dispatch) => {
    dispatch(updateClonePackageStatus(true));
    callApi(`venues/clonepackage/${clonepackageid}/update`, 'POST', {
      package: updatedPackage,
      userId: userId,
    }).then((res) => {
      if(res.status === 'Success'){
        userNotify('Package updated!', 'success');
        dispatch(updateClonePackageToList(res.data));
      } else {
        userNotify('Failed! to update', 'error');
        dispatch(updateClonePackageStatus(false));
      }
    }).catch(() => {});
  };
}


export function updateClonePackageActiveStatus(id, status) {
  return {
    type: UPDATE_CLONE_PACKAGE_ACTIVE_STATUS,
    packId: id,
    isUpdatingActiveStatus: status
  };
}

export function updateActiveStatusClonePackage(clonepackageid, updatedPackage, userId) {
  return (dispatch) => {
    dispatch(updateClonePackageActiveStatus(clonepackageid, true));
    callApi(`venues/clonepackage/${clonepackageid}/update`, 'POST', {
      package: updatedPackage,
      userId: userId
    }).then((res) => {
      if(res.status === 'Success'){
        userNotify('Status updated!', 'success');
        dispatch(updateClonePackageToList(res.data));
      } else {
        userNotify('Failed! to update status', 'error');
        dispatch(updateClonePackageActiveStatus(clonepackageid, false));
      }
    }).catch(() => {});
  };
}

export function deletingClonePackageStatus(status) {
  return {
    type: DELETE_CLONE_PACKAGE_STATUS,
    isDeletingClonePackage: status
  };
}

export function deleteClonePackageFromList(data) {
  return {
    type: DELETE_CLONE_PACKAGE,
    package: data.package,
    isDeletingClonePackage: false
  };
}

export function deleteClonePackage(clonepackageid, userId) {
  return (dispatch) => {
    dispatch(deletingClonePackageStatus(true));
    callApi(`venues/clonepackage/${clonepackageid}/remove`, 'POST', {userId: userId}).then((res) => {
      if(res.status === 'Success'){
        userNotify('Package deleted!', 'success');
        dispatch(deleteClonePackageFromList(res.data));
      } else {
        userNotify('Failed! to delete', 'error');
        dispatch(deletingClonePackageStatus(false));
      }
    }).catch(() => {});
  };
}

export function deletingCloneMenuStatus(status) {
  return {
    type: DELETE_CLONE_MENU_STATUS,
    isDeletingCloneMenu: status
  };
}

export function deleteCloneMenuFromList(data) {
  return {
    type: DELETE_CLONE_MENU_FROM_LIST,
    menu: data.menu,
    isDeletingCloneMenu: false
  };
}

export function deleteCloneMenu(clonemenuid, userId) {
  return (dispatch) => {
    dispatch(deletingCloneMenuStatus(true));
    callApi(`venues/clonemenu/${clonemenuid}/remove`, 'POST', {userId: userId}).then((res) => {
      if(res.status === 'Success'){
        userNotify('Menu deleted!', 'success');
        dispatch(deleteCloneMenuFromList(res.data));
      } else {
        userNotify('Failed! to delete menu', 'error');
        dispatch(deletingCloneMenuStatus(false));
      }
    }).catch(() => {});
  };
}

export function addingCloneMenuStatus(status) {
  return {
    type: ADD_CLONE_MENU_STATUS,
    isAddingCloneMenu: status
  };
}

export function addCloneMenuToList(data) {
  return {
    type: ADD_CLONE_MENU_TO_LIST,
    menu: data.menu,
    isAddingCloneMenu: false
  };
}

export function addCloneMenu(clonemenu, userId) {
  return (dispatch) => {
    dispatch(addingCloneMenuStatus(true));
    callApi(`venues/clonemenu/new`, 'POST', {
      menu: clonemenu,
      userId: userId
    }).then((res) => {
      if(res.status === 'Success'){
        userNotify('Menu added!', 'success');
        dispatch(addCloneMenuToList(res.data));
      } else {
        userNotify('Failed! to add menu', 'error');
        dispatch(addingCloneMenuStatus(false));
      }
    }).catch(() => {});
  };
}

export function addingClonePackageStatus(status) {
  return {
    type: ADD_CLONE_PACKAGE_STATUS,
    isAddingClonePackage: status
  };
}

export function addClonePackageToList(data) {
  return {
    type: ADD_CLONE_PACKAGE_TO_LIST,
    package: data.package,
    isAddingClonePackage: false
  };
}

export function addClonePackage(clonepackage, userId) {
  return (dispatch) => {
    dispatch(addingClonePackageStatus(true));
    callApi(`venues/clonepackage/new`, 'POST', {
      package: clonepackage,
      userId: userId
    }).then((res) => {
      if(res.status === 'Success'){
        userNotify('Package added!', 'success');
        dispatch(addClonePackageToList(res.data));
      } else {
        userNotify('Failed! to add package', 'error');
        dispatch(addingClonePackageStatus(false));
      }
    }).catch(() => {});
  };
}

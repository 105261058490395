import React, { Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Navigate } from 'react-router';
import { getAuthenticatedState } from '../../AppReducer';
import LoginForm from '../../components/Login/LoginForm';
import { withRoute } from 'util/withRoute';
import {Helmet} from "react-helmet";


class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.navigate(this.props.redirectUrl)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.props.navigate('/dashboard', {replace: true})
    }
  }

  render() {
    return (
      <div style={this.props.styles ? {...this.props.styles} : {position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: 'calc(100vh - 300px)', marginTop: '15px', marginBottom: '75px'}}>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <div style={{backgroundColor: '#fff', width: '600px', borderRadius: '5px', border: '1px solid #b7b7b7'}}>
          <LoginForm/>
        </div>
      </div>
    );
  }

}

// Actions required to provide data for this component to render in sever side.
// LoginPage.need = [params => {
//     return fetchLeads({id: params.id});
// }];

// Retrieve data from store as props
function mapStateToProps(state, props) {
  return {
    redirectUrl: props.location && props.location.query.ref ? props.location.query.ref : '',
    isAuthenticated: getAuthenticatedState(state),
  };
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object,
};


export default connect(mapStateToProps)(withRoute(LoginPage));
